jQuery(window).on("resize", function () {
  if (jQuery('.footer .container').length > 0) {
    var $offset = jQuery('.footer .container').offset().left;
    jQuery('.two-columns-two .col-two-outer.right-img .col-two:last-child .content').css({"margin-left": $offset + "px"});
    jQuery('.two-columns-two .col-two-outer.right-img .col-two:first-child').addClass('right');
  }
}).resize();

jQuery(window).on("load resize", function () {
  jQuery('.investment-management .two-columns-two .col-two-outer').each(function () {
    jQuery(this).find('.col-two').sameHeight();
  });
  jQuery('.endowments-foundations .two-columns-two .col-two-outer').each(function () {
    jQuery(this).find('.col-two').sameHeight();
  });
  jQuery('.about .two-columns-two .col-two-outer.canavs-padding').each(function () {
    jQuery(this).find('.col-two').sameHeight();
  });
    jQuery('.two-columns-two .col-two-outer').each(function (i, elem) {
      jQuery(this).find('.col-two').sameHeight();
    });
  /*
   *  Canvas Height    
   */
  if (!jQuery('html').hasClass('ie10')) {
    if (jQuery('.about_canvas').length > 0) {
      var $canvas_height = jQuery('.about_canvas #stairs_canvas').outerHeight();
      jQuery('.about_canvas #stairs_animation_container').css({'min-height': $canvas_height + 'px'});
    }
  }
  if (jQuery('html').hasClass('ie10')) {
    var $secHeight = jQuery('.about_canvas').parents('.col-two-outer').find('.content-outer').outerHeight();
    var $width = (77.71 * ($secHeight - 80)) / 100;
    var $height = (128.67 * $width) / 100;
      jQuery('.about_canvas #stairs_animation_container').css({'min-height': $secHeight + 'px', 'max-height': $secHeight + 'px'});
    jQuery('.about_canvas #stairs_canvas, .about_canvas #stairs_animation_container, .about_canvas #stairs_dom_overlay_container').css({'min-width': $width + 'px', 'max-width': $width + 'px'});
    jQuery('.about_canvas #stairs_canvas, .about_canvas #stairs_dom_overlay_container').css({'min-height': $height + 'px', 'max-height': $height + 'px'});
  }
});