(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [];


// symbols:
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.topLeg2 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AhqAiIgBgDICIhfIBPAtIiPBQQgPAEgMAAQggAAgMgfg");
	this.shape.setTransform(10.8,6.5);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.topLeg2, new cjs.Rectangle(0,0,21.6,13), null);


(lib.topleg1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AiKACIABgCICLgzICIA9IABADIjlAnQgrgGgFgsg");
	this.shape.setTransform(13.9,5.2);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.topleg1, new cjs.Rectangle(0,0,27.7,10.5), null);


(lib.lowleg2 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AADCiIgrk8QAngWAcApIgDABIAQEog");
	this.shape.setTransform(4.5,16.7);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.lowleg2, new cjs.Rectangle(0.4,0.4,8.1,32.5), null);


(lib.lowleg1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AglChIAdlAQAsgJACA8IgqENg");
	this.shape.setTransform(3.8,16.1);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.lowleg1, new cjs.Rectangle(0,0,7.7,32.2), null);


(lib.head1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{on:1});

	// timeline functions:
	this.frame_0 = function() {
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(51));

	// Layer_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#003E5B").s().p("AgHAKQgGgFgBgFQgCgGADgEQAEgEAGAAQAFABAGAEQAFAFACAFQACAGgEAEQgDAEgGAAQgGAAgFgFg");
	this.shape.setTransform(12,29.4,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#003E5B").s().p("AgEAGQgEgDAAgDQgCgDACgDQADgCADAAQADAAAEADQADACABADQABAEgCADQgCACgEAAQgDAAgDgDg");
	this.shape_1.setTransform(12.1,29.4);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#003E5B").s().p("AgDAFQgDgCgBgDQgBgDACgCQAAAAABgBQAAAAABAAQABAAAAgBQABAAABAAQACAAADADQADACABACQABADgCADQgCACgDAAQgCgBgDgCg");
	this.shape_2.setTransform(12.1,29.4);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#003E5B").s().p("AgCAEQgDgCAAgCQAAAAAAgBQgBAAABgBQAAAAAAgBQAAAAAAgBIAEgBQABAAAAAAQAAAAABAAQAAABABAAQAAABABAAQACACABABQABABAAAAQAAABgBAAQAAABAAAAQAAABgBAAQAAABAAAAQgBAAAAABQgBAAAAAAQgBAAgBAAQAAAAgBAAQAAAAAAAAQgBgBAAAAQgBAAAAgBg");
	this.shape_3.setTransform(12.1,29.4);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).wait(33));
	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1).to({_off:true},1).wait(3).to({_off:false,scaleX:0.31,scaleY:0.31,x:12.1,y:29.3},0).to({_off:true},1).wait(3).to({_off:false,scaleX:0.72,scaleY:0.72,x:12,y:29.4},0).wait(1).to({_off:true},1).wait(3).to({_off:false,scaleX:0.31,scaleY:0.31,x:12.1,y:29.3},0).to({_off:true},1).wait(3).to({_off:false,scaleX:0.72,scaleY:0.72,x:12,y:29.4},0).wait(33));

	// Layer_1
	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#003E5B").s().p("AC3DvQgugSgmgrQgogtgSg5QANgMACgQQADgRgJgOQgKgRgTgDQgTgFgPAJQgGAEgGAHQgegVgegEQgTA+hUArQhbAug8gxQAUgEAGgWIAIg2QAMheBJhKQBThUBagEQBPgEBFA4QBBA1AhBUQAiBUgNBOQgPBVA3ALQAVAFAYgJQAXgJAMgPQgZA2gsAQQgSAHgTAAQgZAAgagKg");
	this.shape_4.setTransform(24.5,17.9,0.722,0.722);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#233129").s().p("AgWAZIABgIQAKABAJgEQAIgEAEgHQAIgLgHgMIAHgEQAKAQgKAQQgHAKgLAEQgIADgIAAIgGAAg");
	this.shape_5.setTransform(23.7,34.2,0.722,0.722);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#EB6378").s().p("ACADKQgZgehLgBQhugBg9gqIguAgQAOgWgIgfQgEgPgUgoQgfg9AWhLQAahZBgg7QAwgdA1ABQAzAAAqAdQBgBAgaCFQgNBAAFAnQAHA4AsA1IAOAOIhMBFQgRg1gGgGg");
	this.shape_6.setTransform(21.4,25.7,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_6},{t:this.shape_5},{t:this.shape_4}]}).wait(51));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,49,44.6);


(lib.head = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{"on":1});

	// timeline functions:
	this.frame_0 = function() {
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(51));

	// Layer_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#003E5B").s().p("AgIAKQgKgEgGgHQgBAAAAgBQAAAAAAgBQAAgBABAAQAAgBABAAQAAgBABAAQABAAAAAAQABAAAAABQABAAAAABQAFAGAJADQAIADAHgDQAKgEgBgKQAAAAABgBQAAAAAAgBQAAAAABAAQABgBAAAAQABAAABAAQAAAAAAABQABAAAAABQABAAAAABQABAPgPAFIgDABIgGABQgFAAgGgDg");
	this.shape.setTransform(21.5,15,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#003E5B").s().p("AgFAHQgIgDgEgEQAAAAgBgBQAAAAAAgBQAAAAABgBQAAAAABgBQAAAAAAAAQABAAAAAAQABAAAAAAQAAABABAAQAEAFAGACQAFACAFgCQAHgEAAgGQAAAAAAgBQAAAAABAAQAAgBAAAAQABAAAAAAQABAAAAAAQAAAAABAAQAAAAAAABQAAAAABABQABALgLADIgCABIgFAAQgDABgEgDg");
	this.shape_1.setTransform(21.5,15);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#003E5B").s().p("AAEAIQgEABgEgEIgFgDIgEgDIAAgBIAAgBQABgBAAAAQAAAAABAAQAAABAAAAQABAAAAABQABACADABQAAABAAAAQABAAAAAAQAAABABAAQAAAAABAAIABAAQAEABAEgCQADgCABgCIABgEIABgBQABAAAAAAQAAAAABABQAAAAAAAAQAAABAAAAQABAGgEAEIgCABIgDACIgBAAIgBAAg");
	this.shape_2.setTransform(21.5,15);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#003E5B").s().p("AAEAHIgBAAIgBAAQgCgBgDgDIgDgDQgBAAAAAAQgBAAAAgBQAAAAAAgBQgBAAAAgBIAAAAIAAAAIAAgBIACACIADADIACABIABAAQADgBACgBIADgFIABgBIABAAIABADQAAAFgCADIgBABIgCABIgBAAg");
	this.shape_3.setTransform(21.5,15.1);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#003E5B").s().p("AACAHIgBAAIAAgBQgBgBgCgDQABAAAAAAQAAAAAAABQABAAAAAAQAAgBAAAAIAAAAIADgEIABgEIABABIAAABIABADQAAAEgCADIAAABIgCAAIAAAAgAgEAAIAAgDIAAACIACACIgCgBgAgFgDIAAgBIABABg");
	this.shape_4.setTransform(21.5,15.1);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#003E5B").s().p("AAAAGIgBgHIAAgEIAAABIABAHQAAAAAAAAQAAABAAAAQAAAAABgBQAAAAAAAAIABgKQAAAKgBAEIAAABIgBAAIAAgCg");
	this.shape_5.setTransform(21.5,15.1);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).wait(33));

	// Layer_1
	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#003E5B").s().p("AiUiIIEohqIgdB3IgiAMIAQByIgKABQgQAEgIANQgIANAEAQQAEAPANAJQANAIAQgEIACgBIASB8IixAqg");
	this.shape_6.setTransform(10.7,17.6,0.722,0.722);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#003E5B").s().p("AgDALQgXgIgKgOQgBAAAAAAQAAgBAAgBQAAAAABgBQAAAAABgBQAAgBABAAQAAAAABAAQABAAAAABQABAAAAABQAKANAVAHQAVAHANgEQAAAAABAAQABAAAAAAQABAAAAABQAAAAABABQAAABAAAAQAAABAAAAQgBABAAAAQgBABAAAAIgCABIgKABQgNAAgOgGg");
	this.shape_7.setTransform(26.3,25.4,0.722,0.722);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#EB6378").s().p("AjdjRIC+hCIAuBLICWBYIhAAcIB5DrIljB9g");
	this.shape_8.setTransform(18.3,23.1,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_8},{t:this.shape_7},{t:this.shape_6}]}).wait(51));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,34.3,43.1);


(lib.foot2girl = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#223129").s().p("AALgYIgBgHIAlAAIACA/IhiAAg");
	this.shape.setTransform(5,3.2);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.foot2girl, new cjs.Rectangle(0,0,9.9,6.4), null);


(lib.foot1girl = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#223129").s().p("AAIgQIAAgFIABgBIAhAAIgGAoIhNAFg");
	this.shape.setTransform(4.2,2.3);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.foot1girl, new cjs.Rectangle(0,0,8.5,4.7), null);


(lib.body1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#FFFFFF").s().p("AAtAtQggiOg5hSIglgMIgFguIAgAIIBGhCIAnAWIguBDQAwByASBnQAZCQgcCOQACh9gdh/g");
	this.shape.setTransform(9.4,21.4,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#003E5B").s().p("AkjETQBehoAohCQArhJAEhCQAEhBgghZQgYhDhGiQIBog/IDKDoQBjB3AvBZQA6BrALBvQAMB5goCSg");
	this.shape_1.setTransform(21.1,35.2,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.body1, new cjs.Rectangle(0,0,42.2,68.6), null);


(lib.arm4 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AAGAdIgcgIQgTgGgBgGQgDgKAJgOQAKgPAPACIAxAOQAIADgDAJIgHATQgJgDgHAIQgHAHgFAAIgCAAg");
	this.shape.setTransform(33.5,2.1,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#EB6378").s().p("AgYAnQgSgMgJgIQgRgNAHgKQAFgJAKACQAIACASAMQACgDACgjQACgWAQAEIAyANQASAGgJAlQgJAmgZAOg");
	this.shape_1.setTransform(32.8,4,0.722,0.722);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#003E5B").s().p("AjxC4QCxgaB3i9QBViGAujCIA4ARQg2EliUDJQiDCziKAdg");
	this.shape_2.setTransform(17.5,32.8,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.arm4, new cjs.Rectangle(0,0,37.4,58.8), null);


(lib.arm3 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#003E5B").s().p("AluATQhDg7AbhEQAJgZAUgNQASgNAMAIQB5CVC0AoQDTAxDah+IAgAxQg2A1hUAlQhcAphmAKQgiAEgiAAQjRAAisiIg");
	this.shape.setTransform(30,11.2,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#EB6378").s().p("Ag1AXIAFgSQAGgHAIghQAHgcAHAAQARAAgHAuQAMABARgMQASgNAFgSQAOADgDAXQgBANgHAbQgFAWgWARQgSANgSAFIgJAAg");
	this.shape_1.setTransform(61.3,7.3,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.arm3, new cjs.Rectangle(0,0,65.2,22.4), null);


(lib.ARM2 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#002B44").s().p("AkggVQgVgDgNgNQgMgNABgOQACgmA4ALIJhCWIgFAjQoPhlhagOg");
	this.shape.setTransform(24.1,6.9,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.ARM2, new cjs.Rectangle(0,0,48.3,13.7), null);


(lib.arm1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#003E5B").s().p("AjdE5IFup/QAJgUARAAQAQAAAOAQQAkAogYAzImXJKg");
	this.shape.setTransform(19.7,25,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#EB6378").s().p("AgSA2QABgNgEgLIgOgiQgNgIASgUQARgTAagMIAcAOIgFAVQgFAOgBAQQgBAQgDAGQgJAagJAIQgFAGgEAAQgIAAgJgKg");
	this.shape_1.setTransform(3,52.1,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.arm1, new cjs.Rectangle(0,0,35.8,56.7), null);


(lib.guy1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{"on":41});

	// timeline functions:
	this.frame_40 = function() {
		this.stop();
		this.parent.girl.gotoAndPlay("on");
	}
	this.frame_69 = function() {
		this.head.gotoAndPlay("on");
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(40).call(this.frame_40).wait(29).call(this.frame_69).wait(51));

	// Layer_7
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#003E5B").s().p("AjEASQgtkDgSimIFuhBQBaBrApEpQAlEQgaDqIlnAkQgwjtgmjbg");
	this.shape.setTransform(26.6,68.2,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#003E5B").s().p("AgYI1Ih7wEIA/hlICDBJIADPFIBiBbg");
	this.shape_1.setTransform(29.2,135.8,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(120));

	// Layer_3
	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#003E5B").s().p("AjKAxIBUhyIFBA/QgnBChMACg");
	this.shape_2.setTransform(51.4,96);

	this.timeline.addTween(cjs.Tween.get(this.shape_2).wait(120));

	// Layer_6
	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#003E5B").s().p("AhYDBIA+k8IgFAAQABgsBxgZIAGABIhxFFIA8A7g");
	this.shape_3.setTransform(63.4,115.1);

	this.timeline.addTween(cjs.Tween.get(this.shape_3).wait(120));

	// head
	this.head = new lib.head();
	this.head.name = "head";
	this.head.parent = this;
	this.head.setTransform(29.6,45.3,1,1,0,0,0,29.6,45.3);

	this.timeline.addTween(cjs.Tween.get(this.head).to({rotation:-15,x:31.6,y:43.3},10,cjs.Ease.quadInOut).wait(88).to({rotation:0,x:29.6,y:45.3},9,cjs.Ease.get(1)).wait(13));

	// arm3
	this.instance = new lib.arm3();
	this.instance.parent = this;
	this.instance.setTransform(29.6,45.3,1,1,0,0,0,0,11.2);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(11).to({rotation:-60,y:43.3},13,cjs.Ease.quartInOut).wait(4).to({rotation:0,y:45.3},9,cjs.Ease.get(1)).wait(83));

	// arm4
	this.instance_1 = new lib.arm4();
	this.instance_1.parent = this;
	this.instance_1.setTransform(29.6,45.3,1,1,0,0,0,15.3,44);

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(52).to({x:27.6,y:37.4},11,cjs.Ease.cubicInOut).wait(35).to({x:29.6,y:45.3},9,cjs.Ease.get(1)).wait(13));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,94.7,176.6);


(lib.fullarm = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.instance = new lib.ARM2();
	this.instance.parent = this;
	this.instance.setTransform(24.1,6.8,1,1,0,0,0,24.1,6.8);

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#EB6378").s().p("AAHAXQglgLgIgBIgRgcIAVgGQALACApgCQAbgCAHAKQAIAMgHAIQgFAGgIADQgIAAgDAEQgCAGgJAAIgLgBg");
	this.shape.setTransform(50.8,12.9,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.instance}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.fullarm, new cjs.Rectangle(0,0,54.9,14.6), null);


(lib.woman = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{"on":1});

	// timeline functions:
	this.frame_0 = function() {
		this.stop();
	}
	this.frame_116 = function() {
		this.head.gotoAndPlay("on");
		this.parent.guy.play();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(116).call(this.frame_116).wait(79));

	// Layer_4
	this.instance = new lib.arm1();
	this.instance.parent = this;
	this.instance.setTransform(40.9,47.7,0.833,0.705,-31.7,0,0,31.4,3.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(18).to({regY:3.4,scaleX:1,scaleY:1,rotation:0,x:40.8},22,cjs.Ease.quintInOut).wait(32).to({rotation:-7.7,x:16.3,y:66.2},12,cjs.Ease.get(0.79)).wait(5).to({rotation:0,x:40.8,y:47.7},12,cjs.Ease.get(1)).wait(53).to({regY:3.5,scaleX:0.83,scaleY:0.71,rotation:-31.7,x:40.9},11,cjs.Ease.cubicInOut).wait(30));

	// body
	this.instance_1 = new lib.body1();
	this.instance_1.parent = this;
	this.instance_1.setTransform(50.2,80.2,1,1,5.2,0,0,24.5,43.3);

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(18).to({regX:24.4,rotation:0,x:50.1},22,cjs.Ease.quartInOut).wait(32).to({regX:24.6,regY:43.4,scaleX:1.04,scaleY:1.04,rotation:-45,x:53.8,y:80.7},12,cjs.Ease.get(0.79)).wait(5).to({regX:24.4,regY:43.3,scaleX:1,scaleY:1,rotation:0,x:50.1,y:80.2},12,cjs.Ease.get(1)).wait(53).to({regX:24.5,rotation:5.2,x:50.2},11,cjs.Ease.cubicInOut).wait(30));

	// head
	this.head = new lib.head1();
	this.head.name = "head";
	this.head.parent = this;
	this.head.setTransform(38.1,39.5,1,1,24.2,0,0,35,39.5);

	this.timeline.addTween(cjs.Tween.get(this.head).to({rotation:0,x:38,y:39},18,cjs.Ease.quartInOut).to({x:34.3,y:40},22,cjs.Ease.quartInOut).wait(32).to({rotation:-15,x:13.1,y:63.5},12,cjs.Ease.get(0.79)).wait(5).to({rotation:0,x:34.3,y:40},12,cjs.Ease.get(1)).wait(53).to({rotation:24.2,x:38.1,y:39.5},11,cjs.Ease.cubicInOut).wait(30));

	// Layer_2
	this.instance_2 = new lib.fullarm();
	this.instance_2.parent = this;
	this.instance_2.setTransform(35.6,44.3,1,1,45,0,0,2.6,3.1);

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(72).to({regX:2.5,rotation:-30,x:17.1,y:62.2},12,cjs.Ease.get(0.56)).wait(5).to({regX:2.6,rotation:45,x:35.6,y:44.3},12,cjs.Ease.get(1)).wait(94));

	// Layer_3
	this.instance_3 = new lib.lowleg2();
	this.instance_3.parent = this;
	this.instance_3.setTransform(49.3,102.8,1,1,0,0,0,5.3,0.1);

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(72).to({rotation:-10,x:57.4,y:103.7},7,cjs.Ease.get(-1)).wait(13).to({rotation:0,x:49.3,y:102.8},7,cjs.Ease.get(1)).wait(96));

	// Layer_5
	this.instance_4 = new lib.topleg1();
	this.instance_4.parent = this;
	this.instance_4.setTransform(53.1,92.4,1,1,0,0,0,17.1,2.2);

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(73).to({regX:13.8,regY:5.2,rotation:-4.6,x:50.3,y:94.9},0).wait(1).to({regX:17.2,regY:2.3,scaleX:1,scaleY:1,rotation:-18.6,x:54.5,y:89.4},0).wait(1).to({regX:13.8,regY:5.2,rotation:-24.3,x:52.1,y:93.2},0).wait(1).to({scaleX:1,scaleY:1,rotation:-30.8,x:52.2,y:93.1},0).wait(1).to({regX:17.2,regY:2.3,rotation:-38.1,x:53.1,y:88.6},0).wait(1).to({regX:13.8,regY:5.2,scaleX:1,scaleY:1,rotation:-52.7,x:51.1,y:93.5},0).wait(1).to({regX:17.1,regY:2.2,scaleX:1,scaleY:1,rotation:-70,x:48.2,y:89.6},0).wait(13).to({regX:17,regY:2.4,scaleX:1,scaleY:1,rotation:-40.3,x:53.3,y:89.3},3,cjs.Ease.get(1)).wait(1).to({regX:13.8,regY:5.2,scaleX:1,scaleY:1,rotation:-22.7,x:51.3,y:94.5},0).wait(1).to({scaleX:1,scaleY:1,rotation:-10.1,x:50.5,y:94.9},0).wait(1).to({rotation:-2.5,x:50.1,y:95.2},0).wait(1).to({regX:17.1,regY:2.2,rotation:0,x:53.1,y:92.4},0).wait(96));

	// Layer_9
	this.instance_5 = new lib.foot1girl();
	this.instance_5.parent = this;
	this.instance_5.setTransform(32.2,129.9,1,1,0,0,0,4.2,2.3);

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(72).to({regX:4.3,regY:2.4,rotation:-10,x:47.3,y:137.5},7,cjs.Ease.get(-1)).wait(13).to({regX:4.2,regY:2.3,rotation:0,x:32.2,y:129.9},7,cjs.Ease.get(1)).wait(96));

	// Layer_10
	this.instance_6 = new lib.foot2girl();
	this.instance_6.parent = this;
	this.instance_6.setTransform(47.9,138.8,1,1,0,0,0,5,3.2);

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(72).to({regY:3.3,rotation:5,x:61.8,y:138.2},7,cjs.Ease.get(-1)).wait(13).to({regY:3.2,rotation:0,x:47.9,y:138.8},7,cjs.Ease.get(1)).wait(96));

	// Layer_11
	this.instance_7 = new lib.lowleg1();
	this.instance_7.parent = this;
	this.instance_7.setTransform(40.1,96.2,1,1,0,0,0,7,0.8);

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(72).to({regY:0.9,rotation:-10,x:49.2,y:105.1},7,cjs.Ease.get(-1)).wait(13).to({regY:0.8,rotation:0,x:40.1,y:96.2},7,cjs.Ease.get(1)).wait(96));

	// Layer_12
	this.instance_8 = new lib.topLeg2();
	this.instance_8.parent = this;
	this.instance_8.setTransform(62.5,97.3,1,1,0,0,0,18.2,3.5);

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(72).to({regY:3.6,rotation:-25,x:66.7,y:92.6},7,cjs.Ease.get(-1)).wait(13).to({regY:3.5,rotation:0,x:62.5,y:97.3},7,cjs.Ease.get(1)).wait(96));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(7.6,-10.8,63.9,152.9);


// stage content:
(lib.web_g3 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// guy1
	this.guy = new lib.guy1();
	this.guy.name = "guy";
	this.guy.parent = this;
	this.guy.setTransform(57.4,260.3,1,1,0,0,0,47.4,88.3);

	this.timeline.addTween(cjs.Tween.get(this.guy).wait(2));

	// woman
	this.girl = new lib.woman();
	this.girl.name = "girl";
	this.girl.parent = this;
	this.girl.setTransform(137,77.5,1,1,0,0,0,44,71.5);

	this.timeline.addTween(cjs.Tween.get(this.girl).wait(2));

	// visual
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#FFFFFF").s().p("AzLCWIGRkrMAgGAAAIAAErg");
	this.shape.setTransform(119.9,137.9,0.722,0.722);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#FFFFFF").s().p("AkSAeIAAg7IImAAIAAA7g");
	this.shape_1.setTransform(35.1,476.4,0.722,0.722);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#FFFFFF").s().p("AkTAdIAAg6IImAAIAAA6g");
	this.shape_2.setTransform(38.7,455.5,0.722,0.722);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#FFFFFF").s().p("AkSAdIAAg5IIlAAIAAA5g");
	this.shape_3.setTransform(42.3,434.5,0.722,0.722);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#FFFFFF").s().p("AkSAdIAAg5IIlAAIAAA5g");
	this.shape_4.setTransform(45.9,413.6,0.722,0.722);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#FFFFFF").s().p("AkTAeIAAg7IImAAIAAA7g");
	this.shape_5.setTransform(49.5,392.6,0.722,0.722);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#FFFFFF").s().p("AkSAeIAAg7IImAAIAAA7g");
	this.shape_6.setTransform(53.1,371.6,0.722,0.722);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#FFFFFF").s().p("AkTAdIAAg5IInAAIAAA5g");
	this.shape_7.setTransform(56.7,350.7,0.722,0.722);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#FFFFFF").s().p("AkSAdIAAg5IIlAAIAAA5g");
	this.shape_8.setTransform(60.3,329.7,0.722,0.722);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#FFFFFF").s().p("AkSAeIAAg7IIlAAIAAA7g");
	this.shape_9.setTransform(63.8,308.7,0.722,0.722);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#FFFFFF").s().p("AkTAeIAAg7IInAAIAAA7g");
	this.shape_10.setTransform(67.5,287.8,0.722,0.722);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#FFFFFF").s().p("AkSAeIAAg7IIlAAIAAA7g");
	this.shape_11.setTransform(71.1,266.8,0.722,0.722);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#FFFFFF").s().p("AkTAdIAAg5IImAAIAAA5g");
	this.shape_12.setTransform(85.2,182.9,0.722,0.722);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#FFFFFF").s().p("AkTAeIAAg7IImAAIAAA7g");
	this.shape_13.setTransform(81.8,203.9,0.722,0.722);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#FFFFFF").s().p("AkSAdIAAg5IIlAAIAAA5g");
	this.shape_14.setTransform(74.7,245.9,0.722,0.722);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#FFFFFF").s().p("AkSAdIAAg5IIlAAIAAA5g");
	this.shape_15.setTransform(78.3,224.9,0.722,0.722);

	this.shape_16 = new cjs.Shape();
	this.shape_16.graphics.f("#FFFFFF").s().p("EgGdAlyQgTgDgMgQQgLgQADgTMAMshKHQADgTAQgLQAQgMATAEQAUADALAQQALAQgDATMgMsBKHQgDARgNALQgNALgRAAIgIgBg");
	this.shape_16.setTransform(40.6,327.7,0.722,0.722);

	this.shape_17 = new cjs.Shape();
	this.shape_17.graphics.f("#FFFFFF").s().p("EgGeAlyQgTgDgLgQQgLgQADgTMAMshKHQADgTAQgLQAQgMATAEQATADAMAQQALAQgDATMgMsBKHQgDARgNALQgNALgRAAIgJgBg");
	this.shape_17.setTransform(80.4,327.7,0.722,0.722);

	this.shape_18 = new cjs.Shape();
	this.shape_18.graphics.lf(["#DAE3E8","rgba(218,227,232,0.376)","rgba(218,227,232,0)"],[0,0.859,1],0,73,0,-69.5).s().p("AujMMIAA4XIdIAAIAAYXg");
	this.shape_18.setTransform(128.6,382.9,0.722,0.722);

	this.shape_19 = new cjs.Shape();
	this.shape_19.graphics.f("#C7CED1").s().p("ADxaaQgLgLAAgQIAAtWInLAAIAANWQAAAPgLAMQgLALgPAAQgQAAgLgLQgMgLAAgQMAAAgz9QAAgPAMgMQALgLAQAAQAPAAALALQALAMAAAPIAAENIHLAAIAAkNQAAgPALgMQAMgLAPAAQAPAAALALQALAMAAAPMAAAAz9QAAAPgLAMQgLALgPAAQgPAAgMgLgAjlLwIHLAAIAAj5InLAAgAjlG9IHLAAIAAj5InLAAgAjlCKIHLAAIAAj3InLAAgAjlinIHLAAIAAj5InLAAgAjlnaIHLAAIAAj4InLAAgAjlsMIHLAAIAAj4InLAAgAjlw/IHLAAIAAj4InLAAg");
	this.shape_19.setTransform(90.1,276.5,0.722,0.722);

	this.shape_20 = new cjs.Shape();
	this.shape_20.graphics.f("#DAE3E8").s().p("EgM6AkfMgGRhI9MAmXAAAMAAABI9g");
	this.shape_20.setTransform(119.9,317.2,0.722,0.722);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(2));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(107.9,220.2,200.7,507.1);
// library properties:
lib.properties = {
	id: '1E3A3DCD787D4D6EB90FBBFB21578C92',
	width: 200,
	height: 450,
	fps: 24,
	color: "#CCCCCC",
	opacity: 0.00,
	manifest: [],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['1E3A3DCD787D4D6EB90FBBFB21578C92'] = {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}



})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;