var extraHeight = 0;
var middleTop = 0;
jQuery(window).on("load", function () {

    if (!jQuery('body').hasClass('touch')) {
        matrixparallax();
    }
    var winWidth = jQuery(window).width();
    if (winWidth < 768) {
        jQuery(".large-banner").css({ "opacity": "1" });
    }

    if (jQuery('html').hasClass('ie10')) {
        if (winWidth <= 1024) {
            var $secHeight = 400;
            var $width = (77.71 * 400) / 100;
            var $height = 400;
            jQuery('.large-banner  #about_dom_overlay_container').css({ 'min-height': $secHeight + 'px', 'max-height': $secHeight + 'px' });
            jQuery('.large-banner  #about_canvas, .large-banner #about_animation_container, .large-banner #about_dom_overlay_container').css({ 'min-width': $width + 'px', 'max-width': $width + 'px' });
            jQuery('.large-banner  #about_canvas, .large-banner #about_animation_container').css({ 'min-height': $height + 'px', 'max-height': $height + 'px' });
        }
    }
});
jQuery(window).on("load resize", function () {
    if (jQuery(".large-banner .bg-img, .col-two-cta .bg-img, .intro-with-image .bg-img, .foundation-large-banner .bg-img").length) {
        jQuery(".large-banner .bg-img, .col-two-cta .bg-img, .intro-with-image .bg-img, .foundation-large-banner .bg-img").each(function () {
            var img = jQuery(this).children("img"),
                defaultWidth = img.prop('naturalWidth'),
                defaultHeight = img.prop('naturalHeight'),
                parentHeight = jQuery(this).height(),
                parentWidth = jQuery(this).width(),
                aspectRatio = defaultWidth / defaultHeight;
            img.css({ "height": "auto", "width": "100%", "margin-left": "0px" });
            var imgHeight = parentWidth / aspectRatio;
            var imgTop = (imgHeight - parentHeight) / 2;
            img.css({ "margin-top": "-" + imgTop + "px" });
            if (img.height() < parentHeight) {
                img.css({ "height": "100%", "width": "auto" });
                var left_margin = (img.width() - parentWidth);
                img.css({ "margin-left": "-" + left_margin + "px", "margin-top": "0" });
            } else if (img.width() < parentWidth) {
                img.css({ "height": "auto", "width": "100%", "margin-left": "0" });
                var imgHeight = parentWidth / aspectRatio;
                var imgTop = (imgHeight - parentHeight) / 2;
                img.css({ "margin-top": "-" + imgTop + "px" });
            }
        });
    }

    var winWidth = jQuery(window).width();
    if (winWidth <= 1024) {
        var backgroundWidth = jQuery(".large-banner .bg-img").width();
        var canvasWidth = backgroundWidth * 2.599978418042516;
        jQuery(".large-banner .bg-img .background-2").css({ "width": canvasWidth + "px", "height": "auto" });
        var imageHeight = canvasWidth / 1.119175496688741;
        var topPadding = jQuery(".large-banner .bg-img .background-2").css("top");
        var contentHeight = jQuery(".large-banner .v-middle-wrapper .container .v-middle .content").height();
        jQuery(".large-banner .bg-img .background-2").css({ "height": imageHeight + "px" });
        var sectionHeight = imageHeight + contentHeight + 80 + parseInt(topPadding);
        middleTop = (imageHeight + parseInt(topPadding) + 40);
        jQuery(".large-banner .v-middle-wrapper .container .v-middle").css({ "padding-top": middleTop + "px" });
        jQuery(".large-banner, .large-banner .inner-wrapper").css({ "height": sectionHeight + "px" });
    }
    if (!jQuery('body').hasClass('touch')) {
        about_paralax();
    }
    jQuery('.grid-two .col-outer .col-box').sameHeight();
}).resize();

/*
 * Hero Banner parallax
 */
function matrixparallax() {
    var $secHeight = jQuery('.large-banner').outerHeight();
    var $winHeight = jQuery(window).height();
    if ((jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").length > 0)) {
        var scroll = jQuery(document).scrollTop();
        var imageParallax = 0;
        jQuery(window).scroll(function () {
            var $secScrolltop = jQuery(window).scrollTop();
            $winHeight = jQuery(window).height();
            var scrolled = jQuery(document).scrollTop();
            if ($secHeight < $winHeight) {
                if ((scrolled > scroll)) {
                    if (jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").length > 0) {
                        jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").each(function () {
                            var $offset = jQuery(this).parent().offset().top;
                            var $secHeight = jQuery(this).parent().outerHeight();
                            var $secPosition = $offset - scrolled;
                            if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
                                imageParallax = -(scrolled / 3);
                                jQuery(this).css("bottom", imageParallax + "px");
                            } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
                                imageParallax = -(scrolled / 3);
                                jQuery(this).css("bottom", imageParallax + "px");
                            }
                        });
                    }
                }
                else if ((scrolled < scroll)) {
                    /* Image parallax */
                    if (jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").length > 0) {
                        jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").each(function () {
                            var $offset = jQuery(this).parent().offset().top;
                            var $secHeight = jQuery(this).parent().outerHeight();
                            var $secPosition = $offset - scrolled;
                            if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
                                if (imageParallax === 0) {
                                    imageParallax = 0;
                                } else {
                                    imageParallax = -(scrolled / 3);
                                    jQuery(this).css("bottom", imageParallax + "px");
                                }
                            } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
                                if (imageParallax === 0) {
                                    imageParallax = 0;
                                } else {
                                    imageParallax = -(scrolled / 3);
                                    jQuery(this).css("bottom", imageParallax + "px");
                                }
                            }
                            if (scrolled <= 0) {
                                imageParallax = 0;
                                jQuery(this).css("bottom", "0px");
                            }
                        });
                    }
                }
            }
            //  else if($secHeight > $winHeight){

            //     console.log('3');
            //     /* Image parallax */
            //     if ((scrolled > scroll)) {
            //         if( ($secHeight-$winHeight) > $secScrolltop){
            //             jQuery('.large-banner').addClass('scroll-stop')
            //         }else if( ($secHeight-$winHeight) < $secScrolltop) {
            //             jQuery('.large-banner').removeClass('scroll-stop');
            //             if (jQuery(".hero-banner .image-parallax, .large-banner .content").length > 0) {
            //                 jQuery(".hero-banner .image-parallax, .large-banner .content").each(function () {
            //                     jQuery('.large-banner .inner-wrapper').css({'top': -($secHeight - $winHeight) + 'px'});
            //                     imageParallax = -((scrolled - ($secHeight - $winHeight)) / 3);
            //                     jQuery(this).css({'bottom': imageParallax + 'px'});
            //                 });
            //             }
            //         }
            //
            //     }
            //     else if ((scrolled < scroll)) {
            //         console.log('4');
            //         jQuery(".hero-banner .image-parallax, .large-banner .image-parallax").each(function () {
            //             var $offset = jQuery(this).parent().offset().top;
            //             var $secHeight = jQuery(this).parent().outerHeight();
            //             var $secPosition = $offset - scrolled;
            //             jQuery('.large-banner .inner-wrapper').css({'top': '0px'});
            //             if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
            //                 if (imageParallax === 0) {
            //                     imageParallax = 0;
            //                 } else {
            //                     imageParallax = -(scrolled / 3);
            //                     jQuery(this).css("bottom", imageParallax + "px");
            //                 }
            //             } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
            //                 if (imageParallax === 0) {
            //                     imageParallax = 0;
            //                 } else {
            //                     imageParallax = -(scrolled / 3);
            //                     jQuery(this).css("bottom", imageParallax + "px");
            //                 }
            //             }
            //             if (scrolled <= 0) {
            //                 imageParallax = 0;
            //                 jQuery(this).css("bottom", "0px");
            //             }
            //         });
            //     }
            // }
            scroll = jQuery(document).scrollTop();
        });
    }
}
function about_paralax() {
    var range = 200;
    jQuery(window).on('scroll', function (e) {
        e.stopPropagation();
        if (jQuery('.large-banner').length > 0) {
            var scrollTop = jQuery(this).scrollTop(),
                height = jQuery('.large-banner').outerHeight(),
                offset = height / 2,
                calc = 1 - (scrollTop - offset + range) / range;
        }
        jQuery('.large-banner .v-middle-wrapper .container .v-middle .content, .large-banner .bg-img').stop(true, true).css({ 'opacity': calc });

        if (calc > '1') {
            jQuery('.large-banner .v-middle-wrapper .container .v-middle .content, .large-banner .bg-img').css({ 'opacity': 1 });
        } else if (calc <= '0.2') {
            jQuery('.large-banner .v-middle-wrapper .container .v-middle .content, .large-banner .bg-img').css({ 'opacity': 0.2 });
        }

    });
}

jQuery(window).on("load resize", function () {
    var winWidth = jQuery(window).width();
    var violatorbar_height = jQuery('.violator-bar').outerHeight();
    var navigation_height = jQuery('.navigation ').outerHeight();
    if (winWidth <= 1024) {
        var backgroundWidth = jQuery(".large-banner .bg-img").width();
        var canvasWidth = backgroundWidth * 2.599978418042516;
        var imageHeight = canvasWidth / 1.119175496688741;
        var topPadding = jQuery(".large-banner .bg-img .background-2").css("top");
        var contentHeight = jQuery(".large-banner .v-middle-wrapper .container .v-middle .content").height();
        var sectionHeight = imageHeight + contentHeight + 80 + parseInt(topPadding);
        if (jQuery('body').hasClass('violator_active')) {
            jQuery(".large-banner, .large-banner .inner-wrapper").css({ "height": (sectionHeight) + "px", "min-height": (sectionHeight + violatorbar_height) + "px", "max-height": (sectionHeight + violatorbar_height) + "px" });
            jQuery(".large-banner .inner-wrapper").css({ "margin-top": -violatorbar_height + "px" });
        } else {
            jQuery(".large-banner, .large-banner .inner-wrapper").css({ "height": sectionHeight + "px" });
        }
    } else if (winWidth > 1024) {
        if (jQuery('body').hasClass('violator_active')) {
            jQuery(".large-banner, .large-banner .inner-wrapper").css({ "min-height": (858 + violatorbar_height) + "px", "max-height": (858 + violatorbar_height) + "px" });
            jQuery(".large-banner .inner-wrapper").css({ "margin-top": -violatorbar_height + "px" });
        } else {
            jQuery(".large-banner, .large-banner .inner-wrapper").css({ "min-height": "858px", "max-height": "858px" });
        }
    }

    if ((winWidth >= 992) && (winWidth <= 1024)) {
        jQuery(".large-banner .bg-img .background-2").css({ "top": (violatorbar_height + navigation_height) + "px" });
    }
    else if ((winWidth >= 768) && (winWidth <= 991)) {
        jQuery(".large-banner .bg-img .background-2").css({ "top": (violatorbar_height + navigation_height) + "px" });
    }
    else if ((winWidth >= 596) && (winWidth <= 767)) {
        jQuery(".large-banner .bg-img .background-2").css({ "top": (violatorbar_height + navigation_height) + "px" });
    }
    else if (winWidth <= 595) {
        jQuery(".large-banner .bg-img .background-2").css({ "top": (violatorbar_height + navigation_height) + "px" });
    }
    var $secHeight = jQuery('.large-banner').outerHeight();
    var $winHeight = jQuery(window).height();
    if ($secHeight >= $winHeight) {
        jQuery('body').addClass('no-scroll-effect');
    }
});