jQuery(document).ready(function () {

  jQuery('.blog-details .play-btn').click(function (e) {
    e.preventDefault();
    var linkval = jQuery(this).attr('data-video');
    jQuery(".video-overlay").addClass("active");
    jQuery('body').css("overflow", "hidden");
    jQuery('.video-overlay.active iframe').attr("src", linkval);
    jQuery('body').removeClass('animate-fade-down');
    jQuery('body').css({'opacity': '1'});
    jQuery('.video-overlay').css({'display': '1', 'visibility': 'visible'});
  });

  jQuery(".video-overlay .closeVideo, .video-overlay").click(function () {
    jQuery('.video-overlay').removeClass('active');
    jQuery('.video-overlay').css({'display': '0', 'visibility': 'hidden'});
    jQuery('body').css("overflow", "inherit");
    jQuery('.video-overlay iframe').attr("src", "");
  });


});

// addthis code for blog-detail page
//jQuery(window).on("load", function () {
//  var headOffset = '';
//  setTimeout(function () {
////    if (jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss').length > 0) {
////      jQuery('.addthis-smartlayers').css({'display': 'block'});
////      var containerOffset = jQuery('.container').offset().left;
////      var headHeight = jQuery('.blog-details .content-wrap h2').height();
////      var headPadding = parseInt(jQuery('.blog-details .content-wrap h2').css('padding-bottom')) / 2;
////      headOffset = jQuery('.blog-details .content-wrap h2').offset().top + headHeight + headPadding;
////      jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss').css('left', (containerOffset + 20) + 'px');
////      jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
////    }
//  }, 1000);
//});
//
//jQuery(window).on("resize", function () {
//  var headOffset = '';
////  if (jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss').length > 0) {
////    jQuery('.addthis-smartlayers').css({'display': 'block'});
////    var containerOffset = jQuery('.container').offset().left;
////    var headHeight = jQuery('.blog-details .content-wrap h2').height();
////    var headPadding = parseInt(jQuery('.blog-details .content-wrap h2').css('padding-bottom')) / 2;
////    headOffset = jQuery('.blog-details .content-wrap h2').offset().top + headHeight + headPadding;
////    jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss').css('left', (containerOffset + 20) + 'px');
////    jQuery('.page-id-147 .addthis-smartlayers .atss, .single-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
////  }
//}).resize();


jQuery(window).load(function () {
  var $topOffset = '';
  setTimeout(function () {
    if (jQuery('.single-post .addthis-smartlayers .atss').length > 0) {
      jQuery('.addthis-smartlayers').css({'display': 'block'});
      var $Offset = jQuery('.single-post .blog-add-this').offset().left;
      $topOffset = (jQuery('.single-post .blog-add-this').offset().top);   
      jQuery('.single-post .addthis-smartlayers .atss').css('left', ($Offset) + 'px');
      jQuery('.single-post .addthis-smartlayers .atss')[0].style.setProperty('top', $topOffset + 'px', 'important');
    }
  }, 100);      
});

jQuery(window).on("resize", function () {      
  var $topOffset = '';
  if (jQuery('.single-post .addthis-smartlayers .atss').length > 0) {
    jQuery('.addthis-smartlayers').css({'display': 'block'});
    var $Offset = jQuery('.single-post .blog-add-this').offset().left;
    $topOffset = (jQuery('.single-post .blog-add-this').offset().top);   
    jQuery('.single-post .addthis-smartlayers .atss').css('left', ($Offset) + 'px');
    jQuery('.single-post .addthis-smartlayers .atss')[0].style.setProperty('top', $topOffset + 'px', 'important');     
  }
}).resize();


// addthis code for career page
jQuery(window).load(function () {
  var win_width = jQuery(window).width();
  if (win_width > 992) {
    var headOffset = '';
    setTimeout(function () {
      if (jQuery('.single-job-post .addthis-smartlayers .atss').length > 0) {
        jQuery('.addthis-smartlayers').css({'display': 'block'});
        var containerOffset = jQuery('.single-job-post .btn-print').offset().left;
        headOffset = (jQuery('.single-job-post .btn-print').offset().top + 84);
        jQuery('.single-job-post .addthis-smartlayers .atss').css('left', (containerOffset + 42) + 'px');
        jQuery('.single-job-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
      }
    }, 100);
  } else if (win_width < 992) {
    var headOffset = '';
    if (jQuery('.single-job-post .addthis-smartlayers .atss').length > 0) {
      jQuery('.addthis-smartlayers').css({'display': 'block'});
      var containerOffset = jQuery('.single-job-post .btn-print').offset().left;
      headOffset = (jQuery('.single-job-post .btn-print').offset().top + 84);
      jQuery('.single-job-post .addthis-smartlayers .atss').css('left', (containerOffset) + 'px');
      jQuery('.single-job-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
    }

  }
});

jQuery(window).on("resize", function () {
  var win_width = jQuery(window).width();
  if (win_width > 992) {
    var headOffset = '';
    if (jQuery('.single-job-post .addthis-smartlayers .atss').length > 0) {
      jQuery('.addthis-smartlayers').css({'display': 'block'});
      var containerOffset = jQuery('.single-job-post .btn-print').offset().left;
      headOffset = (jQuery('.single-job-post .btn-print').offset().top + 84);
      jQuery('.single-job-post .addthis-smartlayers .atss').css('left', (containerOffset + 42) + 'px');
      jQuery('.single-job-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
    }
  } else if (win_width < 992) {
    var headOffset = '';
    if (jQuery('.single-job-post .addthis-smartlayers .atss').length > 0) {
      jQuery('.addthis-smartlayers').css({'display': 'block'});
      var containerOffset = jQuery('.single-job-post .btn-print').offset().left;
      headOffset = (jQuery('.single-job-post .btn-print').offset().top + 84);
      jQuery('.single-job-post .addthis-smartlayers .atss').css('left', (containerOffset) + 'px');
      jQuery('.single-job-post .addthis-smartlayers .atss')[0].style.setProperty('top', headOffset + 'px', 'important');
    }
  }

}).resize();