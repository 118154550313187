jQuery(document).ready(function () {
  setTimeout(function () {
    jQuery('.intro-with-image .item-outer').css({ 'opacity': '1' });
  }, 500);
  jQuery('.intro-with-image .item-outer').slick({
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    adaptiveHeight: false
  });


});

