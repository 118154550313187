jQuery(document).ready(function () {
  jQuery('.contact-us input, .contact-us textarea').focus(function () {
    jQuery(this).parent().addClass('focused');
  });

  jQuery('.contact-us input, .contact-us textarea').keypress(function () {
    jQuery('.thank').empty();
  });

  jQuery('.contact-us input, .contact-us textarea').blur(function () {
    var inputValue = jQuery(this).val();
    if (inputValue === "") {
      jQuery(this).parent().removeClass('focused');
    }
  });


    jQuery("#contactusform").on('submit', function(event) {
        event.preventDefault(); 
        var formData = jQuery(this).serialize();
        //console.log(formData);
        jQuery.ajax({
            type: 'POST',
            url: '/wp-content/themes/sandhill/templates/components/mailer.php',
            dataType: "json",
            data: formData,
            success: function(response) { 
             // console.log(response);
                  if(response == "1") {
                    jQuery('input, textarea').val('').removeClass('used');
                    jQuery('.contact-us .container .contact-form form .input-wrap').removeClass('focused');
                    jQuery('.contact-pop-up').css({
                        'opacity': '1',
                        'z-index': '2',
                        'transition': 'all .2s ease-in-out'
                    });
                    jQuery(".contact-pop-up .message-container").addClass("move-up").css({
                        "animation-duration": "0.8s",
                        "animation-delay": "0.3s",
                        "animation-name": "fadeInUp"
                    });
                    jQuery('.g-recaptcha-err').html("");
                }
                else {
                    jQuery('.unsuccess').css({'display': 'block'});
                    jQuery('.unsuccess').html("Mail is not sent, Please try again");
                }
            },
            error: function(xhr, status, error){
              console.log(error);
              jQuery('.unsuccess').css({'display': 'block'});
              jQuery('.unsuccess').html("Mail is not sent, Please try again");
            }
        });
    });




});


/*
 * google map
 */
jQuery(window).load(function () {
  if (jQuery("#job-post").length) {
    var $top_job_post = jQuery("#job-post");
    function $scrollUp() {
      jQuery('html, body').animate({
        scrollTop: ($top_job_post.offset().top)
      }, 1000);
    }
    jQuery('.footer .job-post').click(function () {
      $scrollUp();
    });
    if (window.location.href.indexOf("/contact-us/#/job-post") > -1) {
      $scrollUp();
    }
  }

});

function initialize() {
  if (0 == jQuery("#palo_alto_map").length)
    return !1;
  var t = [
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#6195a0"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "lightness": "0"
          },
          {
            "saturation": "0"
          },
          {
            "color": "#f5f5f2"
          },
          {
            "gamma": "1"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
          {
            "lightness": "-3"
          },
          {
            "gamma": "1.00"
          }
        ]
      },
      {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#bae5ce"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": 45
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#fac9a9"
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#4e4e4e"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#787878"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "transit.station.airport",
        "elementType": "labels.icon",
        "stylers": [
          {
            "hue": "#0a00ff"
          },
          {
            "saturation": "-77"
          },
          {
            "gamma": "0.57"
          },
          {
            "lightness": "0"
          }
        ]
      },
      {
        "featureType": "transit.station.rail",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#43321e"
          }
        ]
      },
      {
        "featureType": "transit.station.rail",
        "elementType": "labels.icon",
        "stylers": [
          {
            "hue": "#ff6c00"
          },
          {
            "lightness": "4"
          },
          {
            "gamma": "0.75"
          },
          {
            "saturation": "-68"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#eaf6f8"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c7eced"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "lightness": "-49"
          },
          {
            "saturation": "-53"
          },
          {
            "gamma": "0.79"
          }
        ]
      }
    ],
    e = {
      scrollwheel: !1,
      disableDefaultUI: !0,
      panControl: !0,
      zoomControl: false,
      mapTypeControl: !1,
      scaleControl: !0,
      streetViewControl: !1,
      overviewMapControl: !1,
      zoom: 14,
      center: new google.maps.LatLng(37.4452991, -122.1631149),
      styles: t
    }
  var markericon = {
    url: "/wp-content/themes/sandhill/dist/images/nav-pin.svg",
    //state your size parameters in terms of pixels
    size: new google.maps.Size(66, 87),
    scaledSize: new google.maps.Size(66, 87),
    origin: new google.maps.Point(0, 0)
  }
    , i = new google.maps.Map(document.getElementById("palo_alto_map"), e)

    , s = new google.maps.Marker({
    icon: markericon,
    position: new google.maps.LatLng(37.4452991, -122.1631149),
    map: i,
    title: "Sand Hill Global Advisors LLC",
    // must use optimized false for CSS
    optimized: false
  })
    , n = jQuery("#map_window").html();

  google.maps.event.addDomListener(window, "resize", function () {
    var center = i.getCenter();
    google.maps.event.trigger(i, 'resize');
    i.setCenter(center);
    i.panTo(new google.maps.LatLng(37.4452991, -122.1631149));
  });

  /* On load */

  var myoverlay = new google.maps.OverlayView();
  myoverlay.draw = function () {
    this.getPanes().markerLayer.id = 'markerLayer';
  };
  myoverlay.setMap(i);
  google.maps.event.addListenerOnce(i, 'idle', function () {
    jQuery('#markerLayer img');
    //this part runs when the mapobject is created and rendered
    google.maps.event.addListenerOnce(i, 'tilesloaded', function () {
      jQuery('#markerLayer img').length
      //anim_marker();
      i.panTo(new google.maps.LatLng(37.4452991, -122.1631149));
      //this part runs when the mapobject shown for the first time
    });
  });
}
function new_initialize() {
  if (0 == jQuery("#san_francisco_map").length)
    return !1;
  var t = [
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#6195a0"
          }
        ]
      },
      {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "lightness": "0"
          },
          {
            "saturation": "0"
          },
          {
            "color": "#f5f5f2"
          },
          {
            "gamma": "1"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
          {
            "lightness": "-3"
          },
          {
            "gamma": "1.00"
          }
        ]
      },
      {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#bae5ce"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": 45
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#fac9a9"
          },
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#4e4e4e"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#787878"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "simplified"
          }
        ]
      },
      {
        "featureType": "transit.station.airport",
        "elementType": "labels.icon",
        "stylers": [
          {
            "hue": "#0a00ff"
          },
          {
            "saturation": "-77"
          },
          {
            "gamma": "0.57"
          },
          {
            "lightness": "0"
          }
        ]
      },
      {
        "featureType": "transit.station.rail",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#43321e"
          }
        ]
      },
      {
        "featureType": "transit.station.rail",
        "elementType": "labels.icon",
        "stylers": [
          {
            "hue": "#ff6c00"
          },
          {
            "lightness": "4"
          },
          {
            "gamma": "0.75"
          },
          {
            "saturation": "-68"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
          {
            "color": "#eaf6f8"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c7eced"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "lightness": "-49"
          },
          {
            "saturation": "-53"
          },
          {
            "gamma": "0.79"
          }
        ]
      }
    ],
    e = {
      scrollwheel: !1,
      disableDefaultUI: !0,
      panControl: !0,
      zoomControl: false,
      mapTypeControl: !1,
      scaleControl: !0,
      streetViewControl: !1,
      overviewMapControl: !1,
      zoom: 14,
      center: new google.maps.LatLng(37.794537, -122.398540),
      styles: t
    }
  var markericon = {
    url: "/wp-content/themes/sandhill/dist/images/nav-pin.svg",
    //state your size parameters in terms of pixels
    size: new google.maps.Size(66, 87),
    scaledSize: new google.maps.Size(66, 87),
    origin: new google.maps.Point(0, 0)
  }
    , i = new google.maps.Map(document.getElementById("san_francisco_map"), e)

    , s = new google.maps.Marker({
    icon: markericon,
    position: new google.maps.LatLng(37.794537, -122.398540),
    map: i,
    title: "Sand Hill Global Advisors LLC",
    // must use optimized false for CSS
    optimized: false
  })
    , n = jQuery("#map_window").html();

  google.maps.event.addDomListener(window, "resize", function () {
    var center = i.getCenter();
    google.maps.event.trigger(i, 'resize');
    i.setCenter(center);
    i.panTo(new google.maps.LatLng(37.794537, -122.398540));
  });

  /* On load */

  var myoverlay = new google.maps.OverlayView();
  myoverlay.draw = function () {
    this.getPanes().markerLayer.id = 'markerLayer';
  };
  myoverlay.setMap(i);
  google.maps.event.addListenerOnce(i, 'idle', function () {
    jQuery('#markerLayer img');
    //this part runs when the mapobject is created and rendered
    google.maps.event.addListenerOnce(i, 'tilesloaded', function () {
      jQuery('#markerLayer img').length
      //anim_marker();
      i.panTo(new google.maps.LatLng(37.794537, -122.398540));
      //this part runs when the mapobject shown for the first time
    });
  });
}

jQuery(window).bind("load scroll resize", function () {
  google.maps.event.addDomListener(window, "load", initialize);
  google.maps.event.addDomListener(window, "load", new_initialize);

});
