jQuery(document).ready(function () {

  /*Append data attribute in footer links*/

  jQuery('.footer-right .internal-links li').each(function(){
    var str =jQuery(this).find('a').text();
    var className = str.replace(/\s/g, '-').toLowerCase();
    jQuery(this).addClass(className);
  });



  var doc = new jsPDF('p', 'pt', 'a4', true);
  var specialElementHandlers = {
  };

  jQuery('.blog-details .btn-download').click(function () {
    doc.fromHTML(jQuery('.blog-details .content-wrap').html(), 15, 15, {
      'width': 500,
      'elementHandlers': specialElementHandlers
    }, function (dispose) {
      doc.save('blog-details.pdf');
    });
  });

// print a copy by click
  jQuery('.btn-print').on('click', function () {
    window.print();
  });

  data_print();
});

// funtion of print according to content
function data_print() {
  jQuery.fn.printDiv = function () {
    var printContents = jQuery(this).html();
    var originalContents = jQuery('body').html();
    jQuery('body').html(printContents);
    window.print();
    jQuery('body').html(originalContents);
  };

  // Print
  jQuery('[data-print]').click(function () {
    jQuery('[data-print-content]').printDiv();
  });
}


jQuery(window).on("load", function () {
  /*
   *scroll up grid-two section
   */
  if (jQuery(".grid-two").length) {
    var $top = jQuery(".grid-two");
    function scrollUp() {
      jQuery('html, body').animate({
        scrollTop: ($top.offset().top)
      }, 1000);
    }

    jQuery('.our-people').click(function () {
      scrollUp();
    });
    if (window.location.href.indexOf("/about/#/our-people") > -1) {
      scrollUp();
    }
  }

  if (jQuery("#what-we-do").length) {
    var $top_what_we_do = jQuery("#what-we-do");
    function $scrollUp() {
      jQuery('html, body').animate({
        scrollTop: ($top_what_we_do.offset().top)
      }, 1000);
    }
    jQuery('.what-we-do').click(function () {
      $scrollUp();
    });
    if (window.location.href.indexOf("/about/#/what-we-do") > -1) {
      $scrollUp();
    }
  }  
});