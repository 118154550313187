(function (cjs, an) {

    var p; // shortcut to reference prototypes
    var lib={};var ss={};var img={};
    lib.ssMetadata = [];


// symbols:
// helper functions:

    function mc_symbol_clone() {
        var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
        clone.gotoAndStop(this.currentFrame);
        clone.paused = this.paused;
        clone.framerate = this.framerate;
        return clone;
    }

    function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
        var prototype = cjs.extend(symbol, cjs.MovieClip);
        prototype.clone = mc_symbol_clone;
        prototype.nominalBounds = nominalBounds;
        prototype.frameBounds = frameBounds;
        return prototype;
    }


    (lib.tree2 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#23A455").s().p("AgBAhIAAgHQgHgBgDgCQgGgEAAgLIAJAAIACAHQABACAEABIAAgQIgCgBQgIgBgCgDQgDgDAAgGIABgGQABgDACgBQACgDAEgBIAFgBIAAgFIADAAIAAAFQAFABAEACQAGAEAAAJIgJAAIgBgGQgCgCgDAAIAAANQAHADAEADQAFADAAAGQAAAKgGAEQgFACgFABIAAAHgAACARIAEAAQACgCAAgFQAAAAAAgBQAAgBAAAAQAAgBgBAAQAAgBgBAAIgEgCgAgFgSQgBABAAAAQAAABAAAAQgBABAAABQAAAAAAABQAAACADADIADABIAAgMQAAAAgBAAQgBAAAAAAQgBABAAAAQgBAAAAAAg");
        this.shape.setTransform(10.9,31.3);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgEgDAAgFIAGAAIABADIADACIAAgJIgCAAQgEgBgBgBQgCgCAAgDIABgEIACgCIADgCIADgBIAAgDIABAAIAAADQADAAACACQAEACAAAFIgFAAIgBgDQAAAAAAAAQgBgBAAAAQAAAAgBAAQAAAAgBAAIAAAIIAGACQAEACAAAEQAAAFgFACQgCACgDAAIAAAEgAABAKIADgBQAAAAAAAAQAAAAAAgBQAAAAAAgBQABAAAAgBIgBgDIgDgBgAgDgJIgBACQAAABABAAQAAABAAAAQAAAAAAAAQABABAAAAIACABIAAgHg");
        this.shape_1.setTransform(27.7,11.9);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgDgCAAgGIAFAAIABADQAAABABAAQAAAAAAAAQABABAAAAQAAAAABAAIAAgJIgCAAQgDgBgCgBQgCgCAAgDIABgEIACgDIADgCIADAAIAAgDIABAAIAAADIAFACQADACABAFIgGAAIAAgDIgDgCIAAAIIAHADQADACAAADQAAAFgFADQgBABgEAAIAAAFgAABAKIACgBIABgDIgBgDIgCgBgAgDgKIgBADIACADIACABIAAgIg");
        this.shape_2.setTransform(16.2,23.7);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#23A455").s().p("AgBAdIAAgGQgGgBgDgCQgFgEAAgJIAJAAIABAGQABACADABIAAgOIgCgBQgGgBgDgDQgCgDAAgEIABgGIACgEIAGgDIAEgBIAAgEIADAAIAAAEQAGABABACQAGADAAAIIgIAAIgBgEQgCgDgCAAIAAAMIAKAFQAEADAAAFQAAAJgGADQgDACgFABIAAAGgAACAQIAEgCQABgBAAgEQAAAAAAgBQAAAAAAgBQgBAAAAgBQAAAAAAgBIgEgCgAgEgPQgBAAAAABQAAAAgBABQAAAAAAABQAAAAAAABQAAAAAAABQAAAAAAABQABAAAAABQAAAAABABIADABIAAgLQAAAAgBAAQAAAAgBABQAAAAgBAAQAAAAAAABg");
        this.shape_3.setTransform(41.1,9.8);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#23A455").s().p("AAAAdIAAgGQgGgBgEgCQgFgDAAgKIAIAAIABAGQACACAEAAIAAgNIgDgBQgGgBgCgCQgDgFAAgEIABgFIADgEIAEgDIAGgBIAAgEIABAAIAAAFQAGAAADACQAFADABAIIgJAAIgBgEQgBgCgEgBIAAANIALAEQAEACAAAGQAAAJgGADQgCABgHACIAAAGgAABAPIAFgBQABgCABgDQgBAAAAgBQAAAAAAgBQAAAAgBgBQAAgBAAAAIgFgCgAgEgPQgBAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQAAABAAABQAAAAABABQAAAAAAABQAAABABAAIAEACIAAgMg");
        this.shape_4.setTransform(32.8,24.7);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#23A455").s().p("AgBAgIAAgHQgGAAgEgDQgGgEAAgKIAKAAIABAGQABACAEABIAAgPIgDgBQgFgBgEgDQgDgEABgFIABgFQAAgDACgCIAGgEIAFAAIAAgFIADAAIAAAFQAFAAAEACQAGAEAAAJIgKAAIgBgFQAAgCgEgBIAAAOQAIADADACQAFADAAAGQAAAIgGAFQgFADgFAAIAAAHgAACARIAEgBQACgCAAgEQAAAAAAgBQAAgBAAAAQgBgBAAAAQAAgBgBAAIgEgDgAgFgRQAAABgBAAQAAABAAAAQAAABAAAAQAAABAAAAQAAADABACIAEACIAAgNQAAAAgBAAQAAAAgBABQAAAAgBAAQgBABAAAAg");
        this.shape_5.setTransform(18.4,7.3);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#23A455").s().p("AAAAgIAAgHQgHgBgEgCQgGgDAAgLIAJAAIACAGQABACAFABIAAgPIgEgBQgGgBgDgDQgCgEgBgEIABgHQABgCADgCQABgCAEgCIAGgBIAAgEIACAAIAAAEQAFABAEACQAGAFAAAIIgJAAIgBgFQgBgCgEgBIAAANQAHADAEADQAFACAAAHQAAAJgHAEQgEACgFABIAAAHgAACARIAEgBQACgCAAgEQAAgBAAAAQAAgBgBgBQAAAAAAgBQgBAAAAAAIgEgDgAgFgRQAAAAgBABQAAAAAAABQAAAAAAABQgBAAAAABQAAADACACIAFABIAAgLQgBAAgBAAQgBAAAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_6.setTransform(27.2,4.2);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#23A455").s().p("AAAAgIAAgHQgIgBgDgCQgGgDAAgLIAKAAIABAGQACACAEABIAAgPIgDgBQgGgBgEgDQgDgDAAgFIABgHIAEgEQACgCADgCIAGgBIAAgEIACAAIAAAEQAGABADACQAGAEAAAJIgJAAIgBgFQgCgDgDAAIAAANIALAGQAFACAAAHQAAAJgHAEQgEACgFABIAAAHgAACARIAEgBQACgDAAgDQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAgBgBAAIgEgDgAgFgRQgBAAAAABQAAAAAAABQgBAAAAABQAAAAAAABQAAADADACIAEABIAAgLQgEgBgBACg");
        this.shape_7.setTransform(25.9,29.8);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#23A455").s().p("AgBAZIAAgFIgIgDQgEgDAAgIIAHAAIACAFIADADIAAgMIgBgBQgFgBgDgCQgCgDAAgEIABgFIACgDIAFgDIADgBIAAgDIACAAIAAADIAHADQAFADABAGIgIAAIgBgDQAAgBAAAAQgBAAAAgBQgBAAAAAAQgBAAgBgBIAAALIAJAEQAEACAAAFQAAAHgFADIgIADIAAAFgAABANIAEgBQAAAAAAAAQABgBAAAAQAAgBAAAAQABgBAAgBQAAAAgBgBQAAAAAAgBQAAAAgBgBQAAAAAAgBIgEgBgAgEgNIgBADQAAABAAAAQAAABAAAAQABABAAAAQAAAAABABIACABIAAgJIgDABg");
        this.shape_8.setTransform(36.4,14.1);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#23A455").s().p("AAAAZIAAgFQgHgBgBgCQgFgDAAgHIAHAAIABAEQABACAEABIAAgMIgDgBQgFgBgCgCQgCgDAAgEIABgFIADgDIADgDIAFgBIAAgDIABAAIAAADQAGABACACQAEADAAAGIgGAAIgCgDQAAgBAAAAQgBAAAAgBQgBAAAAAAQgBAAgBAAIAAAKQAIADABABQAEACAAAFQAAAHgFAEQgEACgEAAIAAAFgAABAOIAEgBQAAgBABAAQAAgBAAAAQAAgBAAAAQABgBAAgBQAAAAgBgBQAAgBAAAAQAAgBAAAAQgBAAAAgBIgEgBgAgEgNQAAAAAAABQAAAAgBAAQAAABAAAAQAAABAAAAQAAABAAAAQAAABABAAQAAABAAAAQABAAAAABIADABIAAgJg");
        this.shape_9.setTransform(46.9,26.4);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#23A455").s().p("AAAASIAAgDQgEAAgCgCQgDgCgBgGIAGAAIABAEIADABIAAgJIgBAAQgFgBgBgBQgBgCgBgEIABgDIABgDIAEgCIADAAIAAgCIABAAIAAACIAGABQADADAAAFIgFAAIgBgDQAAAAAAgBQgBAAAAAAQAAAAgBAAQAAAAgBAAIAAAIIAHACQACACAAAEQAAAFgDACQgCACgEAAIAAADgAABAKIADAAIABgEIgBgDIgDgBgAgCgJIgBACIABADIACABIAAgHg");
        this.shape_10.setTransform(32.8,16.7);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgDgCAAgGIAFAAIABADIADACIAAgJIgCAAQgDgBgCgBQgCgCAAgDIABgEIACgCIADgCIADgBIAAgDIABAAIAAADQAEAAABACQAEACAAAFIgFAAIgBgDQAAAAgBAAQAAgBAAAAQAAAAgBAAQAAAAgBAAIAAAHIAHADQACABAAAEQAAAGgDACIgGACIAAAEgAABAKIACgBQABAAAAAAQAAAAAAgBQABAAAAgBQAAAAAAgBIgBgDIgDgBgAgCgJIgBACIABADIACABIAAgHIgCABg");
        this.shape_11.setTransform(38.6,26.3);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#23A455").s().p("AAAAgIAAgHQgHAAgEgDQgGgEAAgKIAJAAIACAGQACACAEABIAAgOIgDgBQgIgCgCgDQgDgEAAgFQAAgDABgDQABgCADgCQACgCADgBIAGgCIAAgEIACAAIAAAEQAHACACACQAGAEAAAHIgJAAIgBgEQgBgCgEgBIAAAOQAIADADACQAFACAAAIQAAAIgHAEQgEADgFAAIAAAHgAACARIAEgBQACgBAAgEQAAgEgCgCIgEgBgAgFgRQAAAAgBABQAAAAAAABQAAAAAAABQgBABAAABQAAAAABABQAAABAAAAQAAABABAAQAAABABAAIAEACIAAgNQgBAAgBABQgBAAAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_12.setTransform(42.7,19);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#23A455").s().p("AgBAgIAAgHQgHgBgDgCQgGgDAAgLIAKAAQAAAFABABQABADAEAAIAAgPIgCgBQgHgBgDgDQgDgDAAgGIABgGIADgEIAGgEIAFgBIAAgEIADAAIAAAEQAGABADACQAGAEAAAJIgJAAIgBgFQgCgDgDAAIAAAOIALAFQAFADAAAGQAAAKgHADQgCACgHABIAAAHgAACARIAEgBQACgBAAgFQAAAAAAgBQAAgBAAAAQAAgBgBAAQAAgBgBAAIgEgDgAgFgRIgCAEQAAADADACIADACIAAgNQAAAAgBAAQgBAAAAABQgBAAAAAAQgBAAAAABg");
        this.shape_13.setTransform(42,30.7);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#23A455").s().p("AAAAbIAAgGQgGgBgDgCQgFgDAAgIIAIAAIABAFQABACAEABIAAgNIgDgBQgGgBgCgCQgCgCAAgFIABgFIADgEQABgCADAAIAFgBIAAgFIABAAIAAAFQAGABACABQAFADAAAHIgIAAIgBgEQAAAAAAgBQgBAAAAAAQgBgBAAAAQgBAAgBAAIAAALIAKAEQAEACAAAGQAAAHgGADIgIADIAAAGgAABAPIAEgBQACgDAAgDQAAAAAAAAQgBgBAAAAQAAgBAAAAQgBgBAAAAIgEgCgAgEgOIgBAEQAAABAAAAQAAABAAAAQABABAAAAQAAAAABAAIADACIAAgKQgBAAAAAAQgBAAgBAAQAAABAAAAQgBAAAAAAg");
        this.shape_14.setTransform(8.4,12.7);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#23A455").s().p("AAAAaIAAgFQgFgBgEgCQgFgDAAgIIAIAAIABAFIAFADIAAgNIgCgBQgGgBgCgCQgCgDgBgEIABgFIADgEIAEgCIAFgBIAAgFIABAAIAAAFQAGAAACACQAFADAAAHIgHAAIgBgEQgBgBAAAAQgBAAAAgBQgBAAAAAAQgBAAgBAAIAAALIAKAEQAEACAAAGQgBAHgFADIgIADIAAAFgAABAPIAEgBQACgCAAgEQAAAAgBgBQAAAAAAgBQAAAAAAgBQgBAAAAAAIgEgCgAgEgNQAAAAAAAAQgBAAAAABQAAAAAAABQAAAAAAABQAAABAAAAQAAABAAAAQABABAAAAQAAAAAAAAIAEACIAAgKQgBAAAAAAQgBAAAAAAQgBABAAAAQgBAAAAABg");
        this.shape_15.setTransform(19.1,13.8);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#23A455").s().p("AAAAaIAAgFQgFgBgEgCQgFgDAAgIIAIAAIABAFQABABAEABIAAgMIgDgBQgFgBgDgCQgCgDAAgEIABgFIADgEQACgCACgBIAFgBIAAgDIABAAIAAADQAFABADACQAFADAAAHIgIAAIgBgEQAAAAAAgBQgBAAAAgBQgBAAAAAAQgBAAgBAAIAAALQAHACADACQAEACAAAGQAAAHgGADQgCACgGABIAAAFgAABAOIAEgBQAAAAABgBQAAAAAAgBQAAAAABgBQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAgBAAAAQgBgBAAAAIgEgCgAgEgOIgBAEQAAAAAAABQAAAAAAABQABAAAAABQAAAAABABIADABIAAgKQgBAAAAAAQgBAAgBAAQAAAAAAABQgBAAAAAAg");
        this.shape_16.setTransform(20.6,25);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#23A455").s().p("AAAAQIAAgEIgFgCQgCgCAAgEIAEAAIAAADIADABIAAgHIgBgBQgCAAgCgBQgBAAAAgBQAAAAgBgBQAAAAAAgBQAAAAAAgBIABgDIABgCIACgBIADgBIAAgDIABAAIAAADQABAAAAAAQABAAAAAAQABABAAAAQABAAAAABQADABAAAEIgEAAIgBgCQAAAAAAgBQAAAAgBAAQAAAAAAAAQgBAAAAAAIAAAGIAFACQADABAAAEQgBAEgDABQAAABgBAAQAAAAgBABQAAAAgBAAQAAAAgBAAIAAAEgAABAIIACAAIABgDIgBgDIgCgBgAgCgIIgBACIABADIACABIAAgGg");
        this.shape_17.setTransform(43.6,25.8);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#23A455").s().p("AAAAPIAAgDIgFgBQgCgCAAgFIAEAAIAAADQABAAAAAAQAAABABAAQAAAAAAAAQABAAAAAAIAAgHIgBAAQgDgBgBgBIgCgEIABgDIABgCIACgCIADAAIAAgCIABAAIAAACIAEABQADADAAADIgFAAIAAgCIgCgBIAAAGIAFACQADACAAACQAAAFgEACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgHIgBACIABACIACABIAAgGg");
        this.shape_18.setTransform(13.1,7);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#23A455").s().p("AAAAPIAAgDQgDAAgCgCQgCgBAAgFIAEAAIABADIACABIAAgHIgBAAIgEgCIgBgEIAAgDIABgCIADgCIACAAIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAAAABABQAAAAAAAAQADACAAAEIgEAAIgBgCIgCgCIAAAHIAFACQADABAAADQAAAFgEABQAAABAAAAQgBAAAAAAQgBABgBAAQAAAAgBAAIAAADgAABAIIACgBIABgCIgBgDIgCgBgAgCgIIgBACIABADIACAAIAAgFg");
        this.shape_19.setTransform(6.3,15.7);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#23A455").s().p("AAAAPIAAgDIgEgBQgDgCAAgFIAEAAIABADQAAAAAAAAQAAABABAAQAAAAAAAAQABAAAAAAIAAgHIgBAAQgCgBgCgBIgCgEIABgDIABgCIACgCIADAAIAAgCIABAAIAAACIAEABQADADAAADIgEAAIAAgCIgDgBIAAAGIAFACQACABAAADQAAAFgDACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgIIgBADIABACIACABIAAgGg");
        this.shape_20.setTransform(13.8,11.9);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#23A455").s().p("AAAAPIAAgDQgDAAgBgCQgDgBAAgFIAEAAIABADIACABIAAgHIgBAAQgCgBgCgBIgBgEIAAgDIABgCIADgCIACAAIAAgCIABAAIAAACIAEABQADADAAADIgEAAIAAgCIgDgBIAAAGIAFACQACABAAADQAAAFgDACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgIIgBACIABADIACABIAAgGg");
        this.shape_21.setTransform(10.2,25.1);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#23A455").s().p("AgBAoIAAgJQgJgCgDgCQgIgFAAgMIAMAAIACAHQABAEAFAAIAAgSIgDgBQgJgCgDgEQgDgEAAgGIABgIQABgCADgEQADgDAEAAIAGgCIAAgGIADAAIAAAHQAHgBAEAEQAIAEAAALIgLAAIgCgGQgBgDgFAAIAAAQQALAEADACQAGAEAAAJQAAALgIAEQgFADgHABIAAAJgAACAVIAFgBQADgCAAgFQAAgEgDgDIgFgCgAgGgVQgCACAAADQAAAEADACIAEACIAAgPQgEAAgBACg");
        this.shape_22.setTransform(5.9,25.3);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#23A455").s().p("AAAARIAAgEQgDAAgCgCQgDgCAAgFIAFAAIABADQAAAAAAABQAAAAABAAQAAABAAAAQABAAAAAAIAAgIIgBgBIgFgBIgBgFIAAgDIACgCIADgBIACgBIAAgDIABAAIAAADQABAAABAAQAAAAABAAQAAABABAAQAAAAABABQADABAAAEIgFAAIAAgCIgDgBIAAAHIAGACQACABAAAEQAAAEgDACIgFABIAAAFgAABAIIACAAIABgDIgBgDIgCgBgAgCgJIgBADIABADIACAAIAAgGg");
        this.shape_23.setTransform(23.7,4.2);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#23A455").s().p("AAAAQIAAgDQgEgBgBgBQgDgBAAgGIAFAAIAAADQABAAAAABQAAAAABAAQAAAAABAAQAAAAAAAAIAAgHIgBgBQgEAAgBgCQAAAAgBAAQAAgBAAAAQAAgBAAAAQgBgBAAAAIABgEIACgCIACgCIADAAIAAgCIABAAIAAACIAFABQACACABAFIgFAAIAAgDIgDgCIAAAHIAGADQACABAAAEQAAAEgDACQgCABgDABIAAADgAABAIIACAAQAAAAAAAAQABgBAAAAQAAAAAAgBQAAAAAAgBIgBgCIgCgBgAgCgJIgBADQAAAAAAABQAAAAAAAAQABAAAAABQAAAAAAAAIACABIAAgGg");
        this.shape_24.setTransform(32.1,5.4);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#23A455").s().p("AAAARIAAgEIgFgBQgDgCAAgGIAFAAIAAAEIADABIAAgHIgBgBIgFgCQgBAAAAgBQAAAAAAgBQgBAAAAgBQAAgBAAgBIABgCIABgDIADgCIADAAIAAgCIABAAIAAACQABAAAAAAQABAAAAAAQABABAAAAQABAAAAAAQAEADAAAEIgFAAIgBgCQAAgBAAAAQAAAAgBgBQAAAAAAAAQgBAAAAAAIAAAHIAGACQACACAAADQAAAEgDADQgCABgDAAIAAAEgAABAJIACAAIABgEQAAAAAAAAQAAgBAAAAQAAAAgBAAQAAgBAAAAIgCgBgAgCgIIgBACIABACIACABIAAgGg");
        this.shape_25.setTransform(14.8,16.5);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#23A455").s().p("AAAARIAAgEIgFgCQgDgBAAgGIAFAAIABAEIACABIAAgIIgBgBQgDAAgCgBQAAgBgBAAQAAgBAAAAQAAgBAAAAQgBgBAAgBIABgCIABgDIAEgCIACAAIAAgDIABAAIAAADQABAAAAAAQABAAABAAQAAABABAAQAAAAABABQACABAAAEIgEAAIAAgBQgBgBAAAAQAAAAAAgBQgBAAAAAAQAAAAgBAAIAAAHIAGACQACABAAAEQAAAEgDACIgFACIAAAEgAABAJIACgBIABgCIgBgDIgCgBgAgCgIIgBACIABACIACACIAAgHg");
        this.shape_26.setTransform(25.2,16.7);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#23A455").s().p("AAAAQIAAgDIgFgBQgDgCAAgGIAFAAIABADIACACIAAgIIgBAAQgEgBgBgBIgBgEIAAgDIACgDIADgCIACAAIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAABABAAQAAAAABAAQADACAAAFIgFAAIAAgCQgBgBAAAAQAAAAAAgBQgBAAAAAAQAAAAgBAAIAAAHIAGACQACABAAADQAAAFgDADIgFABIAAADgAABAJIACgBIABgDIgBgCIgCgBgAgCgIIgBACIABACIACABIAAgGIgCABg");
        this.shape_27.setTransform(21.2,17.3);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#23A455").s().p("AAAAQIAAgDIgFgCQgDgBAAgGIAFAAIABADIACACIAAgIIgBgBQgDAAgCgBIgCgEIABgEIABgCIAEgBIACgBIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAABABAAQAAAAABAAQACACAAAFIgEAAIAAgDIgDgBIAAAGIAGADQACABAAAEQAAAFgDACIgFABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgJIgBADIABADIACAAIAAgGg");
        this.shape_28.setTransform(27.9,23.8);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#23A455").s().p("AAAAYIAAgFIgIgDQgEgCAAgIIAHAAIABAFIAEACIAAgLIgCgBQgGgBgCgCQgBgDAAgDIABgFIABgDIAFgDIAEAAIAAgEIABAAIAAAEQAEAAADACQAFADAAAGIgHAAIgBgEIgEgCIAAAKIAIAEQAEABAAAGQAAAGgFADIgHADIAAAFgAABANIAEgBQAAgBAAAAQAAAAABgBQAAAAAAgBQAAgBAAAAQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAAAAAgBIgEgCgAgDgMIgBADQAAAAAAABQAAAAAAABQAAAAAAAAQABABAAAAIADACIAAgKQgBAAAAAAQgBAAAAABQgBAAAAAAQAAAAAAABg");
        this.shape_29.setTransform(15.3,32.5);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#23A455").s().p("AgQATIAAgEIAbAAQADAAAAgEIAAgdIAEAAIAAAdQgBAIgGAAg");
        this.shape_30.setTransform(13.1,7.5);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#23A455").s().p("AgRATIAAgEIAbAAQAFAAAAgEIAAgdIACAAIAAAdQABAIgHAAg");
        this.shape_31.setTransform(25.2,17.4);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#23A455").s().p("AgZAcIAAgGIAoAAQAGAAAAgGIAAgqIAFAAIAAAqQAAAMgJAAg");
        this.shape_32.setTransform(32.8,17.3);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#23A455").s().p("AgpAtIAAgJIBCAAQAJAAAAgJIAAhHIAIAAIAABHQAAASgPAAg");
        this.shape_33.setTransform(41.6,31.5);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#23A455").s().p("AgCAOQgIAAAAgIIAAgTIADAAIAAAOQAAAHAGAAIAMAAIAAAGg");
        this.shape_34.setTransform(22.3,20);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#23A455").s().p("AgCAOQgIAAAAgIIAAgTIADAAIAAAOQAAAHAGAAIAMAAIAAAGg");
        this.shape_35.setTransform(28.8,26.3);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#23A455").s().p("AgSATQgIAAgDgCQgFgDAAgKIAAgWIAGAAIAAATQAAAFADACIAHABIA1AAIAAAKg");
        this.shape_36.setTransform(26.9,7.3);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#23A455").s().p("AgSATQgIAAgEgCQgEgEAAgJIAAgXIAGAAIAAAUQAAAFACACIAHABIA2AAIAAAKg");
        this.shape_37.setTransform(7.8,16.1);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#23A455").s().p("AgSAUQgIAAgDgDQgFgDAAgKIAAgXIAGAAIAAAUQAAAIAJAAIA2AAIAAALg");
        this.shape_38.setTransform(15.3,35.2);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#23A455").s().p("AgUBYIAAgJIAbAAQAHAAAAgJIAAidIAHAAIAAChQAAAOgNAAg");
        this.shape_39.setTransform(21.6,22.2);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#23A455").s().p("AgChYIAFAAIAACwIgFACg");
        this.shape_40.setTransform(11.3,13.4);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#23A455").s().p("AgNCCQgQgBAAgTIAAjuIAHAAIAADrQAAAOAMAAIAoAAIAAAJg");
        this.shape_41.setTransform(32.8,18.3);

        this.shape_42 = new cjs.Shape();
        this.shape_42.graphics.f("#23A455").s().p("Ag1AuIAAgIIALAAQAGAAAAgGIAAgpQAAgIAIAAIAOAAQAEAAAAgDIAAgSQAAgHAHAAIA5AAIAAACIg4AAQgFABABAEIAAASQAAAIgIAAIgNAAQgEAAAAAHIAAAnQAAAMgJAAg");
        this.shape_42.setTransform(16.6,15.6);

        this.shape_43 = new cjs.Shape();
        this.shape_43.graphics.f("#23A455").s().p("Ag1AuIAAgIIAKAAQAHAAAAgGIAAgoQgBgJAKAAIAMAAQAEAAABgEIAAgSQAAgGAGAAIA6AAIAAACIg4AAQgEAAgBAGIAAARQABAIgIAAIgNAAQgEAAAAAHIAAAnQAAAMgKAAg");
        this.shape_43.setTransform(35.8,9.2);

        this.shape_44 = new cjs.Shape();
        this.shape_44.graphics.f("#23A455").s().p("AA8BhQgUAAAAgZIAAhUIgBgKQgCgFgGAAIgcAAQgOAAgBgRIAAgkQABgGgCgDQgCgCgGAAIhAAAIAAgFIBDAAQAOAAAAANIAAAmQABAHAHAAIAcAAQASAAAAATIAABWQABANANAAIAWAAIAAARg");
        this.shape_44.setTransform(14.7,31.7);

        this.shape_45 = new cjs.Shape();
        this.shape_45.graphics.f("#23A455").s().p("AhLC3QgIgCgBgCIAAkNQAAgHAFgEQADgFAHAAIBqAAQAIAAAAgIIAAg6QAAgNALAAIAcAAIAAAHIgYAAQgGAAAAAIIAABOQAAAPgQAAIg0AAQgMAAAAAQIAADwQAAACgJACQgIACgMABQgMgBgIgCg");
        this.shape_45.setTransform(31.1,45.9);

        this.shape_46 = new cjs.Shape();
        this.shape_46.graphics.f("#2B7848").s().p("AizCbQhMg8AAhVIAAgTQAAhVBMg8QBKg8BpAAQBpAABMA8QBKA8AABVIAAATQAABVhKA8QhMA8hpAAQhpAAhKg8g");
        this.shape_46.setTransform(25.6,21.5);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_46},{t:this.shape_45},{t:this.shape_44},{t:this.shape_43},{t:this.shape_42},{t:this.shape_41},{t:this.shape_40},{t:this.shape_39},{t:this.shape_38},{t:this.shape_37},{t:this.shape_36},{t:this.shape_35},{t:this.shape_34},{t:this.shape_33},{t:this.shape_32},{t:this.shape_31},{t:this.shape_30},{t:this.shape_29},{t:this.shape_28},{t:this.shape_27},{t:this.shape_26},{t:this.shape_25},{t:this.shape_24},{t:this.shape_23},{t:this.shape_22},{t:this.shape_21},{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.tree2, new cjs.Rectangle(0,0,51.1,64.5), null);


    (lib.tree1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#23A455").s().p("AgBAhIAAgHQgHgBgDgCQgGgEAAgLIAKAAIABAHQABACAEABIAAgQIgCgBQgIgBgCgDQgDgDAAgGIABgGQABgDACgBQACgDAEgBIAFgBIAAgFIADAAIAAAFQAFABAEACQAGAEAAAJIgJAAIgBgGQgCgCgDAAIAAANQAHADAEADQAFADAAAGQAAAKgGAEQgFACgFABIAAAHgAACARIAEAAQACgCAAgFQAAAAAAgBQAAgBAAAAQAAgBgBAAQAAgBgBAAIgEgCgAgFgSQgBABAAAAQAAABAAAAQgBABAAABQAAAAAAABQAAACADADIADABIAAgMQAAAAgBAAQgBAAAAAAQgBABAAAAQgBAAAAAAg");
        this.shape.setTransform(10.9,31.3);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgEgDAAgFIAGAAIABADIADACIAAgJIgCAAQgEgBgBgBQgCgCAAgDIABgEIACgCIADgCIADgBIAAgDIABAAIAAADQADAAACACQAEACAAAFIgFAAIgBgDQAAAAAAAAQgBgBAAAAQAAAAgBAAQAAAAgBAAIAAAIIAGACQAEACAAAEQAAAFgFACQgCACgDAAIAAAEgAABAKIADgBQAAAAAAAAQAAAAAAgBQAAAAAAgBQAAAAABgBIgBgDIgDgBgAgDgJIgBACQABABAAAAQAAABAAAAQAAAAAAAAQABABAAAAIACABIAAgHg");
        this.shape_1.setTransform(27.7,11.9);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgDgCAAgGIAFAAIABADQAAABABAAQAAAAAAAAQABABAAAAQAAAAABAAIAAgJIgCAAQgDgBgCgBQgCgCAAgDIABgEIACgDIADgCIADAAIAAgDIABAAIAAADIAFACQADACABAFIgGAAIAAgDIgDgCIAAAIIAHADQADACAAADQAAAFgFADQgBABgEAAIAAAFgAABAKIACgBIABgDIgBgDIgCgBgAgDgKIgBADIACADIACABIAAgIg");
        this.shape_2.setTransform(16.2,23.7);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#23A455").s().p("AgBAdIAAgGQgGgBgDgCQgFgEAAgJIAJAAIABAGQABACADABIAAgOIgCgBQgGgBgDgDQgCgDAAgEIABgGIACgEIAGgDIAEgBIAAgEIADAAIAAAEQAGABACACQAFADAAAIIgIAAIgBgEQgCgDgCAAIAAAMIAKAFQAEADAAAFQAAAJgGADQgDACgFABIAAAGgAACAQIAEgCQABgCAAgDQAAAAAAgBQAAAAAAgBQAAAAgBgBQAAAAAAgBIgEgCgAgEgPQgBAAAAABQAAAAgBABQAAAAAAABQAAAAAAABQAAAAAAABQAAAAAAABQABAAAAABQAAAAABABIADABIAAgLQAAAAgBAAQAAAAgBABQAAAAgBAAQAAAAAAABg");
        this.shape_3.setTransform(41.1,9.8);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#23A455").s().p("AgBAdIAAgGQgFgBgEgCQgFgDAAgKIAIAAIACAGQABACADAAIAAgNIgCgBQgGgBgCgCQgDgFAAgEIABgFIADgEIAEgDIAFgBIAAgEIACAAIAAAFQAGAAADACQAFADABAIIgJAAIgBgEQgBgCgEgBIAAANIALAEQAEACAAAGQAAAJgGADQgCABgHACIAAAGgAABAPIAFgBQABgCABgDQAAAAgBgBQAAAAAAgBQAAAAgBgBQAAgBAAAAIgFgCgAgEgPQgBAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQAAABAAABQAAAAABABQAAAAAAABQAAABABAAIADACIAAgMg");
        this.shape_4.setTransform(32.8,24.7);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#23A455").s().p("AgBAgIAAgHQgGAAgEgDQgGgEAAgKIAKAAIABAGQABACAEABIAAgPIgDgBQgFgBgEgDQgDgEABgFIABgFQAAgDACgCIAGgEIAFAAIAAgFIADAAIAAAFQAFAAAEACQAGAEAAAJIgKAAIgBgFQAAgCgEgBIAAAOQAIADADACQAFADAAAGQAAAIgGAFQgFADgFAAIAAAHgAACARIAEgBQACgCAAgEQAAAAAAgBQAAgBAAAAQgBgBAAAAQAAgBgBAAIgEgDgAgFgRQAAABgBAAQAAABAAAAQAAABAAAAQAAABAAAAQAAADACACIADACIAAgNQAAAAgBAAQAAAAgBABQAAAAgBAAQgBABAAAAg");
        this.shape_5.setTransform(18.4,7.3);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#23A455").s().p("AAAAgIAAgHQgHgBgEgCQgGgDAAgLIAJAAIACAGQABACAFABIAAgPIgEgBQgGgBgDgDQgCgEgBgEIABgHQABgCADgCQABgCAEgCIAGgBIAAgEIACAAIAAAEQAFABAEACQAGAFAAAIIgJAAIgBgFQgBgCgEgBIAAANQAHADAEADQAFACAAAHQAAAJgHAEQgEACgFABIAAAHgAACARIAEgBQACgCAAgEQAAgBAAAAQAAgBgBgBQAAAAAAgBQgBAAAAAAIgEgDgAgFgRQAAAAgBABQAAAAAAABQAAAAAAABQgBAAAAABQAAADACACIAFABIAAgLQgBAAgBAAQgBAAAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_6.setTransform(27.2,4.2);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#23A455").s().p("AAAAgIAAgHQgIgBgDgCQgGgDAAgLIAKAAIABAGQACACAEABIAAgPIgDgBQgGgBgEgDQgDgDAAgFQAAgEACgDQABgCACgCQACgCADgCIAGgBIAAgEIACAAIAAAEQAGABADACQAGAEAAAJIgJAAIgBgFQgCgDgDAAIAAANQAHADAEADQAFACAAAHQAAAJgHAEQgEACgFABIAAAHgAACARIAEgBQACgDAAgDQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAgBgBAAIgEgDgAgFgRIgBAEQAAADACACIAEABIAAgLQgEgBgBACg");
        this.shape_7.setTransform(25.9,29.8);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#23A455").s().p("AgBAZIAAgFIgIgDQgEgDAAgIIAHAAIACAFIADADIAAgMIgBgBQgFgBgDgCQgCgDAAgEIABgFIACgDIAFgDIADgBIAAgDIACAAIAAADIAHADQAFADABAGIgIAAIgBgDQAAgBAAAAQgBAAAAgBQgBAAAAAAQgBAAgBgBIAAALIAJAEQAEACAAAFQAAAHgFADIgIADIAAAFgAABANIAEgBQAAAAAAAAQABgBAAAAQAAgBAAAAQABgBAAgBQAAAAgBgBQAAAAAAgBQAAAAgBgBQAAAAAAgBIgEgBgAgEgNIgBADQAAABAAAAQAAABAAAAQABABAAAAQAAAAABABIACABIAAgJIgDABg");
        this.shape_8.setTransform(36.4,14.1);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#23A455").s().p("AAAAZIAAgFQgHgBgBgCQgFgDAAgHIAHAAIABAEQABACAEABIAAgMIgDgBQgFgBgCgCQgCgDAAgEIABgFIADgDIADgDIAFgBIAAgDIACAAIAAADQAFABACACQAEADAAAGIgGAAIgCgDQAAgBAAAAQgBAAAAgBQgBAAAAAAQgBAAAAAAIAAAKQAHADABABQAEACAAAFQAAAHgFAEQgEACgDAAIAAAFgAACAOIADgBQAAgBABAAQAAgBAAAAQAAgBAAAAQABgBAAgBQAAAAgBgBQAAgBAAAAQAAgBAAAAQgBAAAAgBIgDgBgAgEgNQAAAAAAABQAAAAgBAAQAAABAAAAQAAABAAAAQAAABAAAAQAAABABAAQAAABAAAAQABAAAAABIADABIAAgJg");
        this.shape_9.setTransform(46.9,26.4);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#23A455").s().p("AAAASIAAgDQgEAAgCgCQgDgCgBgGIAGAAIABAEIADABIAAgJIgBAAQgFgBgBgBQgBgCgBgEIABgDIABgDIAEgCIADAAIAAgCIABAAIAAACIAGABQADADAAAFIgFAAIgBgDQAAAAAAgBQgBAAAAAAQAAAAgBAAQAAAAgBAAIAAAIIAHACQACACAAAEQAAAFgDACQgCACgEAAIAAADgAABAKIADAAIABgEIgBgDIgDgBgAgCgJIgBACIABADIACABIAAgHg");
        this.shape_10.setTransform(32.8,16.7);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#23A455").s().p("AAAATIAAgEIgGgCQgDgCAAgGIAFAAIABADIADACIAAgJIgBAAQgEgBgCgBQgCgCAAgDIABgEIACgCIADgCIADgBIAAgDIABAAIAAADQAEAAABACQAEACAAAFIgFAAIgBgDQAAAAgBAAQAAgBAAAAQAAAAgBAAQAAAAgBAAIAAAHIAHADQACABAAAEQAAAGgDACIgGACIAAAEgAABAKIACgBQABAAAAAAQAAAAAAgBQABAAAAgBQAAAAAAgBIgBgDIgDgBgAgCgJIgBACIABADIACABIAAgHIgCABg");
        this.shape_11.setTransform(38.6,26.3);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#23A455").s().p("AAAAgIAAgHQgHAAgEgDQgGgEAAgKIAJAAIACAGQACACAEABIAAgOIgDgBQgIgCgCgDQgDgDAAgGIABgGQACgCACgCQACgCADgBIAGgCIAAgEIACAAIAAAEQAHACACACQAGAEAAAHIgJAAIgBgEQgCgCgDgBIAAAOQAJADACACQAFACAAAIQAAAIgHAEQgDACgGABIAAAHgAACARIAEgBQACgBAAgEQAAgEgCgCIgEgBgAgFgRQAAAAgBABQAAAAAAABQAAAAAAABQgBABAAABQAAAAABABQAAABAAAAQAAABABAAQAAABABAAIAEACIAAgNQgBAAgBABQgBAAAAAAQgBAAAAAAQgBABAAAAg");
        this.shape_12.setTransform(42.7,19);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#23A455").s().p("AgBAgIAAgHQgHgBgDgCQgGgDAAgLIAKAAIABAGQABADAEAAIAAgPIgCgBQgHgBgDgDQgDgDAAgGIABgGIADgEIAGgEIAFgBIAAgEIADAAIAAAEQAGABADACQAGAEAAAJIgJAAIgBgFQgCgDgDAAIAAAOIALAFQAFADAAAGQAAAKgHADQgCACgHABIAAAHgAACARIAEgBQACgBAAgFQAAAAAAgBQAAgBAAAAQAAgBgBAAQAAgBgBAAIgEgDgAgFgRIgCAEQAAADADACIADACIAAgNQAAAAgBAAQgBAAAAABQgBAAAAAAQgBAAAAABg");
        this.shape_13.setTransform(42,30.7);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#23A455").s().p("AAAAbIAAgGQgGgBgDgCQgFgDAAgIIAIAAIABAFQABACAEABIAAgNIgDgBIgIgDQgCgCAAgFIABgFIADgEQABgCADAAIAFgBIAAgFIABAAIAAAFQAGABACABQAFADAAAHIgIAAIgBgEQAAAAAAgBQgBAAAAAAQgBgBAAAAQgBAAgBAAIAAALIAKAEQAEACAAAGQAAAHgGADIgIADIAAAGgAABAPIAEgBQACgDAAgDQAAAAAAAAQgBgBAAAAQAAgBAAAAQgBgBAAAAIgEgCgAgEgOIgBAEQAAABAAAAQAAABAAAAQABABAAAAQAAAAABAAIADACIAAgKQgBAAAAAAQgBAAgBAAQAAABAAAAQgBAAAAAAg");
        this.shape_14.setTransform(8.4,12.7);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#23A455").s().p("AAAAaIAAgFIgJgDQgFgDAAgIIAIAAIABAFIAFADIAAgNIgCgBQgGgBgCgCQgCgDgBgEIABgFIADgEIAEgCIAFgBIAAgFIABAAIAAAFQAGAAACACQAFADAAAHIgHAAIgBgEQgBgBAAAAQgBAAAAgBQgBAAAAAAQgBAAgBAAIAAALIAKAEQAEACAAAGQgBAHgEADIgJADIAAAFgAABAPIAEgBQACgCAAgEQAAAAgBgBQAAAAAAgBQAAAAAAgBQgBAAAAAAIgEgCgAgEgNQAAAAAAAAQgBAAAAABQAAAAAAABQAAAAAAABQAAABAAAAQAAABAAAAQABABAAAAQAAAAAAAAIAEACIAAgKQgBAAAAAAQgBAAAAAAQgBABAAAAQgBAAAAABg");
        this.shape_15.setTransform(19.1,13.8);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#23A455").s().p("AAAAaIAAgFQgFgBgEgCQgFgDAAgIIAIAAIABAFQABABAEABIAAgMIgDgBQgFgBgCgCQgDgDAAgEIABgFIADgEQACgCACgBIAFgBIAAgDIABAAIAAADQAFABADACQAFADAAAHIgIAAIgBgEQAAAAAAgBQgBAAAAgBQgBAAAAAAQgBAAgBAAIAAALQAHACADACQAEACAAAGQAAAHgGADQgCACgGABIAAAFgAABAOIAEgBQAAAAABgBQAAAAAAgBQAAAAABgBQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAgBAAAAQgBgBAAAAIgEgCgAgEgOIgBAEQAAAAAAABQAAAAAAABQABAAAAABQAAAAABABIADABIAAgKQgBAAAAAAQgBAAgBAAQAAAAAAABQgBAAAAAAg");
        this.shape_16.setTransform(20.6,25);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#23A455").s().p("AAAAQIAAgEIgFgCQgCgCAAgEIAEAAIAAADIADABIAAgHIgBgBQgCAAgCgBQgBAAAAgBQAAAAgBgBQAAAAAAgBQAAAAAAgBIABgDIABgCIACgBIADgBIAAgDIABAAIAAADQABAAAAAAQABAAAAAAQABABAAAAQABAAAAABQADABAAAEIgFAAIAAgCQAAAAAAgBQAAAAgBAAQAAAAAAAAQgBAAAAAAIAAAGIAFACQADABAAAEQgBAEgDABQAAABgBAAQAAAAgBABQAAAAgBAAQAAAAgBAAIAAAEgAABAIIACAAIABgDIgBgDIgCgBgAgCgIIgBACIABADIACABIAAgGg");
        this.shape_17.setTransform(43.6,25.8);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#23A455").s().p("AAAAPIAAgDIgFgBQgCgCAAgFIAEAAIAAADQABAAAAAAQAAABABAAQAAAAAAAAQABAAAAAAIAAgHIgBAAQgDgBgCgBIgBgEIABgDIABgCIACgCIADAAIAAgCIABAAIAAACIAEABQADADAAADIgFAAIAAgCIgCgBIAAAGIAFACQADACAAACQAAAFgEACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgHIgBACIABACIACABIAAgGg");
        this.shape_18.setTransform(13.1,7);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#23A455").s().p("AAAAPIAAgDQgDAAgCgCQgCgBAAgFIAEAAIABADIACABIAAgHIgBAAIgFgCIAAgEIAAgDIABgCIADgCIACAAIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAAAABABQAAAAAAAAQACACACAEIgFAAIgBgCIgCgCIAAAHIAFACQADABAAADQAAAFgEABQAAABAAAAQgBAAAAAAQgBABgBAAQAAAAgBAAIAAADgAABAIIACgBIABgCIgBgDIgCgBgAgCgIIgBACIABADIACAAIAAgFg");
        this.shape_19.setTransform(6.3,15.7);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#23A455").s().p("AAAAPIAAgDIgEgBQgDgCAAgFIAEAAIABADQAAAAAAAAQAAABABAAQAAAAAAAAQABAAAAAAIAAgHIgBAAQgCgBgCgBIgCgEIABgDIABgCIADgCIACAAIAAgCIABAAIAAACIAEABQADADAAADIgEAAIAAgCIgDgBIAAAGIAFACQACABAAADQAAAFgDACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgIIgBADIABACIACABIAAgGg");
        this.shape_20.setTransform(13.8,11.9);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#23A455").s().p("AAAAPIAAgDQgDAAgBgCQgDgBAAgFIAEAAIABADIACABIAAgHIgBAAQgCgBgCgBIgBgEIAAgDIABgCIADgCIACAAIAAgCIABAAIAAACIAEABQADADAAADIgEAAIAAgCIgDgBIAAAGIAFACQACABAAADQAAAFgDACIgEABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgIIgBACIABADIACABIAAgGg");
        this.shape_21.setTransform(10.2,25.1);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#23A455").s().p("AgBAoIAAgJQgJgCgDgCQgIgFAAgMIAMAAIACAHQABAEAFAAIAAgSIgDgBQgJgCgDgEQgDgEAAgGIABgIQABgCADgEQADgDAEAAIAGgCIAAgGIADAAIAAAHQAHgBAEAEQAIAEAAALIgLAAIgCgGQgBgDgFAAIAAAQQALAEADACQAGAEAAAJQAAALgIAEQgFADgHABIAAAJgAACAVIAFgBQADgCAAgFQAAgEgDgDIgFgCgAgGgVQgCACAAADQAAAEADACIAEACIAAgPQgEAAgBACg");
        this.shape_22.setTransform(5.9,25.3);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#23A455").s().p("AAAARIAAgEQgDAAgCgCQgDgCAAgFIAFAAIABADQAAAAAAABQAAAAABAAQAAABAAAAQABAAAAAAIAAgIIgBgBIgFgBIgBgFIAAgDIACgCIADgBIACgBIAAgDIABAAIAAADQABAAABAAQAAAAABAAQAAABABAAQAAAAABABQADABAAAEIgFAAIAAgCIgDgBIAAAHIAGACQACABAAAEQAAAEgDACIgFABIAAAFgAABAIIACAAIABgDIgBgDIgCgBgAgCgJIgBADIABADIACAAIAAgGg");
        this.shape_23.setTransform(23.7,4.2);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#23A455").s().p("AAAAQIAAgDQgEgBgBgBQgDgBAAgGIAFAAIAAADQABAAAAABQAAAAABAAQAAAAABAAQAAAAAAAAIAAgHIgBgBQgEAAgBgCQAAAAgBAAQAAgBAAAAQAAgBAAAAQgBgBAAAAIABgEIACgCIACgCIADAAIAAgCIABAAIAAACIAFABQACACABAFIgFAAIAAgDIgDgCIAAAHIAGADQACABAAAEQAAAEgDACQgCABgDABIAAADgAABAIIACAAQAAAAAAAAQABgBAAAAQAAAAAAgBQAAAAAAgBIgBgCIgCgBgAgCgJIgBADQAAAAAAABQAAAAAAAAQABAAAAABQAAAAAAAAIACABIAAgGg");
        this.shape_24.setTransform(32.1,5.4);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#23A455").s().p("AAAARIAAgEIgFgBQgDgCAAgGIAFAAIAAAEIADABIAAgHIgBgBIgFgCQgBAAAAgBQAAAAAAgBQgBAAAAgBQAAgBAAgBIABgCIABgDIADgCIADAAIAAgCIABAAIAAACQABAAAAAAQABAAAAAAQABABAAAAQABAAAAAAQAEADAAAEIgFAAIgBgCQAAgBAAAAQAAAAgBgBQAAAAAAAAQgBAAAAAAIAAAHIAGACQACACAAADQAAAEgDADQgCABgDAAIAAAEgAABAJIACAAIABgEQAAAAAAAAQAAgBAAAAQAAAAgBAAQAAgBAAAAIgCgBgAgCgIIgBACIABACIACABIAAgGg");
        this.shape_25.setTransform(14.8,16.5);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#23A455").s().p("AAAARIAAgEIgFgCQgDgBAAgGIAFAAIABAEIACABIAAgIIgBgBQgDAAgCgBQAAgBgBAAQAAgBAAAAQAAgBAAAAQgBgBAAgBIABgCIABgDIAEgCIACAAIAAgDIABAAIAAADQABAAAAAAQABAAABAAQAAABABAAQAAAAABABQACABAAAEIgEAAIAAgBQgBgBAAAAQAAAAAAgBQgBAAAAAAQAAAAgBAAIAAAHIAGACQACABAAAEQAAAEgDACIgFACIAAAEgAABAJIACgBIABgCIgBgDIgCgBgAgCgIIgBACIABACIACACIAAgHg");
        this.shape_26.setTransform(25.2,16.7);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#23A455").s().p("AAAAQIAAgDIgFgBQgDgDAAgFIAFAAIABADIACACIAAgIIgBAAQgEgBgBgBIgBgEIAAgDIACgDIADgCIACAAIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAABABAAQAAAAABAAQADACAAAFIgFAAIAAgCQgBgBAAAAQAAAAAAgBQgBAAAAAAQAAAAgBAAIAAAHIAGACQACABAAADQAAAFgDADIgFABIAAADgAABAJIACgBIABgDIgBgCIgCgBgAgCgIIgBACIABACIACABIAAgGIgCABg");
        this.shape_27.setTransform(21.2,17.3);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#23A455").s().p("AAAAQIAAgDIgFgCQgDgBAAgGIAFAAIABADIACACIAAgIIgBgBQgDAAgCgBQAAAAgBgBQAAgBAAAAQAAgBAAAAQgBgBAAAAIABgEIABgCIAEgBIACgBIAAgCIABAAIAAACQABAAAAAAQABAAABAAQAAABABAAQAAAAABAAQACACAAAFIgEAAIAAgDIgDgBIAAAGIAGADQACABAAAEQAAAFgDACIgFABIAAADgAABAIIACAAIABgDIgBgCIgCgBgAgCgJIgBADIABADIACAAIAAgGg");
        this.shape_28.setTransform(27.9,23.8);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#23A455").s().p("AAAAYIAAgFIgIgDQgEgCAAgIIAHAAIABAFIAEACIAAgLIgCgBQgFgBgDgCQgBgDAAgDIABgFIABgDIAFgDIAEAAIAAgEIABAAIAAAEQAEAAADACQAFADAAAGIgHAAIgBgEIgEgCIAAAKIAIAEQAEABAAAGQAAAGgFADIgHADIAAAFgAABANIAEgBQAAgBAAAAQAAAAABgBQAAAAAAgBQAAgBAAAAQAAgBAAAAQAAgBAAAAQgBgBAAAAQAAAAAAgBIgEgCgAgDgMIgBADQAAAAAAABQAAAAAAABQAAAAAAAAQABABAAAAIADACIAAgKQgBAAAAAAQgBAAAAABQgBAAAAAAQAAAAAAABg");
        this.shape_29.setTransform(15.3,32.5);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#23A455").s().p("AgQATIAAgEIAbAAQADAAAAgEIAAgdIAEAAIAAAdQgBAIgGAAg");
        this.shape_30.setTransform(13.1,7.5);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#23A455").s().p("AgRATIAAgEIAbAAQAFAAAAgEIAAgdIACAAIAAAdQABAIgHAAg");
        this.shape_31.setTransform(25.2,17.4);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#23A455").s().p("AgZAcIAAgGIAoAAQAGAAAAgGIAAgqIAFAAIAAAqQAAAMgJAAg");
        this.shape_32.setTransform(32.8,17.3);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#23A455").s().p("AgpAtIAAgJIBCAAQAJAAAAgJIAAhHIAIAAIAABHQAAASgPAAg");
        this.shape_33.setTransform(41.6,31.5);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#23A455").s().p("AgCAOQgIAAAAgIIAAgTIADAAIAAAOQAAAHAGAAIAMAAIAAAGg");
        this.shape_34.setTransform(22.3,20);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#23A455").s().p("AgCAOQgIAAAAgIIAAgTIADAAIAAAOQAAAHAGAAIAMAAIAAAGg");
        this.shape_35.setTransform(28.8,26.3);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#23A455").s().p("AgSATQgIAAgDgCQgFgDAAgKIAAgWIAGAAIAAATQAAAFADACIAHABIA1AAIAAAKg");
        this.shape_36.setTransform(26.9,7.3);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#23A455").s().p("AgSATQgIAAgEgCQgEgEAAgJIAAgXIAGAAIAAAUQAAAFACACIAHABIA2AAIAAAKg");
        this.shape_37.setTransform(7.8,16.1);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#23A455").s().p("AgSAUQgIAAgDgDQgFgDAAgKIAAgXIAGAAIAAAUQAAAIAJAAIA2AAIAAALg");
        this.shape_38.setTransform(15.3,35.2);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#23A455").s().p("AgUBYIAAgJIAbAAQAHAAAAgJIAAidIAHAAIAAChQAAAOgNAAg");
        this.shape_39.setTransform(21.6,22.2);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#23A455").s().p("AgChYIAFAAIAACwIgFACg");
        this.shape_40.setTransform(11.3,13.4);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#23A455").s().p("AgNCCQgQgBAAgTIAAjuIAHAAIAADrQAAAOAMAAIAoAAIAAAJg");
        this.shape_41.setTransform(32.8,18.3);

        this.shape_42 = new cjs.Shape();
        this.shape_42.graphics.f("#23A455").s().p("Ag1AuIAAgIIALAAQAGAAAAgGIAAgpQAAgIAIAAIAOAAQAEAAAAgDIAAgSQAAgHAHAAIA5AAIAAACIg4AAQgFABABAEIAAASQAAAIgIAAIgNAAQgEAAAAAHIAAAnQAAAMgKAAg");
        this.shape_42.setTransform(16.6,15.6);

        this.shape_43 = new cjs.Shape();
        this.shape_43.graphics.f("#23A455").s().p("Ag1AuIAAgIIAKAAQAHAAAAgGIAAgoQgBgJAKAAIAMAAQAFAAAAgEIAAgSQAAgGAGAAIA6AAIAAACIg4AAQgEAAgBAGIAAARQABAIgIAAIgNAAQgEAAAAAHIAAAnQAAAMgKAAg");
        this.shape_43.setTransform(35.8,9.2);

        this.shape_44 = new cjs.Shape();
        this.shape_44.graphics.f("#23A455").s().p("AA8BhQgUAAAAgZIAAhUIgBgKQgCgFgFAAIgdAAQgOAAgBgRIAAgkQABgGgCgDQgCgCgGAAIhAAAIAAgFIBDAAQAOAAAAANIAAAmQABAHAHAAIAcAAQASAAAAATIAABWQABANANAAIAWAAIAAARg");
        this.shape_44.setTransform(14.7,31.7);

        this.shape_45 = new cjs.Shape();
        this.shape_45.graphics.f("#23A455").s().p("AhLC3QgIgCgBgCIAAkNQAAgHAFgEQAEgFAGAAIBqAAQAIAAAAgIIAAg6QAAgNALAAIAcAAIAAAHIgYAAQgGAAAAAIIAABOQAAAPgQAAIg0AAQgMAAAAAQIAADwQAAACgJACQgIACgMABQgMgBgIgCg");
        this.shape_45.setTransform(31.1,45.9);

        this.shape_46 = new cjs.Shape();
        this.shape_46.graphics.f("#2B7848").s().p("AizCbQhMg8AAhVIAAgTQAAhVBMg8QBKg8BpAAQBpAABMA8QBKA8AABVIAAATQAABVhKA8QhMA8hpAAQhpAAhKg8g");
        this.shape_46.setTransform(25.6,21.5);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_46},{t:this.shape_45},{t:this.shape_44},{t:this.shape_43},{t:this.shape_42},{t:this.shape_41},{t:this.shape_40},{t:this.shape_39},{t:this.shape_38},{t:this.shape_37},{t:this.shape_36},{t:this.shape_35},{t:this.shape_34},{t:this.shape_33},{t:this.shape_32},{t:this.shape_31},{t:this.shape_30},{t:this.shape_29},{t:this.shape_28},{t:this.shape_27},{t:this.shape_26},{t:this.shape_25},{t:this.shape_24},{t:this.shape_23},{t:this.shape_22},{t:this.shape_21},{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.tree1, new cjs.Rectangle(0,0,51.1,64.5), null);


    (lib.LINEDASH2 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AANAXQgBAAgBAAQAAAAgBAAQAAAAgBgBQAAAAAAgBQgMgYgMgLQgDgEADgDQABAAAAAAQAAgBABAAQAAAAABAAQAAgBAAAAIADACQAKAKAQAbQABAEgDADIgBAAg");
        this.shape.setTransform(1.7,2.4);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.LINEDASH2, new cjs.Rectangle(0,0,3.5,4.7), null);


    (lib.dashlast = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AANATQgSgVgMgJQgFgCADgEQADgEAEADQAMAIAUAXQACAEgDADIgDABQAAAAgBgBQAAAAgBAAQAAAAAAAAQgBgBAAAAg");
        this.shape.setTransform(2.1,2);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.dashlast, new cjs.Rectangle(0,0,4.2,4.1), null);


    (lib.d2 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AANATQgPgRgRgMQgDgDADgEQADgDADADQAPAMASARQADADgDAEIgEABg");
        this.shape.setTransform(2.2,2);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.d2, new cjs.Rectangle(0,0,4.3,4), null);


    (lib.d1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AAHAKIgSgKQgDgCABgFQACgEAEADQAPAIAEACQAEACgDAEQAAABAAAAQgBABAAAAQAAAAgBAAQgBAAAAAAg");
        this.shape.setTransform(1.4,1);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.d1, new cjs.Rectangle(0,0,2.9,2.1), null);


    (lib.clouds1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AleFZIAAgBQhogBhJhKQhJhKAAhoQAAhoBKhLQBLhKBpAAQAbAAAcAHQAWhTBDg0QBFg2BXAAQBVAABEA0QBDAzAXBQQBIAIA5AsQA4AqAaBCIATgBQBIAAAzAyQAzAyAABJQAABIgzAzQgzAzhIAAg");
        this.shape.setTransform(1205.5,3.1);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AlIEKQg4AAgngoQgngnAAg3QAAg4AngnQAngmA4AAIAPAAQAUgyArghQAtgiA2gGQATg+AzgnQA0goBBAAQBEAAA1ApQAzApARA/QAVgEAWgBQBRABA5A5QA6A5AABQQAABQg5A5Qg4A5hQACg");
        this.shape_1.setTransform(1010.2,39.2);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AleFZIAAgBQhogBhJhKQhJhKAAhoQAAhoBKhLQBLhKBpAAQAbAAAcAHQAWhTBDg0QBFg2BXAAQBVAABEA0QBDAzAXBQQBIAIA5AsQA4AqAaBCIATgBQBIAAAzAyQAzAyAABJQAABIgzAzQgzAzhIAAg");
        this.shape_2.setTransform(1205.5,3.1);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AlIEKQg4AAgngoQgngnAAg3QAAg4AngnQAngmA4AAIAPAAQAUgyArghQAtgiA2gGQATg+AzgnQA0goBBAAQBEAAA1ApQAzApARA/QAVgEAWgBQBRABA5A5QA6A5AABQQAABQg5A5Qg4A5hQACg");
        this.shape_3.setTransform(1010.2,39.2);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AlIEKQg4AAgngoQgngnAAg3QAAg4AngnQAngmA4AAIAPAAQAUgyArghQAtgiA2gGQATg+AzgnQA0goBBAAQBEAAA1ApQAzApARA/QAVgEAWgBQBRABA5A5QA6A5AABQQAABQg5A5Qg4A5hQACg");
        this.shape_4.setTransform(774.3,-15.8,1,1,0,0,180);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AleFZIAAgBQhogBhJhKQhJhKAAhoQAAhoBKhLQBLhKBpAAQAbAAAcAHQAWhTBDg0QBFg2BXAAQBVAABEA0QBDAzAXBQQBIAIA5AsQA4AqAaBCIATgBQBIAAAzAyQAzAyAABJQAABIgzAzQgzAzhIAAg");
        this.shape_5.setTransform(542.9,46.1,1,1,0,0,180);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AleFZIAAgBQhogBhJhKQhJhKAAhoQAAhoBKhLQBLhKBpAAQAbAAAcAHQAWhTBDg0QBFg2BXAAQBVAABEA0QBDAzAXBQQBIAIA5AsQA4AqAaBCIATgBQBIAAAzAyQAzAyAABJQAABIgzAzQgzAzhIAAg");
        this.shape_6.setTransform(249.6,3.1);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AlIEKQg4AAgngoQgngnAAg3QAAg4AngnQAngmA4AAIAPAAQAUgyArghQAtgiA2gGQATg+AzgnQA0goBBAAQBEAAA1ApQAzApARA/QAVgEAWgBQBRABA5A5QA6A5AABQQAABQg5A5Qg4A5hQACg");
        this.shape_7.setTransform(54.2,39.2);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#FFFFFF").s().p("AleFZIAAgBQhogBhJhKQhJhKAAhoQAAhoBKhLQBLhKBpAAQAbAAAcAHQAWhTBDg0QBFg2BXAAQBVAABEA0QBDAzAXBQQBIAIA5AsQA4AqAaBCIATgBQBIAAAzAyQAzAyAABJQAABIgzAzQgzAzhIAAg");
        this.shape_8.setTransform(249.6,3.1);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#FFFFFF").s().p("AlIEKQg4AAgngoQgngnAAg3QAAg4AngnQAngmA4AAIAPAAQAUgyArghQAtgiA2gGQATg+AzgnQA0goBBAAQBEAAA1ApQAzApARA/QAVgEAWgBQBRABA5A5QA6A5AABQQAABQg5A5Qg4A5hQACg");
        this.shape_9.setTransform(54.2,39.2);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.clouds1, new cjs.Rectangle(7.9,-42.4,1257.8,123), null);


    (lib.warter = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_66
        this.instance = new lib.dashlast();
        this.instance.parent = this;
        this.instance.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(37).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(126));

        // Layer_68
        this.instance_1 = new lib.dashlast();
        this.instance_1.parent = this;
        this.instance_1.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_1._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(31).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(132));

        // Layer_70
        this.instance_2 = new lib.dashlast();
        this.instance_2.parent = this;
        this.instance_2.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_2._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(26).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(137));

        // Layer_72
        this.instance_3 = new lib.dashlast();
        this.instance_3.parent = this;
        this.instance_3.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_3._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(21).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(142));

        // Layer_64
        this.instance_4 = new lib.dashlast();
        this.instance_4.parent = this;
        this.instance_4.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_4._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(16).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(147));

        // Layer_62
        this.instance_5 = new lib.dashlast();
        this.instance_5.parent = this;
        this.instance_5.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_5._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(10).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(153));

        // Layer_60
        this.instance_6 = new lib.dashlast();
        this.instance_6.parent = this;
        this.instance_6.setTransform(-0.6,0.2,1,1,0,0,0,2,2);
        this.instance_6._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(5).to({_off:false},0).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(158));

        // Layer_3
        this.instance_7 = new lib.dashlast();
        this.instance_7.parent = this;
        this.instance_7.setTransform(-0.6,0.2,1,1,0,0,0,2,2);

        this.timeline.addTween(cjs.Tween.get(this.instance_7).to({regX:2.1,rotation:41.4,guide:{path:[-0.5,0.4,4.3,4.4,9.2,10.8,19.3,24.1,20.6,38.4]}},15).to({_off:true},1).wait(163));

        // Layer_50
        this.instance_8 = new lib.LINEDASH2();
        this.instance_8.parent = this;
        this.instance_8.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_8._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(44).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(123));

        // Layer_52
        this.instance_9 = new lib.LINEDASH2();
        this.instance_9.parent = this;
        this.instance_9.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_9._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(40).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(127));

        // Layer_54
        this.instance_10 = new lib.LINEDASH2();
        this.instance_10.parent = this;
        this.instance_10.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_10._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(36).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(131));

        // Layer_56
        this.instance_11 = new lib.LINEDASH2();
        this.instance_11.parent = this;
        this.instance_11.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_11._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(32).to({_off:false},0).to({regX:1.8,regY:2.5,scaleX:0.44,scaleY:0.49,rotation:14.6,guide:{path:[-1.1,2.4,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.5,3.8,10,4.6,11.8,5.1,12.9,5.6,14.2,6.1,15.5,6.6,16.8,7.2,18.5,7.8,20.2,7.9,20.5,8,20.9]}},6).to({regY:2.4,scaleX:1,scaleY:1,rotation:27,guide:{path:[7.9,20.9,8.7,23.2,9.2,25.4,9.7,27.2,10,29,10.5,31.7,10.8,34.3,10.9,36,10.9,37.7]}},5).to({_off:true},1).wait(135));

        // Layer_42
        this.instance_12 = new lib.LINEDASH2();
        this.instance_12.parent = this;
        this.instance_12.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_12._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(28).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(139));

        // Layer_44
        this.instance_13 = new lib.LINEDASH2();
        this.instance_13.parent = this;
        this.instance_13.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_13._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_13).wait(24).to({_off:false},0).to({regX:1.8,regY:2.5,scaleX:0.61,scaleY:0.63,rotation:12.3,guide:{path:[-1.1,2.4,-0.4,2.5,0.5,3.8,1.7,5.4,3.1,8.5,3.8,9.9,4.6,11.7,5,12.9,5.6,14.2,6.1,15.5,6.5,16.7,6.7,17.1,6.8,17.5]}},5).to({regY:2.4,scaleX:1,scaleY:1,rotation:27,guide:{path:[6.8,17.7,7.3,19,7.7,20.2,8.6,22.9,9.2,25.4,9.7,27.2,10,29,10.5,31.7,10.8,34.3,10.9,36,10.9,37.7]}},6).to({_off:true},1).wait(143));

        // Layer_46
        this.instance_14 = new lib.LINEDASH2();
        this.instance_14.parent = this;
        this.instance_14.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_14._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_14).wait(20).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(147));

        // Layer_48
        this.instance_15 = new lib.LINEDASH2();
        this.instance_15.parent = this;
        this.instance_15.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_15._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_15).wait(15).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(152));

        // Layer_40
        this.instance_16 = new lib.LINEDASH2();
        this.instance_16.parent = this;
        this.instance_16.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_16._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_16).wait(11).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(156));

        // Layer_38
        this.instance_17 = new lib.LINEDASH2();
        this.instance_17.parent = this;
        this.instance_17.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_17._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_17).wait(7).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(160));

        // Layer_36
        this.instance_18 = new lib.LINEDASH2();
        this.instance_18.parent = this;
        this.instance_18.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);
        this.instance_18._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_18).wait(3).to({_off:false},0).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(164));

        // Layer_2
        this.instance_19 = new lib.LINEDASH2();
        this.instance_19.parent = this;
        this.instance_19.setTransform(-1.2,2.3,1,1,0,0,0,1.7,2.4);

        this.timeline.addTween(cjs.Tween.get(this.instance_19).to({regX:1.8,rotation:27,guide:{path:[-1.1,2.5,-0.4,2.6,0.6,3.9,1.7,5.4,3.2,8.6,3.9,10.1,4.7,11.9,5.1,13.1,5.6,14.3,6.1,15.6,6.6,16.9,7.2,18.6,7.8,20.2,8.6,22.9,9.3,25.4,9.7,27.2,10,29,10.6,31.7,10.8,34.3,11,36,11,37.7]}},11).to({_off:true},1).wait(167));

        // Layer_25
        this.instance_20 = new lib.d1();
        this.instance_20.parent = this;
        this.instance_20.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_20._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_20).wait(44).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(119));

        // Layer_27
        this.instance_21 = new lib.d1();
        this.instance_21.parent = this;
        this.instance_21.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_21._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_21).wait(40).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(123));

        // Layer_29
        this.instance_22 = new lib.d2();
        this.instance_22.parent = this;
        this.instance_22.setTransform(0.2,-1.3,1,1,-7,0,0,2.2,1.9);
        this.instance_22._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_22).wait(38).to({_off:false},0).to({regX:2.4,regY:2,scaleX:0.49,scaleY:0.51,rotation:9.8,guide:{path:[0.2,-1.2,3.6,0.8,7.1,3.7,10.1,6,13.2,9.2,14.6,10.6,15.9,12]}},6).to({regX:2.2,scaleX:1,scaleY:1,rotation:35.2,guide:{path:[15.9,12.1,26.8,24.3,29.7,38.7]}},9).to({_off:true},1).wait(125));

        // Layer_31
        this.instance_23 = new lib.d1();
        this.instance_23.parent = this;
        this.instance_23.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_23._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_23).wait(35).to({_off:false},0).to({regX:1.5,scaleX:1,scaleY:1,rotation:18.8,guide:{path:[0,-1.2,3.5,0.8,7.1,3.7,10,6.2,13.2,9.3,15.6,11.7,17.7,14.3]}},7).to({scaleX:1,scaleY:1,rotation:40.2,guide:{path:[17.8,14.4,27,25.6,29.7,38.6]}},8).to({_off:true},1).wait(128));

        // Layer_33
        this.instance_24 = new lib.d1();
        this.instance_24.parent = this;
        this.instance_24.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_24._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_24).wait(32).to({_off:false},0).to({scaleX:1,scaleY:1,rotation:21.3,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.1,6.1,13.2,9.2,16.9,13,19.9,17]}},8).to({regX:1.5,scaleX:1,scaleY:1,rotation:40.2,guide:{path:[19.9,17.1,27.3,27.1,29.7,38.6]}},7).to({_off:true},1).wait(131));

        // Layer_15
        this.instance_25 = new lib.d1();
        this.instance_25.parent = this;
        this.instance_25.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_25._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_25).wait(28).to({_off:false},0).to({scaleX:1,scaleY:1,rotation:16,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10,6.1,13.2,9.2,14.4,10.5,15.5,11.7]}},6).to({regX:1.5,scaleX:1,scaleY:1,rotation:40.2,guide:{path:[15.6,11.9,26.7,24.1,29.7,38.6]}},9).to({_off:true},1).wait(135));

        // Layer_17
        this.instance_26 = new lib.d1();
        this.instance_26.parent = this;
        this.instance_26.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_26._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_26).wait(24).to({_off:false},0).to({regX:1.5,scaleX:1,scaleY:1,rotation:18.8,guide:{path:[0,-1.2,3.5,0.8,7.1,3.7,10,6.2,13.2,9.3,15.6,11.7,17.7,14.3]}},7).to({scaleX:1,scaleY:1,rotation:40.2,guide:{path:[17.8,14.4,27,25.6,29.7,38.6]}},8).to({_off:true},1).wait(139));

        // Layer_19
        this.instance_27 = new lib.d2();
        this.instance_27.parent = this;
        this.instance_27.setTransform(0.2,-1.3,1,1,-7,0,0,2.2,1.9);
        this.instance_27._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_27).wait(22).to({_off:false},0).to({regY:2,rotation:35.2,guide:{path:[0.2,-1.1,3.6,0.8,7.1,3.7,10.2,6.2,13.3,9.4,26.4,22.6,29.7,38.8]}},15).to({_off:true},1).wait(141));

        // Layer_21
        this.instance_28 = new lib.d1();
        this.instance_28.parent = this;
        this.instance_28.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_28._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_28).wait(19).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(144));

        // Layer_23
        this.instance_29 = new lib.d1();
        this.instance_29.parent = this;
        this.instance_29.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_29._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_29).wait(16).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(147));

        // Layer_13
        this.instance_30 = new lib.d1();
        this.instance_30.parent = this;
        this.instance_30.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_30._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_30).wait(12).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(151));

        // Layer_11
        this.instance_31 = new lib.d1();
        this.instance_31.parent = this;
        this.instance_31.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_31._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_31).wait(8).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(155));

        // Layer_9
        this.instance_32 = new lib.d2();
        this.instance_32.parent = this;
        this.instance_32.setTransform(0.2,-1.3,1,1,-7,0,0,2.2,1.9);
        this.instance_32._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_32).wait(6).to({_off:false},0).to({regY:2,rotation:35.2,guide:{path:[0.2,-1.1,3.6,0.8,7.1,3.7,10.2,6.2,13.3,9.4,26.4,22.6,29.7,38.8]}},15).to({_off:true},1).wait(157));

        // Layer_7
        this.instance_33 = new lib.d1();
        this.instance_33.parent = this;
        this.instance_33.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);
        this.instance_33._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_33).wait(3).to({_off:false},0).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(160));

        // Layer_1
        this.instance_34 = new lib.d1();
        this.instance_34.parent = this;
        this.instance_34.setTransform(-0.1,-1.3,1,1,0,0,0,1.4,1);

        this.timeline.addTween(cjs.Tween.get(this.instance_34).to({regX:1.5,rotation:40.2,guide:{path:[0,-1.3,3.5,0.7,7.1,3.7,10.2,6.1,13.3,9.3,26.4,22.5,29.7,38.6]}},15).to({_off:true},1).wait(163));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-2.9,-2.3,32.3,41.4);


    (lib.subclouds = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{restart:1});

        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_854 = function() {
            this.gotoAndPlay("restart");
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(854).call(this.frame_854).wait(1));

        // Layer_1
        this.clouds = new lib.clouds1();
        this.clouds.name = "clouds";
        this.clouds.parent = this;
        this.clouds.setTransform(174.9,47.3,1,1,0,0,0,174.9,22.3);

        this.timeline.addTween(cjs.Tween.get(this.clouds).to({x:-781.1},854).wait(1));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(7.9,-17.4,1257.8,123);


    (lib.grow = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{"restart":1});

        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_109 = function() {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(109).call(this.frame_109).wait(1));

        // tree2
        this.instance = new lib.tree2();
        this.instance.parent = this;
        this.instance.setTransform(272.2,63.2,0.05,0.05,0,0,0,26,32);

        this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:25.6,regY:32.2,scaleX:1,scaleY:1,y:32.2},109).wait(1));

        // tree1
        this.instance_1 = new lib.tree1();
        this.instance_1.parent = this;
        this.instance_1.setTransform(25.6,63.2,0.05,0.05,0,0,0,26,32);

        this.timeline.addTween(cjs.Tween.get(this.instance_1).to({regX:25.6,regY:32.2,scaleX:1,scaleY:1,y:32.2},109).wait(1));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(24.3,61.6,249.2,3.2);


    (lib.waterloop = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_26
        this.instance = new lib.warter("synched",0);
        this.instance.parent = this;
        this.instance.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(359).to({_off:false},0).wait(48));

        // Layer_25
        this.instance_1 = new lib.warter("synched",0);
        this.instance_1.parent = this;
        this.instance_1.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_1._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(330).to({_off:false},0).wait(77));

        // Layer_24
        this.instance_2 = new lib.warter("synched",0);
        this.instance_2.parent = this;
        this.instance_2.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_2._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(300).to({_off:false},0).wait(107));

        // Layer_23
        this.instance_3 = new lib.warter("synched",0);
        this.instance_3.parent = this;
        this.instance_3.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_3._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(269).to({_off:false},0).wait(138));

        // Layer_22
        this.instance_4 = new lib.warter("synched",0);
        this.instance_4.parent = this;
        this.instance_4.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_4._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(239).to({_off:false},0).wait(168));

        // Layer_21
        this.instance_5 = new lib.warter("synched",0);
        this.instance_5.parent = this;
        this.instance_5.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_5._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(210).to({_off:false},0).wait(197));

        // Layer_20
        this.instance_6 = new lib.warter("synched",0);
        this.instance_6.parent = this;
        this.instance_6.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_6._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(180).to({_off:false},0).wait(227));

        // Layer_12
        this.instance_7 = new lib.warter("synched",0);
        this.instance_7.parent = this;
        this.instance_7.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_7._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(149).to({_off:false},0).wait(258));

        // Layer_11
        this.instance_8 = new lib.warter("synched",0);
        this.instance_8.parent = this;
        this.instance_8.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_8._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(120).to({_off:false},0).wait(287));

        // Layer_10
        this.instance_9 = new lib.warter("synched",0);
        this.instance_9.parent = this;
        this.instance_9.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_9._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(89).to({_off:false},0).wait(318));

        // Layer_6
        this.instance_10 = new lib.warter("synched",0);
        this.instance_10.parent = this;
        this.instance_10.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_10._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(59).to({_off:false},0).wait(348));

        // Layer_2
        this.instance_11 = new lib.warter("synched",0);
        this.instance_11.parent = this;
        this.instance_11.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);
        this.instance_11._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(29).to({_off:false},0).wait(378));

        // Layer_1
        this.instance_12 = new lib.warter("synched",0);
        this.instance_12.parent = this;
        this.instance_12.setTransform(12.8,17.4,1,1,0,0,0,14.7,19.5);

        this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(407));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-4.8,-4.5,4.4,6.9);


    (lib.main = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{"restart":1});

        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_1 = function() {
            this.clouds.play();
            this.trees.gotoAndPlay("restart");
        }
        this.frame_299 = function() {
            this.gotoAndPlay("restart");
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1).call(this.frame_1).wait(298).call(this.frame_299).wait(1));

        // clouds
        this.clouds = new lib.subclouds();
        this.clouds.name = "clouds";
        this.clouds.parent = this;
        this.clouds.setTransform(475,-240.2,1,1,0,0,0,620.9,34.8);

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#998F71").s().p("AjgARQhegHgBgKQABgJBegHQBdgHCDAAQCEAABeAHQBdAHAAAJQAAAKhdAHQheAHiEAAQiDAAhdgHg");
        this.shape.setTransform(90.6,-1.1);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.clouds}]}).wait(300));

        // Layer_2
        this.instance = new lib.waterloop("synched",29);
        this.instance.parent = this;
        this.instance.setTransform(-85.9,-17.8,1.215,1.215,0,0,180,7,16);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(300));

        // Layer_3
        this.trees = new lib.grow();
        this.trees.name = "trees";
        this.trees.parent = this;
        this.trees.setTransform(14.5,-32.3,1,1,0,0,0,148.8,32.2);

        this.timeline.addTween(cjs.Tween.get(this.trees).wait(300));

        // Layer_12
        this.instance_1 = new lib.waterloop("synched",29);
        this.instance_1.parent = this;
        this.instance_1.setTransform(117.1,-17.6,1,1,0,0,0,6.9,16.1);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#284B63").s().p("ABsB9QgSgMgZgbIgtgvQAGgHAAgIQAAgJgFgHQgGgIgLgBQgKgCgIAFIgEAEQgTgLgTAAQgIAhguAZQgwAZgigRQAJgCACgLIACgbQADgxAqgyQArgyAzgHQAtgGAqAcQAnAaAWAtQAXAugFAtQgHA2AcALQAYAJAWgSQgXAggbAAQgRAAgSgMg");
        this.shape_1.setTransform(-44.3,-120);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("Agvg+IAOgNIApgTIAoC9g");
        this.shape_2.setTransform(-33.5,-94.3);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AgJAAIAAgQIARAHIACAag");
        this.shape_3.setTransform(-37.8,-100.9);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("AgYAJIAfgnIASAOIgbAvg");
        this.shape_4.setTransform(-34.4,-102.8);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#38403B").s().p("AgMAPIABgEQALAAAGgKQAEgGgFgGIAEgDQAGAIgFAKQgDAFgDACQgFAEgIAAIgDAAg");
        this.shape_5.setTransform(-44.6,-107.1);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#38403B").s().p("AgXAJIAFgDQAGALAMgBQAMgBAFgJQAGgJgKgPIAEgCQALAQgGAMQgDAFgFADQgFAEgJABIgBAAQgOAAgIgMg");
        this.shape_6.setTransform(-51.6,-113.1);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#EB6B7E").s().p("ABYCIQgFgSgHgHQgLgLgsADQhBAGgogbIgVAVQAHgOgNgdIgPgaQgagrAUgqQASglA5grQAMgIAXAAQAZgBAYALQA/AdAFBQQgEAgAEAQQAEAUAXAfIAJAGIAKA3g");
        this.shape_7.setTransform(-46.8,-114.3);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#EB6B7E").s().p("AgEATQgDgQgCgFQgVAQgEgGQgCgEAIgKIAMgQIAXgFQAPAHAJATQAKATgLAEQgKAEgHACIgFAAQgJAAgDgJg");
        this.shape_8.setTransform(-10,-68.9);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#284B63").s().p("AgtFAQAMjJgOiEQgRiog8hlIA+gkQB2B+AnCCQAnCGgND4g");
        this.shape_9.setTransform(-25.9,-72.4);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#38403B").s().p("AgeAaIAfgvIgBgDIAfAAIgHAyg");
        this.shape_10.setTransform(-31.1,-4);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#EB6B7E").s().p("Ag9EqIAggwIAfnrIAig4IAaA7IhEIYg");
        this.shape_11.setTransform(-28,-31.2);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#38403B").s().p("AgbAaIAegvIAAgDIAZAAIgBAyg");
        this.shape_12.setTransform(-19,-4);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#EB6B7E").s().p("AgfEqIAfgwIgbnrIAhg4IAaA7IgJIYg");
        this.shape_13.setTransform(-18.6,-31.2);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#284B63").s().p("AAdgGQgvhShKgRIgXg8QBYASA+BXQA+BWATCGIgcAGQgRhmgqhGg");
        this.shape_14.setTransform(-20.6,-87.7);

        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f("#EB6B7E").s().p("AgGAMQgLgIgCgJQAAgIAFgEQAFgDAJAFQAUAMAAAHQAAAFgEAFQgEADgEABIgBABQgFAAgIgHg");
        this.shape_15.setTransform(-48.6,-68.4);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f("#284B63").s().p("Ag9CcQAqgrAXhZQAXhRABhdIASgWIAQAtQAEBIggBaQggBWgtA0g");
        this.shape_16.setTransform(-41,-87.2);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f("#284B63").s().p("AgBAAQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQgBAAAAgBg");
        this.shape_17.setTransform(-72.3,-41.7);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f("#284B63").s().p("AAAAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQAAAAAAABQAAAAAAAAQAAAAAAABQAAABAAAAQAAAAAAABQgBAAAAAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQAAgBAAAAg");
        this.shape_18.setTransform(-72.5,-42.9);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f("#284B63").s().p("AgBAAQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQgBAAAAgBg");
        this.shape_19.setTransform(-74,-42.7);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f("#284B63").s().p("AgBAAQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQgBAAAAgBg");
        this.shape_20.setTransform(-73.8,-43.7);

        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAAAAAQAAABABAAQAAAAAAABQAAAAABAAQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIAAAAQAAAAAAAAQAAAAAAgBQgBAAAAgBQAAAAAAgBg");
        this.shape_21.setTransform(-73.3,-44.6);

        this.shape_22 = new cjs.Shape();
        this.shape_22.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQABgBAAAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQAAAAAAABQAAAAAAAAQABABAAAAQAAABgBAAQAAAAAAABQgBAAAAAAQAAAAAAAAQAAgBAAAAQAAAAgBgBQAAAAAAgBg");
        this.shape_22.setTransform(-72.8,-43.9);

        this.shape_23 = new cjs.Shape();
        this.shape_23.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQAAAAABABQAAAAAAAAQAAABAAAAQAAABgBAAQAAAAAAABQAAAAgBAAQAAAAAAAAQAAgBAAAAQgBAAAAgBQAAAAAAgBg");
        this.shape_23.setTransform(-73,-42.6);

        this.shape_24 = new cjs.Shape();
        this.shape_24.graphics.f("#284B63").s().p("AAAAAQgBAAAAAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_24.setTransform(-73.3,-43.5);

        this.shape_25 = new cjs.Shape();
        this.shape_25.graphics.f("#284B63").s().p("AAAAAQAAAAAAAAQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_25.setTransform(-73.5,-42.6);

        this.shape_26 = new cjs.Shape();
        this.shape_26.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQAAAAABABQAAAAAAAAQAAABAAAAQAAABgBAAQAAAAAAAAQAAABgBAAIAAAAQAAAAAAAAQAAAAAAgBQgBAAAAgBQAAAAAAgBg");
        this.shape_26.setTransform(-72.4,-40.6);

        this.shape_27 = new cjs.Shape();
        this.shape_27.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAABABQAAAAAAAAQAAABAAAAQAAABgBAAQAAABAAAAQAAAAgBAAQAAAAAAAAQAAAAAAgBQAAAAgBgBQAAAAAAgBg");
        this.shape_27.setTransform(-74,-41.6);

        this.shape_28 = new cjs.Shape();
        this.shape_28.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQABgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQAAAAAAAAQABABAAAAQAAABgBAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAgBgBQAAAAAAgBg");
        this.shape_28.setTransform(-73.9,-40.6);

        this.shape_29 = new cjs.Shape();
        this.shape_29.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAAAAAQABABAAAAQAAAAAAABQABAAAAAAQAAABAAAAQAAABgBAAQAAAAAAABQAAAAgBAAQAAAAAAAAQAAgBAAAAQgBAAAAgBQAAAAAAgBg");
        this.shape_29.setTransform(-73.7,-39.7);

        this.shape_30 = new cjs.Shape();
        this.shape_30.graphics.f("#284B63").s().p("AAAAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAAAAAABQgBAAAAAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQAAAAAAgBg");
        this.shape_30.setTransform(-73.1,-38.8);

        this.shape_31 = new cjs.Shape();
        this.shape_31.graphics.f("#284B63").s().p("AgBAAQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQAAAAAAAAQAAABAAAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQgBAAAAgBg");
        this.shape_31.setTransform(-72.6,-39.6);

        this.shape_32 = new cjs.Shape();
        this.shape_32.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQABAAAAABQAAAAAAAAQAAABAAAAQAAABgBAAQAAAAAAABQAAAAgBAAQAAAAAAAAQAAgBAAAAQAAAAAAgBQAAAAgBgBg");
        this.shape_32.setTransform(-72.9,-40.8);

        this.shape_33 = new cjs.Shape();
        this.shape_33.graphics.f("#284B63").s().p("AAAAAQAAAAAAAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAAAAAABQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_33.setTransform(-73.1,-39.9);

        this.shape_34 = new cjs.Shape();
        this.shape_34.graphics.f("#284B63").s().p("AgBAAQAAAAABAAQAAAAAAgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAABQAAAAAAABQABAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQgBAAAAAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQgBAAAAgBg");
        this.shape_34.setTransform(-73.4,-40.8);

        this.shape_35 = new cjs.Shape();
        this.shape_35.graphics.f("#284B63").s().p("AgBAAIABgCQAAAAABAAQAAAAAAABQABAAAAABQAAAAAAAAQAAABAAAAQAAABgBAAQAAABAAAAQAAAAgBAAQAAAAAAAAQAAAAAAgBQAAAAAAgBQAAAAgBgBg");
        this.shape_35.setTransform(-73.5,-41.7);

        this.shape_36 = new cjs.Shape();
        this.shape_36.graphics.f("#284B63").s().p("AgBAAQAAAAAAAAQAAgBABAAQAAAAAAgBQAAAAAAAAQAAAAABAAQAAABAAAAQAAAAABABQAAAAAAAAQAAAAAAABQAAABgBAAQAAAAAAABQAAAAgBAAQAAAAAAAAQAAgBAAAAQgBAAAAgBQAAgBAAAAg");
        this.shape_36.setTransform(-72.9,-41.7);

        this.shape_37 = new cjs.Shape();
        this.shape_37.graphics.f("#1D6385").s().p("AgGAZQgEgLAAgOQgBgNADgKQADgLAFAAQAEAAADAKQAEAKAAAOQABAOgDAKQgDAKgFABQgDAAgEgKg");
        this.shape_37.setTransform(-73.2,-41.7);

        this.shape_38 = new cjs.Shape();
        this.shape_38.graphics.f("#284B63").s().p("AgUgIIABgZIAiAAQAEAAACAMQABAIgEAGQgbAlgFAEg");
        this.shape_38.setTransform(-71.3,-41.8);

        this.shape_39 = new cjs.Shape();
        this.shape_39.graphics.f("#1D6385").s().p("AhoAbICyg7QAJACAMAGIAKAGIjQAzg");
        this.shape_39.setTransform(-59.2,-47.4);

        this.shape_40 = new cjs.Shape();
        this.shape_40.graphics.f("#20526C").s().p("Ah0APICbg2QAGgDANADQAMACANAHQARAJAKAKQAJAKgDAFQgBAEgFAAIjfAhg");
        this.shape_40.setTransform(-58.1,-46.8);

        this.shape_41 = new cjs.Shape();
        this.shape_41.graphics.f("#204358").s().p("AhXgeIALgWICkBTIgLAWg");
        this.shape_41.setTransform(-45.4,-59.8);

        this.shape_42 = new cjs.Shape();
        this.shape_42.graphics.f("#1D6385").s().p("AhcgUIAVgqICkBTIgVAqg");
        this.shape_42.setTransform(-49.9,-50.9);

        this.shape_43 = new cjs.Shape();
        this.shape_43.graphics.f("#20526C").s().p("Ah1AcIBHiLICkBUIhHCLg");
        this.shape_43.setTransform(-47.8,-55);

        this.shape_44 = new cjs.Shape();
        this.shape_44.graphics.f("#20526C").s().p("AgbAcIAxg8IAGAFIgxA8g");
        this.shape_44.setTransform(-61.1,-49.8);

        this.shape_45 = new cjs.Shape();
        this.shape_45.graphics.f("#204358").s().p("AgXAMQgKgqAmgmQgSAUgFAbQgFAbAMAWQALAXAaANIgCAFQgmgTgJgmg");
        this.shape_45.setTransform(-60.2,-61.6);

        this.shape_46 = new cjs.Shape();
        this.shape_46.graphics.f("#20526C").s().p("AhqAyQgOgbAGgfQAGghAYgYQAYgXAfgEQAhgDAgAWQAuAgAjBRIgOAGQgbg+giggQgjgggjADQgaADgUAUQgUAVgFAcQgFAaAMAXQAMAWAaAOIgGANQgfgQgPgbg");
        this.shape_46.setTransform(-52.1,-63.2);

        this.shape_47 = new cjs.Shape();
        this.shape_47.graphics.f("#EB6B7E").s().p("AgQATQgGgHAAgEQAAgCAIgHQAHgGgEgBIgNgEQgGgEABgFQACgGAKABIARAEIAQgEIAOALIgHANIgNATQgHAJgFABQgGAAgIgIg");
        this.shape_47.setTransform(-47.6,-69.6);

        this.shape_48 = new cjs.Shape();
        this.shape_48.graphics.f("#FFFFFF").s().p("AAAABIABgFIAAAKQgBgBAAAAQAAgBAAAAQAAgBAAAAQAAgBAAgBg");
        this.shape_48.setTransform(-91.5,-3.4);

        this.shape_49 = new cjs.Shape();
        this.shape_49.graphics.f("#EB6B7E").s().p("AgKAdIgZgLQgIgJAMgRQALgQAQgGIAbAMIAMAPQAHAKgFAFQgFAGgEgEIgNgMQgEAPgEAGQgEAIgFAAQgEAAgEgCg");
        this.shape_49.setTransform(30.6,-81.8);

        this.shape_50 = new cjs.Shape();
        this.shape_50.graphics.f("#284B63").s().p("AigCrQAvikBphpQBfhgBKAJIgNBfQhwAWhJBaQgwA8gpBpg");
        this.shape_50.setTransform(46.1,-101.7);

        this.shape_51 = new cjs.Shape();
        this.shape_51.graphics.f("#EB6B7E").s().p("AgJAUQgEgBgEgEQgFgFABgGQAAgIAXgNQAKgEAGAEQAFAEgBAJQgCAKgOAIQgJAGgFAAIgBAAg");
        this.shape_51.setTransform(83,-69.9);

        this.shape_52 = new cjs.Shape();
        this.shape_52.graphics.f("#284B63").s().p("AgxAdQgPg9ARhVIAPgpIA/gWQggBQgCBDQgDBmA/BdIgdATQg9hUgQhEg");
        this.shape_52.setTransform(76.6,-90);

        this.shape_53 = new cjs.Shape();
        this.shape_53.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAABQgBAAAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_53.setTransform(105.4,-36.9);

        this.shape_54 = new cjs.Shape();
        this.shape_54.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAAAAAAAQAAABgBABQAAAAAAAAQAAABgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_54.setTransform(105.7,-38.2);

        this.shape_55 = new cjs.Shape();
        this.shape_55.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAAAAAAAQAAABgBABQAAAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_55.setTransform(107.3,-37.8);

        this.shape_56 = new cjs.Shape();
        this.shape_56.graphics.f("#284B63").s().p("AAAADQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAAAQAAABgBAAQAAAAAAAAIAAAAg");
        this.shape_56.setTransform(107.3,-38.9);

        this.shape_57 = new cjs.Shape();
        this.shape_57.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAABAAAAQAAAAAAAAQAAABAAABQgBAAAAAAQAAABgBAAQAAAAAAAAQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_57.setTransform(106.9,-40);

        this.shape_58 = new cjs.Shape();
        this.shape_58.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAAAQgBABAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_58.setTransform(106.2,-39.2);

        this.shape_59 = new cjs.Shape();
        this.shape_59.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAAAAAAAQAAABgBABQAAAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_59.setTransform(106.2,-37.8);

        this.shape_60 = new cjs.Shape();
        this.shape_60.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAAAQABAAAAABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAAAQAAABgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_60.setTransform(106.6,-38.8);

        this.shape_61 = new cjs.Shape();
        this.shape_61.graphics.f("#284B63").s().p("AAAADQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAgBAAAAIABgCIAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBAAQAAABAAAAQAAABAAAAQgBAAAAAAIAAAAg");
        this.shape_61.setTransform(106.8,-37.7);

        this.shape_62 = new cjs.Shape();
        this.shape_62.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAAAQABABAAAAQAAABAAAAQAAAAAAAAQAAABgBABQAAAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_62.setTransform(105.3,-35.7);

        this.shape_63 = new cjs.Shape();
        this.shape_63.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAABQgBAAAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_63.setTransform(107.2,-36.6);

        this.shape_64 = new cjs.Shape();
        this.shape_64.graphics.f("#284B63").s().p("AAAADQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAABAAQAAAAABAAQAAAAAAABQAAAAAAABQAAAAAAAAIgCADIAAAAg");
        this.shape_64.setTransform(107,-35.5);

        this.shape_65 = new cjs.Shape();
        this.shape_65.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAAAQgBABAAAAQAAAAAAAAQAAAAAAAAQgBgBAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_65.setTransform(106.6,-34.5);

        this.shape_66 = new cjs.Shape();
        this.shape_66.graphics.f("#284B63").s().p("AAAADQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBQAAAAABAAQAAgBAAAAQAAgBAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAAAQAAABgBAAQAAAAAAAAIAAAAg");
        this.shape_66.setTransform(105.9,-33.6);

        this.shape_67 = new cjs.Shape();
        this.shape_67.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAABAAAAQAAAAAAAAQAAABAAABQgBAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_67.setTransform(105.4,-34.5);

        this.shape_68 = new cjs.Shape();
        this.shape_68.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQABAAAAABQAAAAAAABQAAAAAAAAQgBABAAABQAAAAAAAAQgBABAAAAQAAAAAAAAQAAAAAAgBQgBAAAAAAQAAgBAAAAQAAgBAAAAg");
        this.shape_68.setTransform(105.9,-35.8);

        this.shape_69 = new cjs.Shape();
        this.shape_69.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAABAAQAAAAAAAAQABAAAAABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAgBAAAAg");
        this.shape_69.setTransform(106,-34.8);

        this.shape_70 = new cjs.Shape();
        this.shape_70.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAAAQAAgBAAAAQAAAAAAAAQABAAAAAAQAAABABAAQAAABAAAAQAAAAAAAAQAAABAAABQgBAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAAAAAgBg");
        this.shape_70.setTransform(106.5,-35.8);

        this.shape_71 = new cjs.Shape();
        this.shape_71.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAAAAAQABAAAAAAQAAAAABABQAAAAAAABQAAAAAAAAQAAABAAAAQgBABAAAAQAAABgBAAQAAAAAAAAQAAAAAAAAQgBAAAAgBQAAAAAAgBQAAgBAAAAg");
        this.shape_71.setTransform(106.6,-36.7);

        this.shape_72 = new cjs.Shape();
        this.shape_72.graphics.f("#284B63").s().p("AgBAAQAAAAABgBQAAAAAAgBQAAAAAAAAQAAAAABAAQAAAAAAAAQABAAAAABQAAAAAAABQAAAAAAAAQAAABgBABQAAAAAAABQAAAAgBAAQAAAAAAAAQAAAAAAAAQAAAAgBgBQAAAAAAgBQAAAAAAgBg");
        this.shape_72.setTransform(105.9,-36.8);

        this.shape_73 = new cjs.Shape();
        this.shape_73.graphics.f("#1D6385").s().p("AgFAmQgFAAgCgMQgCgMADgPQACgPAGgLQAEgLAFABQAGABABALQACAMgDAPQgBAQgGALQgFAJgEAAIgBAAg");
        this.shape_73.setTransform(106.3,-36.8);

        this.shape_74 = new cjs.Shape();
        this.shape_74.graphics.f("#284B63").s().p("AgHANIgOgYQgDgFACgKQAEgOAEABIAmAFIgCAcIgNAuIgQgbg");
        this.shape_74.setTransform(104.5,-37.2);

        this.shape_75 = new cjs.Shape();
        this.shape_75.graphics.f("#1D6385").s().p("AhwglIALgFQANgEAMgCIC9BaIgCAHg");
        this.shape_75.setTransform(91.6,-44.8);

        this.shape_76 = new cjs.Shape();
        this.shape_76.graphics.f("#20526C").s().p("Ah5gKQgFgBgBgEQgDgHAMgKQAMgKAUgHQAkgNAOAJICkBSIgGAcg");
        this.shape_76.setTransform(90.1,-44.1);

        this.shape_77 = new cjs.Shape();
        this.shape_77.graphics.f("#204358").s().p("AhlAWIDChFIAJAaIjCBFg");
        this.shape_77.setTransform(78,-60.7);

        this.shape_78 = new cjs.Shape();
        this.shape_78.graphics.f("#1D6385").s().p("AhqAKIDDhFIASAyIjCBFg");
        this.shape_78.setTransform(81.8,-50.3);

        this.shape_79 = new cjs.Shape();
        this.shape_79.graphics.f("#20526C").s().p("Ah+guIDChGIA7CjIjCBGg");
        this.shape_79.setTransform(80,-55.1);

        this.shape_80 = new cjs.Shape();
        this.shape_80.graphics.f("#20526C").s().p("AgagiIAHgFIAuBKIgHAFg");
        this.shape_80.setTransform(94,-47.5);

        this.shape_81 = new cjs.Shape();
        this.shape_81.graphics.f("#204358").s().p("AghBGQAggMAPgXQAQgXgBgeQgCgggTgYQAnAwgSAsQgPApgtAQg");
        this.shape_81.setTransform(94.3,-60.8);

        this.shape_82 = new cjs.Shape();
        this.shape_82.graphics.f("#20526C").s().p("AA0BbQAggMAQgXQAQgXgBgfQgCgfgUgaQgTgZgdgHQgngJgqAfQgrAfgmBCIgOgJQAxhVA4geQAmgUAlAJQAiAIAXAeQAYAdACAlQACAkgUAcQgUAcgkAOg");
        this.shape_82.setTransform(85.7,-63);

        this.shape_83 = new cjs.Shape();
        this.shape_83.graphics.f("#FFFFFF").s().p("AhbBlIB+jpIAEg1IA1AjIgiAbIhVEGIgxAvg");
        this.shape_83.setTransform(66.9,-93.3);

        this.shape_84 = new cjs.Shape();
        this.shape_84.graphics.f("#284B63").s().p("AABFbIhiqNIAwgoIA4A7IAkJHIA3Azg");
        this.shape_84.setTransform(59.3,-36);

        this.shape_85 = new cjs.Shape();
        this.shape_85.graphics.f("#284B63").s().p("AhgFbIBWqZIAugcIA9AcIiKJmIA2Azg");
        this.shape_85.setTransform(41.1,-36);

        this.shape_86 = new cjs.Shape();
        this.shape_86.graphics.f("#284B63").s().p("AjGDsQA1mDCpiHICvB4QhGBhgwBkQg9CCgTB+g");
        this.shape_86.setTransform(59.8,-91.5);

        this.shape_87 = new cjs.Shape();
        this.shape_87.graphics.f("#EB6B7E").s().p("AAAAeQgGAAgHgLIgNgWIgHgPIARgLIARAFIAUgDQALgBABAHQACAGgIADIgOAEQgFABAHAHQAJAIAAADQAAAFgIAHQgIAHgHAAIgBAAg");
        this.shape_87.setTransform(82.2,-71.3);

        this.shape_88 = new cjs.Shape();
        this.shape_88.graphics.f("#284B63").s().p("AgOg/ICKBgIhAAaIhEg5IgnAWIgCgDQgIgFgIABQgIACgFAHQgEAHABAJIgqAWg");
        this.shape_88.setTransform(82.7,-134.6);

        this.shape_89 = new cjs.Shape();
        this.shape_89.graphics.f("#38403B").s().p("AgKAGQgLgWADgWIAFABQgDAVALAUQAJATAQAMIgDAEQgRgMgKgVg");
        this.shape_89.setTransform(76.8,-117.2);

        this.shape_90 = new cjs.Shape();
        this.shape_90.graphics.f("#38403B").s().p("AgKARIgDgCQgPgKAJgXIAFACQgEALACAHQACAHAGADQAHADAHgCQAIgEAFgIIAEACQgFALgLAEIgHABQgFAAgFgCg");
        this.shape_90.setTransform(83.6,-127);

        this.shape_91 = new cjs.Shape();
        this.shape_91.graphics.f("#EB6B7E").s().p("AiTAnIDBjHIBcBBIgTAuIAdByIg1glIhECFg");
        this.shape_91.setTransform(76.8,-123.8);

        this.shape_92 = new cjs.Shape();
        this.shape_92.graphics.f("#998F71").s().p("ACZARQhdgHAAgKQAAgJBdgHQBegHCEAAQCFAABdAHQBeAHAAAJQAAAKheAHQheAHiEAAQiEAAhegHgApcARQhegHAAgKQAAgJBegHQBdgHCFAAQCEAABeAHQBdAHAAAJQAAAKhdAHQheAHiEAAQiEAAhegHg");
        this.shape_92.setTransform(12.9,-0.2);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_92},{t:this.shape_91},{t:this.shape_90},{t:this.shape_89},{t:this.shape_88},{t:this.shape_87},{t:this.shape_86},{t:this.shape_85},{t:this.shape_84},{t:this.shape_83},{t:this.shape_82},{t:this.shape_81},{t:this.shape_80},{t:this.shape_79},{t:this.shape_78},{t:this.shape_77},{t:this.shape_76},{t:this.shape_75},{t:this.shape_74},{t:this.shape_73},{t:this.shape_72},{t:this.shape_71},{t:this.shape_70},{t:this.shape_69},{t:this.shape_68},{t:this.shape_67},{t:this.shape_66},{t:this.shape_65},{t:this.shape_64},{t:this.shape_63},{t:this.shape_62},{t:this.shape_61},{t:this.shape_60},{t:this.shape_59},{t:this.shape_58},{t:this.shape_57},{t:this.shape_56},{t:this.shape_55},{t:this.shape_54},{t:this.shape_53},{t:this.shape_52},{t:this.shape_51},{t:this.shape_50},{t:this.shape_49},{t:this.shape_48},{t:this.shape_47},{t:this.shape_46},{t:this.shape_45},{t:this.shape_44},{t:this.shape_43},{t:this.shape_42},{t:this.shape_41},{t:this.shape_40},{t:this.shape_39},{t:this.shape_38},{t:this.shape_37},{t:this.shape_36},{t:this.shape_35},{t:this.shape_34},{t:this.shape_33},{t:this.shape_32},{t:this.shape_31},{t:this.shape_30},{t:this.shape_29},{t:this.shape_28},{t:this.shape_27},{t:this.shape_26},{t:this.shape_25},{t:this.shape_24},{t:this.shape_23},{t:this.shape_22},{t:this.shape_21},{t:this.shape_20},{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.instance_1}]}).wait(300));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-138,-292.4,1257.8,295.4);


    (lib.container = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{idle:1,on:7});

        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_1 = function() {
            this.main.stop();
            this.main.clouds.stop();
        }
        this.frame_7 = function() {
            this.main.play();
            this.main.clouds.subclouds.play();
        }
        this.frame_12 = function() {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1).call(this.frame_1).wait(6).call(this.frame_7).wait(5).call(this.frame_12).wait(1));

        // Layer_1
        this.main = new lib.main();
        this.main.name = "main";
        this.main.parent = this;
        this.main.setTransform(-16,-40);

        this.timeline.addTween(cjs.Tween.get(this.main).wait(13));

        // Layer_4
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#B6A984").s().p("EghlAc0MAAAg5nMBDLAAAMAAAA5ng");
        this.shape.setTransform(0.5,142.9);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#43C1C4").s().p("EghiAzGMAAAhmLMBDGAAAMAAABmLg");
        this.shape_1.setTransform(0.8,-0.8,1.002,1.002);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(13));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-214.5,-332.4,1318.2,659.8);

// stage content:
    (lib.inf_g13 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer 1
        this.container = new lib.container();
        this.container.name = "container";
        this.container.parent = this;
        this.container.setTransform(215,327);

        this.timeline.addTween(cjs.Tween.get(this.container).wait(1));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(215.5,321.6,1318.2,659.8);
// library properties:
    lib.properties = {
        id: '510B49D90F975C4B87E133B9D22EEE79',
        width: 430,
        height: 654,
        fps: 24,
        color: "#FFFFFF",
        opacity: 1.00,
        manifest: [],
        preloads: []
    };



// bootstrap callback support:

    (lib.Stage = function(canvas) {
        createjs.Stage.call(this, canvas);
    }).prototype = p = new createjs.Stage();

    p.setAutoPlay = function(autoPlay) {
        this.tickEnabled = autoPlay;
    }
    p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
    p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
    p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
    p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

    p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

    an.bootcompsLoaded = an.bootcompsLoaded || [];
    if(!an.bootstrapListeners) {
        an.bootstrapListeners=[];
    }

    an.bootstrapCallback=function(fnCallback) {
        an.bootstrapListeners.push(fnCallback);
        if(an.bootcompsLoaded.length > 0) {
            for(var i=0; i<an.bootcompsLoaded.length; ++i) {
                fnCallback(an.bootcompsLoaded[i]);
            }
        }
    };

    an.compositions = an.compositions || {};
    an.compositions['510B49D90F975C4B87E133B9D22EEE79'] = {
        getStage: function() { return exportRoot.getStage(); },
        getLibrary: function() { return lib; },
        getSpriteSheet: function() { return ss; },
        getImages: function() { return img; }
    };

    an.compositionLoaded = function(id) {
        an.bootcompsLoaded.push(id);
        for(var j=0; j<an.bootstrapListeners.length; j++) {
            an.bootstrapListeners[j](id);
        }
    }

    an.getComposition = function(id) {
        return an.compositions[id];
    }



})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;