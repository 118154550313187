(function($){
    $(function(){
        var siteName = window.location.host.replace('www.', '');
        $('a[href^="htt"]:not([target]):not([href*="www.' + siteName + '"]):not([href*="//' + siteName + '"])').attr('target', '_blank');
    });
})(jQuery);

jQuery(document).ready(function () {
  /* Append search box in main navigation*/

    jQuery('.nav li:last-child').before('<li class="search "><span><img src="/wp-content/themes/sandhill/dist/images/search.svg" alt="Search"></span></li>');
    var win_width = jQuery(window).width();
    if(win_width > 991) {
        jQuery('.nav li:last-child a').addClass('btn-default');
    }else{
        jQuery('.nav li:last-child a').removeClass('btn-default');
    }

    if(Function('/*@cc_on return document.documentMode===10@*/')()){
      jQuery("html").addClass("ie10");
    }else{
        jQuery("html").addClass("no-ie10");
    }
  if (jQuery('body.home').length < 1) {
    jQuery('.navigation').addClass('bg-white');
  }

  /*
   * Menu functionality
   */

  jQuery('.navigation .toggle').click(function (e) {
    e.stopPropagation();
    jQuery('body').toggleClass('overflow-hidden');
    jQuery('body').addClass('nav-open');
  });

  jQuery(document).click(function () {
    var win_width = jQuery(window).width();
    if (win_width < 992)
    {
      jQuery('body').removeClass('overflow-hidden');
      jQuery('body').removeClass('animation-fade');
    } else {
      jQuery('body').removeClass('animation-fade');
    }
  });

  /* Search overlay functionality */
  jQuery('.menu .search').click(function (e) {
    e.stopPropagation();
    jQuery('body').addClass('overflow-hidden');
    jQuery('body').addClass('animation-fade');
    jQuery('body').removeClass('overflow-hidden');
    jQuery('body').css({"opacity": "1"});
    jQuery('.overlay-search').addClass('overlay-open');
    setTimeout(function () {
      jQuery(".search-input").focus();
    }, 50);

  });
  jQuery('.overlay-search .close').click(function () {
    jQuery('body').removeClass('overflow-hidden');
    jQuery('.overlay-search').removeClass('overlay-open');
    jQuery('body').removeClass('animation-fade');
    jQuery(".search-input").val('');
  });
  jQuery('.overlay-search').click(function () {
    jQuery('body').removeClass('overflow-hidden');
    jQuery('.overlay-search').removeClass('overlay-open');
    jQuery(".search-input").val('');
  });
  jQuery('.overlay-search .content-wrap').click(function (e) {
    e.stopPropagation();
  });

  setSelectedPageNav();
  detect_touchDevice();
});


/* Auto nav select according to page */
function setSelectedPageNav() {
  var page_url = window.location.pathname;
  if (page_url.indexOf('/') > -1) {
    var array = page_url.split('/');
    jQuery(".navigation .content-wrap .menu .nav li a").each(function () {
      var link_val = jQuery(this).attr('data-title');
      if (array[1] === link_val) {
        jQuery(this).addClass("active");
      }
    });
  }
}

jQuery(window).load(function () {
  detect_touchDevice();
  jQuery("body").addClass("animate-fade-down");
  setTimeout(function () {
    jQuery("body").addClass("nav-open");
    jQuery("body").addClass("animate-line");
  }, 1500);
  setTimeout(function () {
    jQuery("body").addClass("addThis"); 
  }, 1000);
});


// Fix navigation on scroll

jQuery(window).on("resize", function () {
    var win_width = jQuery(window).width();
    if(win_width > 991) {
        jQuery('.nav li:last-child a').addClass('btn-default');
    }else{
        jQuery('.nav li:last-child a').removeClass('btn-default');
    }
  jQuery('body').removeClass('nav-open');
  var scroll = jQuery(document).scrollTop();
  var mainnavHeight = jQuery(".navigation").outerHeight();
  var bannerHeight2 = jQuery(".large-banner").height() - 160;
  var bannerHeight1 = jQuery(".hero-banner").height() - 160;
  var violatorbar_height = jQuery('.violator-bar').outerHeight();
  var flag= 1;
  jQuery(window).scroll(function () {
    if (jQuery('body.home').length > 0) {
      if((flag !=0)&& (jQuery('body').hasClass('violator_active'))){
      mainnavHeight = mainnavHeight + bannerHeight1 + violatorbar_height;
      flag = 0;
    } else if((flag !=0)&& (jQuery('body').hasClass('violator_inactive'))){
          mainnavHeight = mainnavHeight + bannerHeight1;
          flag = 0;
      }
    } else if (jQuery('body.about').length > 0) {
      if(flag !=0){
      mainnavHeight = mainnavHeight + bannerHeight2;
      flag = 0;
    }
    }
    var scrolled = jQuery(document).scrollTop();
    if ((scrolled > scroll)) {
      if (scrolled >= mainnavHeight) {
        jQuery(".navigation").addClass("fixed");
      }
      jQuery(".navigation").removeClass("off-canvas");
    } else if ((scrolled < scroll)) {
      if (scrolled > mainnavHeight) {
        jQuery(".navigation.fixed").addClass("off-canvas");
      } else if (scrolled < mainnavHeight) {
        jQuery(".navigation").removeClass("off-canvas");
        jQuery(".navigation").removeClass("fixed");
      }
    }
    scroll = jQuery(document).scrollTop();
  });
}).resize();


jQuery(window).on("resize", function () {
    var violatorbar_height = jQuery('.violator-bar').outerHeight();
    var navigation_height = jQuery('.navigation ').outerHeight();
  jQuery(window).scroll(function () {
    if (jQuery('body.home, body.about').length > 0) {
      var bannerHeight = jQuery(".hero-banner, .large-banner").height();
        if(jQuery('body').hasClass('violator_active')){
            bannerHeight = bannerHeight+navigation_height;
        }else{
            bannerHeight = bannerHeight;
        }
      var scroll = jQuery(document).scrollTop();
      if (scroll >= bannerHeight) {
        jQuery(".hero-banner .inner-wrapper, .large-banner .inner-wrapper").addClass('hide');
        jQuery(".hero-banner .inner-wrapper, .large-banner .inner-wrapper").removeClass('show');
      } else {
        jQuery(".hero-banner .inner-wrapper, .large-banner .inner-wrapper").addClass('show');
        jQuery(".hero-banner .inner-wrapper, .large-banner .inner-wrapper").removeClass('hide');
      }
    }
  });
}).resize();

jQuery(window).on({
  'orientationchange resize scroll': function () {
    detect_touchDevice();
  }
});

function detect_touchDevice() {
  var is_touch_device = 'ontouchstart' in document.documentElement;
  if (is_touch_device) {
    jQuery('body').addClass('touch');
  }
  /* Add class on touch device*/
  if (/Android|webOS|Nokia|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    jQuery('body').addClass('touch');
  } else {
    jQuery('body').removeClass('touch');
  }
}


