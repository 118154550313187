jQuery(window).bind("load scroll resize", function () {
  //  intro_height();
  var $winTop = jQuery(window).scrollTop();
  /*
   * Fadein up with normal delay
   */
  if (jQuery('.intro-with-image').length > 0) {
    jQuery(".fade-in-down").each(function () {
      var $offset = jQuery(".intro-with-image").offset().top;
      var $secPosition = $offset - $winTop;
      if (($winTop >= ($offset - 300)) && ($secPosition > -500)) {
        jQuery(this).css({
          'animation-name': 'fadeInDown',
          'animation-duration': '0.6s',
          'animation-delay': '0.7s'
        });
      }
    });
  }
});

jQuery(window).load(function () {
  //  intro_height();
  var video = document.getElementById('videoPlayer');
  if (jQuery(video).length > 0) {
    video.setAttribute("playsinline", true);
    video.setAttribute("controls", true);
    setTimeout(function () {
      video.removeAttribute("controls");
    }, 1);
  }


});

jQuery(window).on('load', function () {
  jQuery(window).on("resize", function () {
    /*Keep Image In center from vertically and horizontally  */
    // if (jQuery(".intro-with-image .bg-img").length) {
    //   jQuery(".intro-with-image .bg-img").each(function () {
    //     var img = jQuery(this).children("img"),
    //       defaultWidth = img.prop('naturalWidth'),
    //       defaultHeight = img.prop('naturalHeight'),
    //       parentHeight = jQuery(this).height(),
    //       parentWidth = jQuery(this).width(),
    //       aspectRatio = defaultWidth / defaultHeight;
    //     img.css({"height": "auto", "width": "100%", "margin-left": "0px"});
    //     var imgHeight = parentWidth / aspectRatio;
    //     var imgTop = (imgHeight - parentHeight) / 2;
    //     img.css({"margin-top": "-" + imgTop + "px"});
    //     if (img.height() < parentHeight) {
    //       img.css({"height": "100%", "width": "auto"});
    //       var right_margin = (img.width() - parentWidth) / 2;
    //       img.css({"margin-left": "-" + right_margin + "px", "margin-top": "0"});
    //     } else if (img.width() < parentWidth) {
    //       img.css({"height": "auto", "width": "100%", "margin-left": "0"});
    //       var imgHeight = parentWidth / aspectRatio;
    //       var imgTop = (imgHeight - parentHeight) / 2;
    //       img.css({"margin-top": "-" + imgTop + "px"});
    //     }
    //   });
    // }
  }).resize();
});

jQuery(window).on("load resize", function () {
  jQuery('.intro-with-image').each(function () {
    setTimeout(function () {
      var markerPosition = ((jQuery('.intro-with-image .intro-video > .v-middle-wrapper').outerHeight()) / 2) - 43;
      var secHeight = jQuery('.intro-with-image .container > .content').outerHeight();
      if ((markerPosition - secHeight) < 0) {
        var sectionPadding = -((markerPosition - secHeight) - 120);
      } else {
        var sectionPadding = (markerPosition - secHeight) + 120;
      }
      jQuery('.intro-with-image ').css({ 'padding-top': sectionPadding + 'px' });
    }, 100);
  });
}).resize();

