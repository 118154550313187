

/*
 * Movable Parallax Functionality.
 */

function cta_paralax() {
  jQuery(window).on('scroll', function (e) {
    e.stopPropagation();
    if (jQuery('.cta-banner').length > 0) {
      var $div_offset = jQuery('.cta-banner').offset().top;
    }
    var scrollTop = jQuery(window).scrollTop();
    var $diff = ($div_offset - scrollTop) / 6 * 0.5;
    jQuery('.cta-banner').stop(true, true).css({'background-position': '50%' + $diff + 'px'});
  });
}

jQuery(document).ready(function () {
  if (!jQuery('body').hasClass('touch')) {
    cta_paralax();
  }      
  clc_height();
});

jQuery(window).on("load", function () {
  jQuery(window).on("resize", function () {
    clc_height();
  }).resize();
});


function clc_height() {
  var $height = jQuery(".cta-banner .content-570").outerHeight();
  jQuery(".cta-banner").css('height', $height + 'px');
}


