(function (cjs, an) {

    var p; // shortcut to reference prototypes
    var lib={};var ss={};var img={};
    lib.ssMetadata = [];


// symbols:
// helper functions:

    function mc_symbol_clone() {
        var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
        clone.gotoAndStop(this.currentFrame);
        clone.paused = this.paused;
        clone.framerate = this.framerate;
        return clone;
    }

    function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
        var prototype = cjs.extend(symbol, cjs.MovieClip);
        prototype.clone = mc_symbol_clone;
        prototype.nominalBounds = nominalBounds;
        prototype.frameBounds = frameBounds;
        return prototype;
    }


    (lib.tie = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AgkCrIADlLIgQgzIBGgGIgcAzIA5E+IgtBCg");
        this.shape.setTransform(4.8,20.9,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.tie, new cjs.Rectangle(0,0,9.6,41.8), null);


    (lib.subzag1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#003E5B").s().p("AgsgtQAkhfBNgmIACA4QhCAjgbBZQgZBPAKBhIgcABQgPiBAkhfg");
        this.shape.setTransform(18.5,17.2,0.96,0.96);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#EB6378").s().p("AgGAcIgSgBQgKgGAFgUQAEgSANgLIATACIARAJQAKAIgDAGQgDAEgFgBIgQgFIAAAVQgBANgKAAIgCgBg");
        this.shape_1.setTransform(13.8,36.9,0.96,0.96);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AiSBvIAAjdIElAAIAADdg");
        this.shape_2.setTransform(14.1,50.6,0.96,0.96);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AAiAVIAAgWIgCgCIgDgCIg5AAQgDgBgDAFIAAAWIgNAAIAAgWQAAgHAGgFQAGgHAHAAIA5AAQAHAAAGAHQAGAFAAAHIAAAWg");
        this.shape_3.setTransform(13.9,38.3,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.subzag1, new cjs.Rectangle(0,0,28.2,61.3), null);


    (lib.subarmWoman = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#EB6378").s().p("AgPAJIgEgIQgBgFAGgBIATgEQANgCABAIQACAKgIAAIgOADIgIAAQgFAAgBgBg");
        this.shape.setTransform(15.8,4.7,0.96,0.96);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#EB6378").s().p("AgEAEQgGgIgCgGQgBgGAGgBQAFAAABAEQAEALAKADQAAAMgFAFQgGgFgGgJg");
        this.shape_1.setTransform(16,7.6,0.96,0.96);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AgGABQgCgSAHgEQAGADACASQABASgGAEQgGgCgCgTg");
        this.shape_2.setTransform(43.8,3,0.96,0.96);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#906600").s().p("AgEARQgCgHgBgJQgBgJABgGQACgHADgBQADAAADAGQAEAGABAKQACAWgJABIAAAAQgCAAgEgGg");
        this.shape_3.setTransform(44,3,0.96,0.96);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#B4A680").s().p("AgFAXQgEgJgCgNQgBgMADgJQACgKAEAAQAFgBADAJQAFAJACANQABALgCAKQgDAKgFAAIAAAAQgEAAgEgIg");
        this.shape_4.setTransform(44,3,0.96,0.96);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#B4A680").s().p("AhUAfQgEgIgBgLQgBgLACgHQACgIAEAAICkgXIAIAfIgBAfIilAMIgBABQgEAAgDgHg");
        this.shape_5.setTransform(35.8,3.6,0.96,0.96);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#B4A680").s().p("Ag5AVQgCgFgBgIQgBgHABgEQACgGADAAIBvgPIAGAUIgBAVIhxAIIAAAAQgDAAgCgEg");
        this.shape_6.setTransform(22.5,5.1,0.96,0.96);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#003E5B").s().p("AgDAPQgDgGgBgIQAAgIABgHQACgFADgBQACAAADAFQADAGABAJQABAHgCAHQgBAGgEABIAAAAQgCAAgDgGg");
        this.shape_7.setTransform(28.1,4.7,0.96,0.96);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#B4A680").s().p("Ag2AHQgBgLAEgBIBngNIAEAOIgBAOIhnAJIgBAAQgEAAgBgMg");
        this.shape_8.setTransform(12.1,6.3,0.96,0.96);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#003E5B").s().p("AhfA2QBIgGAogKQAfgHASgnQATgqgPgqIAWgGQAZA+gZA1QgXAygzAOQgbAIgyAGIgtAEg");
        this.shape_9.setTransform(10,18.4,0.96,0.96);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#EB6378").s().p("AgEASIgIgVQgFgPAAgGQABgFAKgCIAPgCQAEgBABASQACAWACAGIgCAWg");
        this.shape_10.setTransform(17.2,7.6,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.subarmWoman, new cjs.Rectangle(0,0,45.2,28), null);


    (lib.hairbreeze = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#003E5B").s().p("AhuClQAdgBAMgQQAQgVgngiQghgegNgwQgNguALgsQANgwAkgYQAqgcA/AJQA+AJAjAiIATASQAIAHAIgFQgOAigzANQgyANgcgUQgLAMgGAUIgGAAQgKABgHAJQgGAIABAKQABAHAFAHQAGAGAIABQAVA/gTAvQgQApgsAAQgOAAgQgEg");
        this.shape.setTransform(14,16.3,0.96,0.96);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#003E5B").s().p("AhoCfQAcgCALgPQAQgUgmggQgggdgNguQgMgtAKgqQAMguAigXQAogbA9AIQA7AJAiAhIATARQAHAHAIgFQgOAhgwANQgwAMgbgUQgLAMgFAUIgGAAQgKABgGAIQgGAHABAKQABAHAFAGQAFAGAJABQATA9gRAuQgQAngpAAQgNAAgQgEg");
        this.shape_1.setTransform(14,16.3);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#003E5B").s().p("AhnCfQAcgCALgPQAQgUgmggQgggdgNguQgNgtAKgqQALguAjgXQAogbA8AIQA8AJAiAhIATARQAHAHAIgFQgNAhgxANQgwAMgagUQgLAMgGAUIgGAAQgKABgFAIQgHAHABAKQABAHAGAGQAFAGAJABQATA+gRAtQgPAngpAAQgOAAgQgEg");
        this.shape_2.setTransform(13.9,16.3);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#003E5B").s().p("AhmCfQAcgCALgPQAQgUgnggQgggdgNguQgNgtAJgqQAMguAigXQAogbA8AIQA8AJAiAhIATARQAIAHAHgFQgNAhgwANQgwAMgbgUQgLAMgFAUIgGAAQgKABgFAIQgHAHABAKQABAHAGAGQAFAGAJABQATA+gRAuQgOAmgoAAQgOAAgRgEg");
        this.shape_3.setTransform(13.9,16.3);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#003E5B").s().p("AhkCfQAbgCAMgPQAPgUgmggQghgdgNguQgOgtAKgqQAKguAjgXQAngbA9AIQA8AJAiAhIATARQAHAHAIgFQgNAhgwANQgwAMgbgUQgKAMgGAUIgFAAQgKABgGAIQgHAHACAKQABAHAGAGQAFAGAIABQATA+gQAvQgNAlgoAAQgOAAgRgEg");
        this.shape_4.setTransform(13.9,16.3);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#003E5B").s().p("AhjCeQAcgBALgPQAPgUgmghQghgdgOguQgNgsAJgrQAKgtAjgYQAngaA9AIQA7AJAjAgIATASQAHAGAIgEQgNAhgwAMQgwAMgbgTQgKAMgFATIgGAAQgKABgGAIQgGAIABAJQACAIAFAGQAFAGAJABQASA+gPAwQgNAkgoAAQgOAAgRgFg");
        this.shape_5.setTransform(13.8,16.3);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#003E5B").s().p("AhhCeQAbgBALgPQAPgUgmghQghgdgOguQgOgsAJgrQAKgtAjgYQAmgaA9AIQA8AJAiAgIAUASQAHAGAIgEQgMAhgwAMQgxAMgagTQgLAMgFATIgGAAQgKABgFAIQgHAIACAJQABAIAGAGQAFAGAJABQARA/gPAvQgMAkgnAAQgOAAgRgFg");
        this.shape_6.setTransform(13.8,16.3);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#003E5B").s().p("AhuClQAdgCAMgPQAQgVgngiQghgegNgwQgNgvALgsQANgvAkgZQAqgbA/AIQA+AJAjAiIATATQAIAGAIgEQgOAigzANQgyAMgcgUQgLANgGAUIgGAAQgKABgHAIQgGAIABAKQABAIAFAGQAGAGAIABQARBDgSAxQgOAlgpAAQgOAAgSgFg");
        this.shape_7.setTransform(14,16.3,0.96,0.961,0,-2.4,0);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).wait(1));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0,0,28,32.6);


    (lib.case1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#EB6378").s().p("AABAfQgOAAAAgOQACgRAAgHIgSAHQgGABgDgIQgCgHALgHIATgJIAgAAQANANADAUQAEAWgMAGg");
        this.shape.setTransform(17.8,49.2,0.96,0.96,-1.7);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("Ai0iQIFogBIACEiIlpABg");
        this.shape_1.setTransform(19.1,66.8,0.96,0.96,-1.7);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AArgBIgDgEQgCgCgCAAIhHAAQgCAAgDADIgCADIAAAaIgRAAIAAgaQAAgIAIgIQAHgHAJAAIBHAAQAJAAAHAHQAIAHAAAJIAAAaIgRAAg");
        this.shape_2.setTransform(18.8,50.7,0.96,0.96,-1.7);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#003E5B").s().p("AAjAgQgkiChigtIAlhlQBJAYAyCIQA1CUgUC0IgoABQAHh4gahdg");
        this.shape_3.setTransform(10,23.3,0.96,0.96,-1.7);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.case1, new cjs.Rectangle(0,0,37,81.4), null);


    (lib.bg = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#0F546E").s().p("EgO+AlDIAAgBQjagxiyhGQizhGh+hWIAAgGIAAAAQh6hUhBhcQhDhfgBhjIgBAAMAAAg4vQAAhkBDhhQBBhdB7hVIBxAZQDxiBFdhPIGbEXQEKgfEYAAIAAIkQIDAAG7BmIAAFJQDbAyCyBGQCzBGB+BWIAAFKQB8BUBBBeQBDBhAABlIAAe8QAABmhDBgQhBBeh8BUQh+BWizBGQiyBGjbAyQm7BkoDAAQoCAAm8hkg");

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

    }).prototype = getMCSymbolPrototype(lib.bg, new cjs.Rectangle(-191.6,-247.1,383.2,494.3), null);


    (lib.arm1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#EB6378").s().p("AgRAFQANgGAFgSQABgGAIAAQAIgBAAAKQgCAJgIAMQgHAMgHAJQgJgIgCgNg");
        this.shape.setTransform(39.8,6.2,0.96,0.96);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#EB6378").s().p("AAEANIgUgBQgLAAACgNQAAgMASABIAaADQAHAAAAAJQAAAFgEAFQgBADgMAAIgFAAg");
        this.shape_1.setTransform(38.2,1.9,0.96,0.96);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#003E5B").s().p("AhXBpQgtgHAPhHQAhhiARg9IAtADIgbBvQgKAzAQAEQAnACBhgCIAcBgQiSgSg+gKg");
        this.shape_2.setTransform(41.3,21.2,0.96,0.96);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#EB6378").s().p("AgbAPIAHgmQAFgWAGAAIAVAAQAOABACAIQABAHgEATIgIAdIgYAbg");
        this.shape_3.setTransform(37.4,7,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib.arm1, new cjs.Rectangle(29.6,0.7,23.4,33.4), null);


    (lib._12 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#08455E").s().p("AuNAKQhEhhAAhoIejAAI6dF/Qh/hWhDhgg");
        this.shape.setTransform(0,-181.3,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#053E56").s().p("AtO55IDdjnIXAiZMAAAA50I6dF/g");
        this.shape_1.setTransform(12.8,0,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#0C4C66").s().p("AuNdEQhEhjAAhmMAAAg50IEvC5IBlEoMAB+AyTIWRAAI6dF/Qh/hWhDhgg");
        this.shape_2.setTransform(0,0,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._12, new cjs.Rectangle(-95.8,-200,191.6,400.1), null);


    (lib._11 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#296A80").s().p("AvRc9MAAAg55IejAAMAAAA55g");
        this.shape.setTransform(0,18.8,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#0C4C66").s().p("AvRDAIAAgBQAAhnBEhhQBDhgB+hWIaeF/g");
        this.shape_1.setTransform(0,-181.5,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._11, new cjs.Rectangle(-95.8,-200.3,191.6,400.7), null);


    (lib._10 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#0F546E").s().p("AtOgzQCBhXC2hIQC2hHDfgzIPRKZg");
        this.shape.setTransform(0,-180.8,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#38748A").s().p("AtOaQMAAAg5pIH1g3ISoG3MAAAA5qg");
        this.shape_1.setTransform(0,11.1,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._10, new cjs.Rectangle(-82.9,-213.3,165.9,426.8), null);


    (lib._9 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#115D75").s().p("AnokYQHChnIPAAIAAL/g");
        this.shape.setTransform(0,-181.5,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#487E93").s().p("AnoXxMAAAg56IGCAVIJPKFMAAAA55g");
        this.shape_1.setTransform(0,5,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._9, new cjs.Rectangle(-47.9,-219.1,95.8,438.4), null);


    (lib._8 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#15667D").s().p("Anol/QINAAHEBnIvRKYg");
        this.shape.setTransform(0,-132,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#115D74").s().p("Anov3IFNocIKEh9MAAAAqHIvRKag");
        this.shape_1.setTransform(0,5.1,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._8, new cjs.Rectangle(-47.9,-169.6,95.9,339.4), null);


    (lib._7 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#177186").s().p("ACDlLQDfAyC2BHQC3BICABXI6dGAg");
        this.shape.setTransform(0,-115.5,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#15667D").s().p("AtOvaIadmBMAAAAk2I6dGBg");
        this.shape_1.setTransform(0,13.8,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#CD8914").s().p("ACDlMQDeAzC3BIQC3BHCABYI6dF/g");
        this.shape_2.setTransform(0,115.6,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._7, new cjs.Rectangle(-83,-148.1,166,296.3), null);


    (lib._6 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#177086").s().p("AvQPzIAA/lIehAAIAAflg");
        this.shape.setTransform(0,18.8,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#187B8D").s().p("AvQDAIacl/QB/BWBCBgQBEBhAABog");
        this.shape_1.setTransform(0,-99,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FBAE17").s().p("AvQDAIacl/QB/BWBCBgQBEBiAABng");
        this.shape_2.setTransform(0,99,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._6, new cjs.Rectangle(-95.7,-117.8,191.6,235.7), null);


    (lib._5 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1B8697").s().p("AvQi/IehAAQAABnhEBiQhCBgh/BWg");
        this.shape.setTransform(0,-82.6,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#19778B").s().p("AtOKLIAA6UIUXBhIGGEeIAAaUg");
        this.shape_1.setTransform(-12.8,0,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#187B8E").s().p("AvQKLIacAAIAA0VIDEktIBBhSIAAaUQAABnhEBiQhCBgh/BWg");
        this.shape_2.setTransform(0,0,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._5, new cjs.Rectangle(-95.7,-101.3,191.6,202.7), null);


    (lib._4 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1D92A1").s().p("AtOlMIadGAQiBBXi2BHQi3BJjeAyg");
        this.shape.setTransform(0,-66,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#1A8194").s().p("AnoFWIAA1EILJE7IEIFfIAAVDg");
        this.shape_1.setTransform(-35.1,0,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#1B8698").s().p("AtOCWIPRDdIAAuHIAJg4IJyjRIBRgQIAAVCQiBBYi2BHQi2BIjfAzg");
        this.shape_2.setTransform(0,18.8,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._4, new cjs.Rectangle(-83,-98.6,166,197.2), null);


    (lib._3 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#1F9EAA").s().p("Anol/IPRKYQnEBnoNAAg");
        this.shape.setTransform(0,-49.5,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#1D92A1").s().p("Anom4ICFh4ILSgJIB6AaIAAPxIgCAAIACABQnEBnoNAAg");
        this.shape_1.setTransform(0,31.3,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._3, new cjs.Rectangle(-47.9,-87.1,95.9,174.3), null);


    (lib._2 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#21ACB2").s().p("AnpEZIPTqYIAAL/QoOAAnFhng");
        this.shape.setTransform(0,-33,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#209EAA").s().p("AnpGUIAAqhILxjtIC3ETIArgdIAAL/QoNAAnGhng");
        this.shape_1.setTransform(0,21,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._2, new cjs.Rectangle(-47.9,-70.6,96,141.3), null);


    (lib._1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#25C4C6").s().p("AoYDTQi1hIiBhYIadl/IvSKYQjegyi3hHg");
        this.shape.setTransform(0,-16.5,0.98,0.98);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#CD8914").s().p("AnpCkIB3khINcl3IAAFRIvTKYg");
        this.shape_1.setTransform(35,0,0.98,0.98);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#1FABB1").s().p("AoYDSQi1hHiBhYIAAlQQFDAkADgBQAEgCE/CdIQUjsIvSKYQjegzi3hIg");
        this.shape_2.setTransform(0,16.5,0.98,0.98);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

    }).prototype = getMCSymbolPrototype(lib._1, new cjs.Rectangle(-82.9,-49,166,98.2), null);


    (lib.zag1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_1
        this.instance = new lib.subzag1();
        this.instance.parent = this;
        this.instance.setTransform(30,0,1,1,0,0,0,30,0);

        this.timeline.addTween(cjs.Tween.get(this.instance).to({rotation:-2,x:30.1},9).to({rotation:0,x:30},10).to({rotation:-2,x:30.1},9).to({rotation:0,x:30},10).to({rotation:-2,x:30.1},9).to({rotation:0,x:30},10).to({rotation:-2,x:30.1},9).to({rotation:0,x:30},10).to({rotation:-2,x:30.1},9).to({rotation:0,x:30},24,cjs.Ease.get(1)).wait(164));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0,0,28.2,61.3);


    (lib.guy1 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#003E5B").s().p("AgEAQQgKgBgGgKQgHgJACgMIAGABQgCAKAGAHQAEAIAIABQARADAGgYIAGACQgHAZgTAAg");
        this.shape.setTransform(58,11.1,0.96,0.96);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#003E5B").s().p("AgDAMQgGgBgEgCIgEgEQgGgGABgKIAFAAIAAADQABAFAFADQACAEAEABIAEABQANADAHgNIABgBIABAAIACABQgIAQgPAAIgDAAg");
        this.shape_1.setTransform(57.8,11.1);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#003E5B").s().p("AgCAIQgFAAgDgCIgEgCQgGgEAAgHIAEAAIABACQACACAFADIAFABIADABQALABAIgEIABAAIABAAIAAABQgKAHgLACIgCgBg");
        this.shape_2.setTransform(57.6,11);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#003E5B").s().p("AgTgDIAFAAIANABQAIABALACIACAAQgWAEgHAAQgKgBAAgHg");
        this.shape_3.setTransform(57.6,11);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape}]},135).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).wait(221));
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(135).to({_off:true},1).wait(5).to({_off:false},0).wait(1).to({_off:true},1).wait(5).to({_off:false},0).wait(221));

        // Layer_4
        this.instance = new lib.case1();
        this.instance.parent = this;
        this.instance.setTransform(67,35.1,1,1,0,0,0,-1,5.4);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(17).to({regX:-0.9,rotation:14.7,x:66},26,cjs.Ease.get(-0.5)).to({rotation:-4.5,x:67.1,y:35},10,cjs.Ease.get(0.5)).to({regX:-1,rotation:0,x:67,y:35.1},11).wait(188).to({regX:-0.9,rotation:14.7,x:66},26,cjs.Ease.get(-0.5)).to({rotation:-4.5,x:67.1,y:35.5},10,cjs.Ease.get(0.5)).to({regX:-1,rotation:0,x:67,y:35.1},11).wait(70));

        // Layer_5
        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#003E5B").s().p("AhQFSIhPlhIC2lOIBLgoIA+BEIjpE4IAlGOIh6AAg");
        this.shape_4.setTransform(52.9,117.1,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get(this.shape_4).wait(369));

        // Layer_6
        this.instance_1 = new lib.tie();
        this.instance_1.parent = this;
        this.instance_1.setTransform(54.1,30,1,1,0,0,0,2.9,-2.1);

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(93).to({regY:-2,rotation:-3.7,y:30.1},7,cjs.Ease.get(0.5)).to({rotation:2.3},4).to({regY:-2.1,rotation:0,y:30},3).wait(74).to({regX:3,regY:-2,rotation:3,y:30.1},7).to({regX:2.9,rotation:-1.4},3).to({regY:-2.1,rotation:0,y:30},3).wait(175));

        // Layer_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#003E5B").s().p("ABLAtQAHgHAAgJQAAgJgGgGQgHgHgJAAIgEAAIgUgqIhfAFIgng+IC2AEIAPC1g");
        this.shape_5.setTransform(60.2,9,0.96,0.96);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#003E5B").s().p("AgnAXIAAgFQAVAAAUgKQAXgLAKgTIAFACQgLAUgXAMQgVALgWAAg");
        this.shape_6.setTransform(57.5,22.9,0.96,0.96);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#003E5B").s().p("ABzFiIkeq7IBog5IA1BXIC6LNIhyABg");
        this.shape_7.setTransform(80.4,115.6,0.96,0.96);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#003E5B").s().p("AiagDQgajCAnhRIDigSQAWDBAKBLQAVCcAbCbIjmAOQhAhqgZjCg");
        this.shape_8.setTransform(63.1,58.2,0.96,0.96);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#EB6378").s().p("AhDgWIhEgCIBghRIAOg0IB4ACIApEnIjjASg");
        this.shape_9.setTransform(58.3,16.2,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5}]}).wait(369));

        // Layer_2
        this.instance_2 = new lib.arm1();
        this.instance_2.parent = this;
        this.instance_2.setTransform(62.6,39.6,1,1,-57.2,0,0,60,33.4);

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(86).to({regX:59.9,regY:33.5,rotation:-80.7,x:60.1,y:40.1},14,cjs.Ease.quadInOut).to({rotation:-76.7},5).wait(70).to({regX:60,y:40},0).to({regY:33.4,rotation:33.4},14,cjs.Ease.cubicInOut).to({rotation:25,x:60,y:40.1},5).to({rotation:33.4,x:60.1,y:40},4).to({rotation:25,x:60,y:40.1},5).wait(4).to({rotation:-57.2,x:62.6,y:39.6},11).wait(151));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(21.9,0,83.1,154.6);


    (lib.arm2a = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{snooze:6});

        // timeline functions:
        this.frame_0 = function() {
            this.stop();
        }
        this.frame_92 = function() {
            this.gotoAndPlay("snooze");
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(92).call(this.frame_92).wait(1));

        // Layer_1
        this.instance = new lib.subarmWoman();
        this.instance.parent = this;
        this.instance.setTransform(22.6,14,1,1,0,0,0,22.6,14);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(6).to({regX:0,regY:30,x:0,y:30},0).to({rotation:-7.2},43).to({rotation:0},43).wait(1));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0,0,45.2,28);


    (lib.girl2 = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // Layer_3
        this.suit = new lib.zag1();
        this.suit.name = "suit";
        this.suit.parent = this;
        this.suit.setTransform(14.1,63.1,1,1,0,0,0,14.1,30.6);

        this.timeline.addTween(cjs.Tween.get(this.suit).wait(258));

        // Layer_5
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#003E5B").s().p("AgXgIIAGgBQADALAGAFQAGAEAFgBQAIgBAEgHQAFgGgBgKIAFAAQABALgGAIQgGAJgJABIgEAAQgRAAgGgXg");
        this.shape.setTransform(34,14.4);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#003E5B").s().p("AgXgGIAGgBQADAIAGAEQAGAEAFgBQAIgBAEgFQAFgGgBgHIAFgBQABAKgGAGQgGAHgJABIgEABQgRgBgGgSg");
        this.shape_1.setTransform(34,14.4);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#003E5B").s().p("AAAAKQgRgBgGgOIAGAAQADAGAGADQAGADAFgBQAIAAAEgEQAFgFgBgGIAFAAQABAIgGAEQgGAFgJACg");
        this.shape_2.setTransform(34,14.4);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#003E5B").s().p("AgXgDIAGgBQADAFAGACQAGACAFgBQAIgBAEgCQAFgCgBgEIAFgBQABAGgGACQgGAEgJAAIgEABQgRAAgGgKg");
        this.shape_3.setTransform(34,14.4);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#003E5B").s().p("AAAAEQgRAAgGgGIAGAAQADADAGABQAGABAFAAQAIgBAEgBQAFgCgBgCIAFAAQABADgGABQgGACgJABg");
        this.shape_4.setTransform(34,14.4);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape}]},53).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_1}]},1).to({state:[{t:this.shape}]},1).wait(188));
        this.timeline.addTween(cjs.Tween.get(this.shape).wait(53).to({_off:true},1).wait(7).to({_off:false},0).wait(1).to({_off:true},1).wait(7).to({_off:false},0).wait(188));

        // Layer_4
        this.instance = new lib.hairbreeze();
        this.instance.parent = this;
        this.instance.setTransform(26.1,16.3,1,1,0,0,0,14,16.3);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(258));

        // Layer_1
        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("AgagCIAJgVIAsATIgIAcg");
        this.shape_5.setTransform(26.8,32.4,0.96,0.96);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("AgUhQIAZgLIAQAJIgICug");
        this.shape_6.setTransform(29,40.7,0.96,0.96);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AgKgEIAKgQIALATIgLAWg");
        this.shape_7.setTransform(30.5,32.4,0.96,0.96);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#003E5B").s().p("AhYgmQARjWACg5IA6AAQAqBTAgDOQAQBxAbDPIjTALQAFjAAMidg");
        this.shape_8.setTransform(31.7,61.7,0.96,0.96);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#003E5B").s().p("AgFAHQgLgEgBgKIAFAAQABAHAHACQAKAEAIgIIAEADQgGAGgJABIgCAAIgGgBg");
        this.shape_9.setTransform(31.2,23,0.96,0.96);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f("#EB6378").s().p("AgiCLIgDgPQgEgkgNgWQgKgQgbgYQgSgPgQgoQgRguASgPIA0gvQBLgIAoANQAxAPAMAuIAGAbQAJAbAPAGIgdgCQgMAsgzAnQgiAagCASIAKAcg");
        this.shape_10.setTransform(29,18.3,0.96,0.96);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#233129").s().p("AgRAZIgMgxIAbAAIABAFIAfAsg");
        this.shape_11.setTransform(47.4,126.2,0.96,0.96);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#EB6378").s().p("AApEKIh9nwIARgcIAngHIBVHmIAdAtg");
        this.shape_12.setTransform(41.8,103.1,0.96,0.96);

        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f("#233129").s().p("AgZAYIAEgvIAWAAIgBADIAaAsg");
        this.shape_13.setTransform(21,126.5,0.96,0.96);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f("#EB6378").s().p("Ag7EgIAzoHIAYg4IAsA5IhdHZIAbAtg");
        this.shape_14.setTransform(24.2,101.2,0.96,0.96);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_14},{t:this.shape_13},{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5}]}).wait(258));

        // Layer_2
        this.arm = new lib.arm2a();
        this.arm.name = "arm";
        this.arm.parent = this;
        this.arm.setTransform(30,34.1,1,1,0,0,0,1.4,25.6);

        this.timeline.addTween(cjs.Tween.get(this.arm).wait(10).to({regX:1.5,regY:25.5,rotation:104.7},14,cjs.Ease.cubicInOut).to({regX:1.4,rotation:98.3,y:34},7).wait(96).to({regY:25.6,rotation:-6},12,cjs.Ease.cubicInOut).to({rotation:0,y:34.1},5).wait(114));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(0,0,73.8,128.9);


// stage content:
    (lib.index = function(mode,startPosition,loop) {
        this.initialize(mode,startPosition,loop,{});

        // timeline functions:
        this.frame_77 = function() {
            this.stop();
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).wait(77).call(this.frame_77).wait(1));

        // man1
        this.man = new lib.guy1();
        this.man.name = "man";
        this.man.parent = this;
        this.man.setTransform(343.8,426.7,1.352,1.352,0,0,180,51.2,77.4);
        this.man.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.man).wait(2).to({alpha:1},0).wait(76));

        // girl1
        this.girl = new lib.girl2();
        this.girl.name = "girl";
        this.girl.parent = this;
        this.girl.setTransform(453.2,277,1.674,1.674,0,0,180,36.9,64.5);
        this.girl.alpha = 0;

        this.timeline.addTween(cjs.Tween.get(this.girl).wait(2).to({alpha:1},0).wait(76));

        // Layer_32
        this.instance = new lib._3();
        this.instance.parent = this;
        this.instance.setTransform(340.7,576.5,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_1 = new lib._2();
        this.instance_1.parent = this;
        this.instance_1.setTransform(204.8,599.9,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_2 = new lib._1();
        this.instance_2.parent = this;
        this.instance_2.setTransform(155.1,616.2,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_3 = new lib._12();
        this.instance_3.parent = this;
        this.instance_3.setTransform(137,362.2,1.418,1.418,0,0,0,0.1,0.2);

        this.instance_4 = new lib._11();
        this.instance_4.parent = this;
        this.instance_4.setTransform(137,309.3,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_5 = new lib._10();
        this.instance_5.parent = this;
        this.instance_5.setTransform(155.2,301.7,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_6 = new lib._9();
        this.instance_6.parent = this;
        this.instance_6.setTransform(204.9,345.2,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_7 = new lib._4();
        this.instance_7.parent = this;
        this.instance_7.setTransform(390.5,546,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_8 = new lib._5();
        this.instance_8.parent = this;
        this.instance_8.setTransform(408.6,503.2,1.418,1.418,0,0,0,0.1,0.2);

        this.instance_9 = new lib._6();
        this.instance_9.parent = this;
        this.instance_9.setTransform(408.6,426.3,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_10 = new lib._7();
        this.instance_10.parent = this;
        this.instance_10.setTransform(390.5,383.4,1.418,1.418,0,0,0,0.1,0.1);

        this.instance_11 = new lib._8();
        this.instance_11.parent = this;
        this.instance_11.setTransform(340.7,352.9,1.418,1.418,0,0,0,0.1,0.2);

        this.instance_12 = new lib.bg();
        this.instance_12.parent = this;
        this.instance_12.setTransform(276.2,701,1.418,1.418,0,0,0,2.5,248);

        this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).wait(78));

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(272,349,544.3,701);
// library properties:
    lib.properties = {
        id: 'FDA0CCA9CB5FD441BFBD82ACB8EB6218',
        width: 544,
        height: 700,
        fps: 30,
        color: "#FFFFFF",
        opacity: 0.00,
        manifest: [],
        preloads: []
    };



// bootstrap callback support:

    (lib.Stage = function(canvas) {
        createjs.Stage.call(this, canvas);
    }).prototype = p = new createjs.Stage();

    p.setAutoPlay = function(autoPlay) {
        this.tickEnabled = autoPlay;
    }
    p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
    p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
    p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
    p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

    p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

    an.bootcompsLoaded = an.bootcompsLoaded || [];
    if(!an.bootstrapListeners) {
        an.bootstrapListeners=[];
    }

    an.bootstrapCallback=function(fnCallback) {
        an.bootstrapListeners.push(fnCallback);
        if(an.bootcompsLoaded.length > 0) {
            for(var i=0; i<an.bootcompsLoaded.length; ++i) {
                fnCallback(an.bootcompsLoaded[i]);
            }
        }
    };

    an.compositions = an.compositions || {};
    an.compositions['FDA0CCA9CB5FD441BFBD82ACB8EB6219'] = {
        getStage: function() { return exportRoot.getStage(); },
        getLibrary: function() { return lib; },
        getSpriteSheet: function() { return ss; },
        getImages: function() { return img; }
    };

    an.compositionLoaded = function(id) {
        an.bootcompsLoaded.push(id);
        for(var j=0; j<an.bootstrapListeners.length; j++) {
            an.bootstrapListeners[j](id);
        }
    }

    an.getComposition = function(id) {
        return an.compositions[id];
    }



})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;