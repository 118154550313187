jQuery(document).ready(function () {
  jQuery('.col-two-cta .sign-up-mail form input').focus(function () {
    jQuery(this).parent().addClass('focused');
  });

  jQuery('.col-two-cta input').blur(function () {
    var inputValue = jQuery(this).val();
    if (inputValue === "") {
      jQuery(this).parent().removeClass('focused');
    }
  });
});


jQuery(window).on("load scroll resize", function () {
  var $winHeight = jQuery(window).height();
  var $winTop = jQuery(window).scrollTop();
  
  /*
   * Fadein up with normal delay 
   */
  if ((jQuery('.animate-fade-down .animation-move-up').length > 0) ){
    jQuery(".animate-fade-down .animation-move-up").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeUp'});
      }
    });
  }
});