jQuery(document).ready(function () {

  if (!jQuery('body').hasClass('touch')) {
    hero_paralax();
  }
    if (jQuery('body').hasClass('touch')) {
        promo_paralax();
    }
  toggle_Effect();
});
jQuery(document).click(function () {
  var $window_width = jQuery(window).width();
  if ($window_width > 767) {
    remove_Effect();
  }
});

/*
 * jQuery FOR Remove Effect
 */
function remove_Effect() {
  jQuery('.filter-wrap .filter-nav').slideUp();
  jQuery('.filter-wrap.menutoogle').removeClass('menutoogle');
}

function toggle_Effect() {
  jQuery('.filter-wrap .filter').click(function (e) {
    e.stopPropagation();
    var $window_width = jQuery(window).width();
    if ($window_width > 767) {
      jQuery('.filter-wrap').toggleClass('menutoogle');
      jQuery(".filter-wrap .filter-nav").stop(true, true).slideToggle();
    }
  });
}

function promo_paralax() {
    var range = 200;
    jQuery(window).on('scroll', function (e) {
        e.stopPropagation();
        if (jQuery('.hero-banner').length > 0) {
            var scrollTop = jQuery(this).scrollTop(),
                height = jQuery('.hero-banner').outerHeight(),
                offset = height / 2,
                calc = 1 - (scrollTop - offset + range) / range;
        }jQuery('.home .violator-bar').stop(true, true).css({'opacity': calc});
        if (calc > '1') {
            jQuery('.home .violator-bar').stop(true, true).css({'opacity': 1});
        } else if (calc <= '0.2') {
            jQuery('.home .violator-bar').stop(true, true).css({'opacity': 0});
        }

    });
}

function hero_paralax() {
  var range = 200;
  jQuery(window).on('scroll', function (e) {
    e.stopPropagation();
    if (jQuery('.hero-banner').length > 0) {
      var scrollTop = jQuery(this).scrollTop(),
              height = jQuery('.hero-banner').outerHeight(),
              offset = height / 2,
              calc = 1 - (scrollTop - offset + range) / range;
    }
    jQuery('.hero-banner .background-image, .hero-banner .content h1, .hero-banner .background-image, .hero-banner .content h4, .hero-banner .background-image, .hero-banner .content a').stop(true, true).css({'opacity': calc});
    jQuery('.home .violator-bar').stop(true, true).css({'opacity': calc});
    if (calc > '1') {
      jQuery('.hero-banner .background-image, .hero-banner .content h1, .hero-banner .background-image, .hero-banner .content h4, .hero-banner .background-image, .hero-banner .content a').css({'opacity': 1});
        jQuery('.home .violator-bar').stop(true, true).css({'opacity': 1});
    } else if (calc <= '0.2') {
      jQuery('.hero-banner .background-image, .hero-banner .content h1, .hero-banner .background-image, .hero-banner .content h4, .hero-banner .background-image, .hero-banner .content a').css({'opacity': 0.2});
        jQuery('.home .violator-bar').stop(true, true).css({'opacity': 0});
    }

  });
}

function hero_canvas_resposive() {
  var $window_width = jQuery(window).width();
  var $window_height = jQuery(window).height();
  var violatorbar_height = jQuery('.violator-bar').outerHeight();


  if ($window_width > 991) {
      if (jQuery('body').hasClass('violator_active')) {
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - (200+violatorbar_height))) / 100;
          jQuery('.hero-banner #hero_animation_container').css({
              'min-height': (banner_height - (200+violatorbar_height)) + 'px',
              'max-width': $canvas_width + 'px'
          });
          jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
          jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - (200+violatorbar_height)) + 'px'});
          var $background_Width = jQuery('.hero-banner .background-1').width();
          var $bgright_Height = jQuery('.bg-right').outerHeight();
          jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
      }else{
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - 200)) / 100;
          jQuery('.hero-banner #hero_animation_container').css({
              'min-height': (banner_height - 200) + 'px',
              'max-width': $canvas_width + 'px'
          });
          jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
          jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - 200) + 'px'});
          var $background_Width = jQuery('.hero-banner .background-1').width();
          var $bgright_Height = jQuery('.bg-right').outerHeight();
          jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
      }
  } else if (($window_width > 767) && ($window_width < 992)) {
      if (jQuery('body').hasClass('violator_active')) {
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - (120 + violatorbar_height))) / 100;
          jQuery('.hero-banner #hero_animation_container').css({
              'min-height': (banner_height - (120 + violatorbar_height)) + 'px',
              'max-width': $canvas_width + 'px'
          });
          jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
          jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - (120 + violatorbar_height)) + 'px'});
          var $background_Width = jQuery('.hero-banner .background-1').width();
          var $bgright_Height = jQuery('.bg-right').outerHeight();
          jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
      }else {
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - 120)) / 100;
          jQuery('.hero-banner #hero_animation_container').css({
              'min-height': (banner_height - 120) + 'px',
              'max-width': $canvas_width + 'px'
          });
          jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
          jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - 120) + 'px'});
          var $background_Width = jQuery('.hero-banner .background-1').width();
          var $bgright_Height = jQuery('.bg-right').outerHeight();
          jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
      }
   } else if (($window_width > 480) && ($window_width < 768)) {
    if ($window_height > 399) {
        if (jQuery('body').hasClass('violator_active')) {
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - (180+violatorbar_height))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - (180 + violatorbar_height)) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - (180 + violatorbar_height)) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
        }else {
          var banner_height = jQuery('.hero-banner').outerHeight();
          var $canvas_width = (65.5 * (banner_height - (180))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - 180) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - 180) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
        }
    } else if ($window_height < 400) {
        if (jQuery('body').hasClass('violator_active')) {
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $canvas_width = (65.5 * (banner_height - (180 + violatorbar_height))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - (180 + violatorbar_height)) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - (180 + violatorbar_height)) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
            jQuery('.hero-banner .v-middle-inner .v-middle .content').css({'max-width': '290px', 'margin-top': '80px'});
        } else {
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $canvas_width = (65.5 * (banner_height - 180)) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - 180) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - 180) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
            jQuery('.hero-banner .v-middle-inner .v-middle .content').css({'max-width': '290px', 'margin-top': '80px'});
        }
    }
  }
  else if ($window_width < 481) {
    if ($window_height > 399) {
        if (jQuery('body').hasClass('violator_active')) {
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $content_height = jQuery('.image-parallax').outerHeight();
            var $canvas_width = (65.5 * (banner_height - ($content_height + 145 + violatorbar_height))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - ($content_height + 145 + violatorbar_height)) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - ($content_height + 145 + violatorbar_height)) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
        } else {
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $content_height = jQuery('.image-parallax').outerHeight();
            var $canvas_width = (65.5 * (banner_height - ($content_height + 145))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - ($content_height + 145)) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - ($content_height + 145)) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});

        }
    }else if ($window_height < 400) {
        if (jQuery('body').hasClass('violator_active')) {
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $canvas_width = (65.5 * (banner_height - (200 + violatorbar_height))) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - (200 + violatorbar_height)) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - (200 + violatorbar_height)) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
            jQuery('.hero-banner .v-middle-inner .v-middle .content').css({
                'max-width': '250px',
                'margin-top': '50px'
            });
        }else{
            var banner_height = jQuery('.hero-banner').outerHeight();
            var $canvas_width = (65.5 * (banner_height - 100)) / 100;
            jQuery('.hero-banner #hero_animation_container').css({
                'min-height': (banner_height - 100) + 'px',
                'max-width': $canvas_width + 'px'
            });
            jQuery('#hero_dom_overlay_container').css({'width': $canvas_width + 'px'});
            jQuery('.hero-banner #hero_canvas, .hero-banner #hero_dom_overlay_container').css({'max-height': (banner_height - 100) + 'px'});
            var $background_Width = jQuery('.hero-banner .background-1').width();
            var $bgright_Height = jQuery('.bg-right').outerHeight();
            jQuery('.bg-left').css({'right': ($background_Width - 4) + 'px', 'height': ($bgright_Height) + 'px'});
            jQuery('.hero-banner .v-middle-inner .v-middle .content').css({
                'max-width': '250px',
                'margin-top': '100px'
            });
        }
    }
  }
}

jQuery(window).on("load resize", function () {

  setTimeout(function () {
    jQuery('.hero-banner .inner-wrapper .content a').addClass('stop-animation');
  }, 1500);

  /*
   *  Canvas Height
   */
  if (jQuery('.hero-banner').length > 0) {
    hero_canvas_resposive();
  }
});

window.addEventListener("orientationchange", function () {
  if (screen.orientation.type == 'portrait-primary') {
    hero_init();
    hero_canvas_resposive();
  } else if (screen.orientation.type == 'landscape-primary') {
    hero_init();
    hero_canvas_resposive();
  }
}, false);


jQuery(window).bind("load scroll resize", function () {
  var $winTop = jQuery(window).scrollTop();
  /*
   * Fadein up with normal delay
   */
  if (jQuery('.hero-banner').length > 0) {
    jQuery(".hero-banner .background-1").each(function () {
      var $offset = jQuery(".hero-banner").offset().top;
      var $secPosition = $offset - $winTop;
      if (($winTop >= ($offset - 300)) && ($secPosition > -500)) {
        jQuery(this).css({
          'animation-name': 'fadeDown',
          'animation-duration': '1s',
          'animation-delay': '900ms'
        });
      }
    });

    jQuery(".hero-banner .background-2").each(function () {
      var $offset = jQuery(".hero-banner").offset().top;
      var $secPosition = $offset - $winTop;
      if (($winTop >= ($offset - 300)) && ($secPosition > -500)) {
        jQuery(this).css({
          'animation-name': 'fadeIn',
          'animation-duration': '1s',
          'animation-delay': '1.5s'
        });
      }
    });
  }

  /*
   * Fadein up with normal delay for content
   */
  if (jQuery('.hero-banner').length > 0) {
    jQuery(".hero-banner .content .animate").each(function () {
      var $offset = jQuery(".hero-banner").offset().top;
      var $secPosition = $offset - $winTop;
      if (($winTop >= ($offset - 300)) && ($secPosition > -500)) {
        jQuery(this).css({
          'animation-name': 'fadeDown',
          'animation-duration': '1s',
          'animation-delay': '600ms'
        });
      }
    });    
  }

  /*
   * Fadeup with normal delay for content
   */
  if (jQuery('.hero-banner').length > 0) {
    jQuery(".hero-banner .content a").each(function () {
      var $offset = jQuery(".hero-banner").offset().top;
      var $secPosition = $offset - $winTop;
      if (($winTop >= ($offset - 300)) && ($secPosition > -500)) {
        jQuery(this).css({
          'animation-name': 'fadeUp',
          'animation-duration': '1s',
          'animation-delay': '600ms',
          'opacity': '1'
        });
      }
    });
  }

});


/*

* Cookies for violator Bar
*/
jQuery(document).ready(function () {
    // checkCookie();
    // function setCookie(cname, cvalue) {
    //     document.cookie = cname + "=" + cvalue + ";" + ";path=/";
    // }
    // function getCookie(cname) {
    //     var name = cname + "=";
    //     var decodedCookie = decodeURIComponent(document.cookie);
    //     var ca = decodedCookie.split(';');
    //     for (var i = 0; i < ca.length; i++) {
    //         var c = ca[i];
    //         while (c.charAt(0) == ' ') {
    //             c = c.substring(1);
    //         }
    //         if (c.indexOf(name) == 0) {
    //             return c.substring(name.length, c.length);
    //         }
    //     }
    //     return "";
    // }
    // function checkCookie() {
    //     var violatorclose_val = getCookie("violatorclose");
    //     if (violatorclose_val) {
    //       jQuery('body').addClass('violator_inactive');
    //     } else {
    //         jQuery('body').addClass('violator_active');
    //     }
    // }
    //
    // jQuery(".violator-bar a").click(function () {
    //     setCookie("violatorclose", 'true');
    //     jQuery('body').addClass('violator_inactive');
    // });

    if(jQuery(".violator-bar").length > 0){
        jQuery('body').addClass('violator_active');
    }else{
        jQuery('body').addClass('violator_inactive');
    }

    /*
    * Manage hero banner top margin
    */

    var $win_width = jQuery(window).width();
    var violatorbar_height = jQuery('.violator-bar').outerHeight();
    var navigation_height = jQuery('.navigation ').outerHeight();
    if((($win_width > 767) && ($win_width < 992)) && (jQuery('body').hasClass('touch'))){
        if(jQuery('body').hasClass('violator_active')){
            jQuery('.hero-banner').css({'padding-top': (violatorbar_height+navigation_height)+'px', 'height': (500+violatorbar_height)+'px','max-height':'inherit'});
            jQuery('.hero-banner .inner-wrapper ').css({'height': (500 - navigation_height)+'px','max-height':'inherit'});
        }else if(jQuery('body').hasClass('violator_inactive')){
            jQuery('.hero-banner').removeAttr('style');
            jQuery('.hero-banner .inner-wrapper ').removeAttr('style');
        }
    }
    else if($win_width < 768) {
      if(jQuery('body').hasClass('violator_active')){
          jQuery('.hero-banner').css({'padding-top': (violatorbar_height+navigation_height)+'px', 'height': (500+violatorbar_height)+'px','max-height':'inherit'});
          jQuery('.hero-banner .inner-wrapper ').css({'height': (500 - navigation_height)+'px','max-height':'inherit'});
        }else if(jQuery('body').hasClass('violator_inactive')){
          jQuery('.hero-banner').removeAttr('style');
          jQuery('.hero-banner .inner-wrapper ').removeAttr('style');
      }
    }
    if(jQuery('body').hasClass('violator_active')){

        if($win_width > 1200) {
            jQuery('.client-experience .wrap > .content-with-link:eq(0)').css({'padding-top': (224+violatorbar_height) + 'px'});
            jQuery('.investment-management .wrap > .content-with-link:eq(0)').css({'padding-top': (225+violatorbar_height) + 'px'});
            jQuery('.disclaimers .wrap > .content-with-link:eq(0)').css({'padding-top': (225+violatorbar_height) + 'px'});
            jQuery('.endowments-foundations.intro-with-image .container').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.filter-wrap').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.filter-wrap .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (395+violatorbar_height) + 'px'});
            jQuery('.filter-wrap li.filter-nav-outer').css({'top': (427+violatorbar_height )+ 'px'});
            jQuery('.profile').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.profile .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.blog-details').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.blog-details .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.small-banner').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.small-banner .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.small-banner .container').css({'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'margin-top': -violatorbar_height + 'px', 'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'height' : (781+violatorbar_height) + 'px'});
            jQuery('.contact-us .map .content-wrap').css({'top' : (154+40+violatorbar_height) + 'px'});
            jQuery('.search.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
            jQuery('.page-not-found.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
            jQuery('.large-banner').css({ 'padding-top' :violatorbar_height + 'px'});
        }
        else if(($win_width >= 992) && ($win_width <= 1200)){
            jQuery('.client-experience .wrap > .content-with-link:eq(0)').css({'padding-top': (170+violatorbar_height) + 'px'});
            jQuery('.investment-management .wrap > .content-with-link:eq(0)').css({'padding-top': (170+violatorbar_height) + 'px'});
            jQuery('.disclaimers .wrap > .content-with-link:eq(0)').css({'padding-top': (170+violatorbar_height) + 'px'});
            jQuery('.endowments-foundations.intro-with-image .container').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.profile').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.profile .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.blog-details').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.blog-details .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.filter-wrap').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.filter-wrap .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (395+violatorbar_height) + 'px'});
            jQuery('.filter-wrap li.filter-nav-outer').css({'top': (427+violatorbar_height )+ 'px'});
            jQuery('.small-banner').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.small-banner .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.small-banner .container').css({'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'margin-top': -violatorbar_height + 'px', 'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'height' : (781+violatorbar_height) + 'px'});
            jQuery('.contact-us .map .content-wrap').css({'top' : (154+40+violatorbar_height) + 'px'});
            jQuery('.search.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
            jQuery('.large-banner').css({ 'padding-top' :violatorbar_height + 'px'});
            jQuery('.page-not-found.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
        }
        else if(($win_width >= 768) && ($win_width <= 991)){
            jQuery('.client-experience .wrap > .content-with-link:eq(0)').css({'padding-top': (120+violatorbar_height) + 'px'});
            jQuery('.investment-management .wrap > .content-with-link:eq(0)').css({'padding-top': (120+violatorbar_height) + 'px'});
            jQuery('.disclaimers .wrap > .content-with-link:eq(0)').css({'padding-top': (120+violatorbar_height) + 'px'});
            jQuery('.endowments-foundations.intro-with-image .container').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.profile').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.profile .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.blog-details').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.blog-details .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.filter-wrap').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.filter-wrap .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (395+violatorbar_height) + 'px'});
            jQuery('.filter-wrap li.filter-nav-outer').css({'top': (427+violatorbar_height )+ 'px'});
            jQuery('.small-banner').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.small-banner .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (222+violatorbar_height) + 'px'});
            jQuery('.small-banner .container').css({'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'margin-top': -violatorbar_height + 'px', 'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'height' : (781+violatorbar_height) + 'px'});
            jQuery('.contact-us .map .content-wrap').css({'top' : (154+40+violatorbar_height) + 'px'});
            jQuery('.search.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
            jQuery('.large-banner').css({ 'padding-top' :violatorbar_height + 'px'});
            jQuery('.page-not-found.content-with-link').css({'height' : (222+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
        } else if($win_width < 768){
            jQuery('.client-experience .wrap > .content-with-link:eq(0)').css({'padding-top': (80+violatorbar_height) + 'px'});
            jQuery('.investment-management .wrap > .content-with-link:eq(0)').css({'padding-top': (100+violatorbar_height) + 'px'});
            jQuery('.disclaimers .wrap > .content-with-link:eq(0)').css({'padding-top': (100+violatorbar_height) + 'px'});
            jQuery('.endowments-foundations.intro-with-image .container').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.profile').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.profile .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (180+violatorbar_height) + 'px'});
            jQuery('.blog-details').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.blog-details .bg-blue').css({'margin-top': -violatorbar_height + 'px', 'height' : (150+violatorbar_height) + 'px'});
            jQuery('.filter-wrap').css({'padding-top': violatorbar_height + 'px'});
            jQuery('.filter-wrap .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (220+violatorbar_height) + 'px'});
            jQuery('.blog-landing .filter-wrap .container').css({'padding-top' :violatorbar_height + 'px'});

            jQuery('.small-banner').css({'padding-top': (violatorbar_height) + 'px'});
            jQuery('.small-banner .bg-img').css({'margin-top': -violatorbar_height + 'px', 'height' : (180+violatorbar_height) + 'px'});
            jQuery('.small-banner .container').css({'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'margin-top': -violatorbar_height + 'px', 'padding-top' :violatorbar_height + 'px'});
            jQuery('.contact-us .map').css({'height' : (781+violatorbar_height) + 'px'});
            jQuery('.contact-us .map .content-wrap').css({'top' : (410+violatorbar_height) + 'px'});
            jQuery('.search.content-with-link').css({'height' : (200+violatorbar_height) + 'px' ,'padding-top' : (111+violatorbar_height) + 'px'});
            jQuery('.large-banner').css({ 'padding-top' :violatorbar_height + 'px'});
            jQuery('.page-not-found.content-with-link').css({'height' : (200+violatorbar_height) + 'px' ,'padding-top' : (127+violatorbar_height) + 'px'});
        }
    }else{
        jQuery('.client-experience .content-with-link, .filter-wrap, .filter-wrap .bg-img, .blog-landing .filter-wrap .container, .disclaimers .content-with-link').removeAttr('style');
    }

});
