jQuery(window).on("load resize", function () {

    /*
    *  Canvas Height
    */

    if(!jQuery('html').hasClass('ie10')){
        if(jQuery('.stairs_canvas').length > 0) {
            var $canvas_height = jQuery('.stairs_canvas #lower_canvas').outerHeight();
            jQuery('.stairs_canvas #lower_animation_container').css({'min-height' : $canvas_height+'px'});
        }

    }
});