


jQuery(document).ready(function () {
  filter_menu();
  show_menu();
  jQuery(".blog-landing .container .grid-outer .bg-img").imageResponsive();
  /*
   * Clear search on click of cross
   */

  jQuery('.blog-landing .filter-wrap .form-outer form input').keyup(function () {
    var $val = jQuery('.blog-landing .filter-wrap .form-outer form input').val();
    if ($val.length > 0) {
      jQuery('.blog-landing .filter-wrap .form-outer form span.clear-search').css({'opacity': '1', 'visibility': 'visible'});
    } else {
      jQuery('.blog-landing .filter-wrap .form-outer form span.clear-search').css({'opacity': '0', 'visibility': 'hidden'});
    }
  });

  jQuery('.blog-landing .filter-wrap .form-outer form span.clear-search').click(function () {
    jQuery('.blog-landing .filter-wrap .form-outer form input').val('');
    jQuery('.blog-landing .filter-wrap .form-outer form span.clear-search').css({'opacity': '0', 'visibility': 'hidden'});
  });
});

jQuery(document).click(function (e) {
  e.stopPropagation();
  var $window_width = jQuery(window).width();
  if ($window_width < 767) {
    setTimeout(function () {
      filter_remove_Effect();
    }, 500);
  }
});
jQuery(window).on("load", function () {
  jQuery(window).on("resize", function () {
    jQuery('.blog-landing .filter-wrap .filter-nav .info .col-four').sameHeight();
    jQuery('.filter-wrap .filter').click(function (e) {
      e.stopPropagation();
      jQuery('.blog-landing .filter-wrap .filter-nav .info .col-four').sameHeight();
    });

    jQuery('.blog-landing .filter-wrap .container ul.main-menu li .sub-menu').removeAttr('style');
    jQuery('.blog-landing .filter-wrap').removeClass('menutoogle');
    jQuery('body').removeClass('stop-scroll');
    jQuery('.down-arrow').parent().removeClass('menu-active');
  }).resize();
});


function filter_menu() {
  jQuery('.filter-wrap .filter').click(function (e) {
    e.stopPropagation();
    var $window_width = jQuery(window).width();
    if ($window_width < 767) {
      jQuery('.filter-wrap').toggleClass('menutoogle');
      jQuery('body').addClass('stop-scroll');
    }
  });
}

function filter_remove_Effect() {
  jQuery('.filter-wrap.menutoogle').removeClass('menutoogle');
  jQuery('.down-arrow').parent().removeClass('menu-active');
  jQuery('.blog-landing .filter-wrap .sub-menu').not(jQuery(this).siblings('.blog-landing .filter-wrap .sub-menu')).slideUp();
  jQuery('body').removeClass('stop-scroll');
  jQuery('.blog-landing .filter-wrap .main-menu .btn-wrap').removeClass('bg-white');
}


/*             
 * jQuery FOR Show sub menu
 */
function show_menu() {
  jQuery('.blog-landing .filter-wrap .down-arrow').click(function (e) {
    e.stopPropagation();
    jQuery('.blog-landing .filter-wrap .sub-menu').not(jQuery(this).siblings('.blog-landing .filter-wrap .sub-menu')).slideUp();
    jQuery(this).siblings('.sub-menu').slideToggle();
    jQuery(this).parent().toggleClass('menu-active');
    jQuery('.down-arrow').not(this).parent().removeClass('menu-active');
    jQuery('.blog-landing .filter-wrap .main-menu .btn-wrap').addClass('bg-white');
  });
}

jQuery(window).on("load", function () {
  jQuery(window).on("resize", function () {
    jQuery(".blog-landing .container .grid-outer .bg-img").imageResponsive();   
  }).resize();
});