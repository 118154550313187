jQuery(window).load(function () {
    hero_init(); // Load home page hero banner canvas
    lower_init(); // load home page lower canvas
    about_init(); // load about us page large banner canvas
    stairs_init(); // load about us lower canvas
    infographic_1_init(); // load infographic canvas 1
    infographic_2_init(); // load infographic canvas 2
    infographic_3_init(); // load infographic canvas 3
    infographic_4_init(); // load infographic canvas 4
    infographic_5_init(); // load infographic canvas 5
    //infographic_6_init(); // load infographic canvas 6
    infographic_7_init(); // load infographic canvas 7
    infographic_8_init(); // load infographic canvas 8
    infographic_9_init(); // load infographic canvas 9
    infographic_10_init(); // load infographic canvas 10
    infographic_11_init(); // load infographic canvas 11
    infographic_12_init(); // load infographic canvas 12
    infographic_13_init(); // load infographic canvas 13
    infographic_14_init(); // load infographic canvas 14
    infographic_15_init(); // load infographic canvas 15
    infographic_16_init()  // load infographic canvas 16
    infographic_17_init(); // load infographic canvas 17
    infographic_18_init(); // load infographic canvas 18
});