

jQuery(window).on("load scroll resize", function () {

  var $winHeight = jQuery(window).height();
  var $winwidth = jQuery(window).width();
  var $winTop = jQuery(window).scrollTop();

  /*
   * Fadein up with normal delay 
   */
  if (jQuery('.move-up').length > 0) {
    jQuery(".move-up").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInUp'});
      }
    });
  }
  /*
   * Fadein up with delay of 0.6second
   */
  if (jQuery('.move-up.delay-1').length > 0) {
    jQuery(".move-up.delay-1").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInUp'});
      }
    });
  }
  /*
   * Fadein up with delay of 0.9second
   */
  if (jQuery('.move-up.delay-2').length > 0) {
    jQuery(".move-up.delay-2").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.9s', 'animation-name': 'fadeInUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.9s', 'animation-name': 'fadeInUp'});
      }
    });
  }
  /*
   * Fadein up with delay of 1.2second
   */
  if (jQuery('.move-up.delay-3').length > 0) {
    jQuery(".move-up.delay-3").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '1.2s', 'animation-name': 'fadeInUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '1.2s', 'animation-name': 'fadeInUp'});
      }
    });
  }
  /*
   * Fadein up with delay of 1.5second
   */
  if (jQuery('.move-up.delay-4').length > 0) {
    jQuery(".move-up.delay-4").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '1.5s', 'animation-name': 'fadeInUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '1.5s', 'animation-name': 'fadeInUp'});
      }
    });
  }

  /*
   * Fadein from left to right with normal delay
   */
  if (jQuery('.move-right').length > 0) {
    jQuery(".move-right").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInRight'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInRight'});
      }
    });
  }

  /*
   * Fadein from left to right with delay of 0.6second
   */
  if (jQuery('.move-right.delay-1').length > 0) {
    jQuery(".move-right.delay-1").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInRight'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInRight'});
      }
    });
  }

  /*
   * Fadein from right to left with normal delay
   */
  if (jQuery('.move-left').length > 0) {
    jQuery(".move-left").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInLeft'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.3s', 'animation-name': 'fadeInLeft'});
      }
    });
  }
  
    /*
   * Fadein from right to left with normal delay-1
   */
  if (jQuery('.move-left.delay-1').length > 0) {
    jQuery(".move-left.delay-1").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInLeft'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '0.8s', 'animation-delay': '0.6s', 'animation-name': 'fadeInLeft'});
      }
    });
  }


  /*
   * fade-down with normal delay
   */
  if (jQuery('.fade-down').length > 0) {
    jQuery(".fade-down").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeDown'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeDown'});
      }
    });
  }
  
    /*
   * fade-down with delay of 1second
   */
  if (jQuery('.fade-down.delay-1').length > 0) {
    jQuery(".fade-down.delay-1").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '1s', 'animation-name': 'fadeDown'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '1s', 'animation-name': 'fadeDown'});
      }
    });
  }

  /*
   * fade-up normal delay
   */
  if (jQuery('.fade-up').length > 0) {
    jQuery(".fade-up").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeUp'});
      } else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeUp'});
      }
    });
  }

});


jQuery(window).on("load resize", function () {
  var $win_width = jQuery(window).width();
  jQuery('.profile .blog-wrap .fade-down').addClass('mobile');
  jQuery('.profile .blog-wrap .fade-up').addClass('mobile-up');
  if ($win_width < 768) {
    jQuery('.profile .blog-wrap .mobile').addClass('mobile-fade-down');
    jQuery('.profile .blog-wrap .mobile').removeClass('fade-down');
    jQuery('.profile .blog-wrap .fade-up').addClass('mobile-fade-up');
    jQuery('.profile .blog-wrap .fade-up').removeClass('fade-up');
  }
  else {
    jQuery('.profile .blog-wrap .mobile').removeClass('mobile-fade-down');
    jQuery('.profile .blog-wrap .fade-up').removeClass('mobile-fade-up');
    jQuery('.profile .blog-wrap .mobile').addClass('fade-down');
    jQuery('.profile .blog-wrap .fade-up').addClass('fade-up');
  }
});

jQuery(window).load(function () {

  /*
   * fade-down with normal delay
   */

  if (jQuery('.mobile-fade-down').length > 0) {
    jQuery(".mobile-fade-down").each(function () {
      jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeDown'});
    });
  }

  /*
   * fade-down with delay of 1second
   */
  if (jQuery('.mobile-fade-down.delay-1').length > 0) {
    jQuery(".mobile-fade-down.delay-1").each(function () {
      jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeDown'});
    });
  }

  /*
   * fade-up normal delay
   */
  if (jQuery('.mobile-fade-up').length > 0) {
    jQuery(".mobile-fade-up").each(function () {
      jQuery(this).css({'animation-duration': '1s', 'animation-delay': '600ms', 'animation-name': 'fadeUp'});
    });
  }

});
