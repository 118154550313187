
jQuery(window).bind("load scroll resize", function () {
  var $winHeight = jQuery(window).height();
  var $winTop = jQuery(window).scrollTop();
  if (jQuery('.ie9 .move-up.delay').length > 0) {
    jQuery(".ie9 .move-up.delay").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 300);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 300);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-1').length > 0) {
    jQuery(".ie9 .move-up.delay-1").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 600);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 600);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-2').length > 0) {
    jQuery(".ie9 .move-up.delay-2").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 900);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 900);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-3').length > 0) {
    jQuery(".ie9 .move-up.delay-3").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1100);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1100);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-4').length > 0) {
    jQuery(".ie9 .move-up.delay-4").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1300);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1300);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-5').length > 0) {
    jQuery(".ie9 .move-up.delay-5").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1500);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1500);
      }
    });
  }

  if (jQuery('.ie9 .move-up.delay-6').length > 0) {
    jQuery(".ie9 .move-up.delay-6").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1700);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1700);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-7').length > 0) {
    jQuery(".ie9 .move-up.delay-7").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1900);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 1900);
      }
    });
  }
  if (jQuery('.ie9 .move-up.delay-8').length > 0) {
    jQuery(".ie9 .move-up.delay-8").each(function () {
      var $offset = jQuery(this).parent().offset().top;
      var $secHeight = jQuery(this).parent().outerHeight();
      var $secPosition = $offset - $winTop;
      if (($secPosition <= ($winHeight - 150)) && ($secPosition > -500)) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 2100);
      }
      else if ((($secPosition - 80) >= -($secHeight)) && ($secPosition <= ($winHeight - 150))) {
        jQuery(this).animate({'opacity': '1', 'bottom': '0'}, 2100);
      }
    });
  }
});

function msieversion()
{
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0)
    {
        jQuery('html').addClass('ie9');
    }

    return false;

}

jQuery(document).ready(function () {
  msieversion();
});
